import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UserForm from "./UserForm";
import InformationForm from "./InformationForm";
import CarForm from "./CarForm";
import PositionForm from "./PositionForm";
import DocumentForm from "./DocumentForm";
import axios from "axios";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#F15B2A",
    },
    secondary: {
      main: "#494c7d",
    },
    red: {
      main: "#FF0000",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Prompt", "Helvetica", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        iSurvey DP
      </Link>{" "}
      v{process.env.REACT_APP_VERSION} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["ข้อมูลส่วนตัว", "เอกสารการสมัคร", "ตำแหน่งที่ต้องการสมัคร"];

export default function Checkout() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [pin, setPin] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [IDcard, setIDcard] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [age, setAge] = React.useState("");
  const [is_punish, setIs_punish] = React.useState("");
  const [education_desc, setEducation_desc] = React.useState("");
  const [education_qual, setEducation_qual] = React.useState("");
  const [emergency_name, setEmergency_name] = React.useState("");
  const [emergency_phone, setEmergency_phone] = React.useState("");
  const [work_history, setWork_history] = React.useState("");
  const [lt_drv_lic, setLt_drv_lic] = React.useState(0);
  const [veh_type, setVeh_type] = React.useState("");
  const [veh_plate_no, setVeh_plate_no] = React.useState("");
  const [veh_plate_provinceID, setVeh_plate_provinceID] = React.useState("");
  const [veh_driver_license, setVeh_driver_license] = React.useState("");
  const [veh_license_expire_date, setVeh_license_expire_date] =
    React.useState("");
  const [bank_name, setBank_name] = React.useState("");
  const [b_account_no, setB_account_no] = React.useState("");
  const [b_account_name, setB_account_name] = React.useState("");
  const [STRAIGHT_FACE, setSTRAIGHT_FACE] = React.useState("");
  const [ID_CARD, setID_CARD] = React.useState("");
  const [DRIVER_LICENSE, setDRIVER_LICENSE] = React.useState("");
  const [BOOK_BANK, setBOOK_BANK] = React.useState("");
  const [HOUSE_REGIS, setHOUSE_REGIS] = React.useState("");
  const [dataStep1, setDataStep1] = React.useState("");
  const [dataStep2, setDataStep2] = React.useState("");
  const [dataStep3, setDataStep3] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return <InformationForm onSave={onSave} dataStep={dataStep1} />;
      case 2:
        return (
          <DocumentForm
            onSave={onSave}
            handleBack={handleBack}
            dataStep={dataStep3}
          />
        );
      case 3:
        return <PositionForm onSave={onSave} handleBack={handleBack} />;
      case 4:
        return <PositionForm />;
      default:
        throw new Error("Unknown step");
    }
  };

  const onSave = (data) => {
    if (activeStep === 0) {
      setIDcard(data.IDcard);
      setPhone(data.phone);
      //   setUsername(data.username);
      //   setPassword(data.password);
      //   setPin(data.pin);
      handleNext();
    } else if (activeStep === 1) {
      setDataStep1(data);
      setFirstname(data.firstname);
      setLastname(data.lastname);
      setGender(data.gender);
      setBirthday(data.birthday);
      setEmail(data.email);
      setAddress1(data.address1);
      setAddress2(data.address2);
      setAge(data.age);
      setEducation_qual(data.education_qual);
      setEmergency_name(data.emergency_name);
      setEmergency_phone(data.emergency_phone);
      setWork_history(data.work_history);
      setIs_punish(data.is_punish);
      setEducation_desc(data.education_desc);
      handleNext();
    } else if (activeStep === 2) {
      setDataStep3(data);
      setSTRAIGHT_FACE(data.STRAIGHT_FACE);
      setID_CARD(data.ID_CARD);
      setDRIVER_LICENSE(data.DRIVER_LICENSE);
      setBOOK_BANK(data.BOOK_BANK);
      setHOUSE_REGIS(data.HOUSE_REGIS);
      setBank_name(data.bank_name);
      setB_account_no(data.b_account_no);
      setB_account_name(data.b_account_name);
      handleNext();
    } else if (activeStep === 3) {
      var dataForm = JSON.stringify({
        // username: username,
        // password: password,
        // password: IDcard.substring(5), //default ID Card last 8 digits (Cancel)
        password: "ss12345",
        // pin: pin,
        firstname: firstname,
        lastname: lastname,
        gender: gender,
        address1: address1,
        address2: address2,
        birthday: birthday,
        age: age,
        education_qual: education_qual,
        emergency_name: emergency_name,
        emergency_phone: emergency_phone,
        work_history: work_history,
        acc_employee: data.acc_employee === true ? 1 : 0,
        insp_employee: data.insp_employee === true ? 1 : 0,
        IDcard: IDcard,
        phone: phone,
        email: email,
        amphurID: data.amphur,
        provinceID: data.province,
        szone: null,
        latitude: 13,
        longitude: 100,
        remark: "grab",
        bank_name: bank_name,
        b_account_no: b_account_no,
        b_account_name: b_account_name,
        image: {
          STRAIGHT_FACE: STRAIGHT_FACE,
          ID_CARD: ID_CARD,
          DRIVER_LICENSE: DRIVER_LICENSE,
          BOOK_BANK: BOOK_BANK,
          HOUSE_REGIS: HOUSE_REGIS,
        },
        interview_flag: "W",
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}api/v1/external/user/add`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
          "Content-Type": "application/json",
        },
        data: dataForm,
      };

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setDataStep1("");
          setDataStep2("");
          setDataStep3("");
          handleNext();
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function renderStep() {
    if (activeStep === 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Container sx={{ mt: 25 }}>
              <img src="/img/logo_dp.png" className="logo" alt="Logo" />
            </Container>
          </Grid>
          <Grid item xs={7}>
            <Container>
              <Paper
                variant="outlined"
                sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              >
                <UserForm onSave={onSave} />
              </Paper>
              <Copyright />
            </Container>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <div>
              <Typography component="h1" variant="h4" align="center">
                ลงทะเบียนพาร์ทเนอร์
              </Typography>
              <Stepper
                activeStep={activeStep - 1}
                sx={{ pt: 3, pb: 5 }}
                orientation="vertical"
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>{getStepContent(activeStep)}</StepContent>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep - 1 === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      การสมัครของคุณเสร็จเรียบร้อยแล้ว
                    </Typography>
                    {/* <Typography variant="subtitle1">
                      โปรดรอการยืนยันผ่านทาง SMS/Email
                    </Typography> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
            </div>
          </Paper>
          <Copyright />
        </Container>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            ลงทะเบียนร่วมงานเป็น Digital Surveyor
          </Typography>
        </Toolbar>
      </AppBar>
      {renderStep()}
    </ThemeProvider>
  );
}
