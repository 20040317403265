import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "@mui/material/Alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PaymentFormOS(props) {
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const { zoneID } = props;
  const [companyList, setCompanyList] = useState([]);
  const [empTypeList, setEmpTypeList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [compID, setCompanyID] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [subTitleZone, setSubTitleZone] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [dataType, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceID, setProvinceID] = useState("");
  const [provinceEmp, setProvinceEmp] = useState("");
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [bank_name, setBank_name] = useState("");
  // const [veh_type, setVeh_type] = useState("");
  // const [veh_typeList, setVeh_typeList] = useState([]);
  const [veh_license_expire_date, setVeh_license_expire_date] = useState("");
  const [birthday, setBirthday] = useState("");
  const [lt_drv_lic, setLt_drv_lic] = React.useState(0);
  const [acc_employee, setAcc_employee] = React.useState(0);
  const [insp_employee, setInsp_employee] = React.useState(0);
  const [images, setImages] = useState([]);
  const [imagesCard, setImagesCard] = useState([]);
  const [imagesLicense, setImagesLicense] = useState([]);
  const [imagesBank, setImagesBank] = useState([]);
  const [approve, setApprove] = useState(false);
  const [is_employee, setIs_employee] = useState(false);
  // const [formTypeList, setFormTypeList] = React.useState([
  //   "TIP",
  //   "InsurVerse",
  //   "Others",
  // ]);
  const [is_selected, setIs_selected] = useState("");
  const [required, setRequired] = useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (zoneID) {
      if (zoneID === "1") {
        setSubTitle("กรุงเทพฯ");
        setSubTitleZone("กรุงเทพฯ");
      } else if (zoneID === "2") {
        setSubTitle("ปริมณฑล");
        setSubTitleZone("ปริมณฑล");
      } else if (zoneID === "3") {
        setSubTitle("ต่างจังหวัดในอำเภอเมือง");
        setSubTitleZone("ต่างจังหวัดในอำเภอเมือง");
      } else if (zoneID === "4") {
        setSubTitle("ต่างจังหวัดนอกอำเภอเมือง");
        setSubTitleZone("ต่างจังหวัดนอกอำเภอเมือง");
      } else {
        setSubTitle("Empty");
        setSubTitleZone("Empty");
      }
    }

    getCompany();
    // getEmployeeType();
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  function onSubmit(data) {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Original : Do not reset value of unselected
    // var dataForm = JSON.stringify({
    //   zoneID: zoneID,
    //   compID: data.compID,
    //   payment_type: is_selected === "1" ? "DIST" : "FLAT",
    //   claim_fresh_pymt: data.claim_fresh_pymt,
    //   claim_dry_pymt: data.claim_dry_pymt,
    //   claim_conti_pymt: data.claim_conti_pymt,
    //   dist_vehicle_pymt: data.dist_vehicle_pymt,
    //   dist_claim_pymt: data.dist_claim_pymt,
    //   tel_pymt: data.tel_pymt,
    //   daily_correct_pymt: data.daily_correct_pymt,
    //   daily_incorrect_pymt: data.daily_incorrect_pymt,
    //   daily_wait_pymt: data.daily_wait_pymt,
    //   flat_vehicle_pymt: data.flat_vehicle_pymt,
    //   flat_claim_pymt: data.flat_claim_pymt,
    // });

    // Reset value=0 for unselected
    var dataForm = JSON.stringify({
      zoneID: zoneID,
      compID: data.compID,
      payment_type: is_selected === "1" ? "DIST" : "FLAT",
      claim_fresh_pymt: is_selected === "1" ? data.claim_fresh_pymt : 0,
      claim_dry_pymt: is_selected === "1" ? data.claim_dry_pymt : 0,
      claim_conti_pymt: is_selected === "1" ? data.claim_conti_pymt : 0,
      claim_follow_pymt: is_selected === "1" ? data.claim_follow_pymt : 0,
      claim_disband_pymt: is_selected === "1" ? data.claim_disband_pymt : 0,
      dist_vehicle_pymt: is_selected === "1" ? data.dist_vehicle_pymt : 0,
      dist_claim_pymt: is_selected === "1" ? data.dist_claim_pymt : 0,
      tel_pymt: is_selected === "1" ? data.tel_pymt : 0,
      daily_correct_pymt: is_selected === "1" ? data.daily_correct_pymt : 0,
      daily_incorrect_pymt: is_selected === "1" ? data.daily_incorrect_pymt : 0,
      daily_wait_pymt: is_selected === "1" ? data.daily_wait_pymt : 0,
      flat_claim_fresh_pymt:
        is_selected === "1" ? 0 : data.flat_claim_fresh_pymt,
      flat_claim_dry_pymt: is_selected === "1" ? 0 : data.flat_claim_dry_pymt,
      flat_claim_conti_pymt:
        is_selected === "1" ? 0 : data.flat_claim_conti_pymt,
      flat_claim_follow_pymt:
        is_selected === "1" ? 0 : data.flat_claim_follow_pymt,
      flat_claim_disband_pymt:
        is_selected === "1" ? 0 : data.flat_claim_disband_pymt,
      // flat_vehicle_pymt: is_selected === "1" ? 0 : data.flat_vehicle_pymt,
      flat_vehicle_pymt: 0,
      flat_claim_pymt: is_selected === "1" ? 0 : data.flat_claim_pymt,
    });

    // console.log(`dataForm`, dataForm);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/ins/payconfig/add`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        setValue(0);
        setIsLoading(false);
        if (response.status === 200 || response.status === 201) {
          setSuccessMessage(response.data.message);

          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(response.data.message);
        }
        getPaymentConfig(compID); // Reload config
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    return false;
  }

  function getCompany() {
    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/ss/ins/list/confcomp`, {
        headers,
      })
      .then((response) => {
        setCompanyList(response.data);
        // console.log(`getCompany`, response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getPaymentConfig(comp) {
    setCompanyID(comp);

    // Set sub title
    if (comp) {
      const compName = companyList.filter((company) => {
        return company.sys_companyID === comp;
      });
      setSubTitle(`${subTitleZone} > ${compName[0].company_name_th}`);
    }

    // Get payment config
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/ins/paymentconfig?zoneID=${zoneID}&compID=${comp}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200 && response.data) {
          var item = response.data;

          setUser({
            payment_type: item.payment_type,
            claim_fresh_pymt: item.claim_fresh_pymt,
            claim_dry_pymt: item.claim_dry_pymt,
            claim_conti_pymt: item.claim_conti_pymt,
            claim_follow_pymt: item.claim_follow_pymt,
            claim_disband_pymt: item.claim_disband_pymt,
            dist_vehicle_pymt: item.dist_vehicle_pymt,
            dist_claim_pymt: item.dist_claim_pymt,
            tel_pymt: item.tel_pymt,
            daily_correct_pymt: item.daily_correct_pymt,
            daily_incorrect_pymt: item.daily_incorrect_pymt,
            daily_wait_pymt: item.daily_wait_pymt,
            flat_claim_fresh_pymt: item.flat_claim_fresh_pymt,
            flat_claim_dry_pymt: item.flat_claim_dry_pymt,
            flat_claim_conti_pymt: item.flat_claim_conti_pymt,
            flat_claim_follow_pymt: item.flat_claim_follow_pymt,
            flat_claim_disband_pymt: item.flat_claim_disband_pymt,
            // flat_vehicle_pymt: item.flat_vehicle_pymt,
            flat_claim_pymt: item.flat_claim_pymt,
          });
          setIs_selected(item.payment_type === "FLAT" ? "2" : "1");
        } else {
          // console.log(`No have data`, response);
          // setUser({
          //   claim_fresh_pymt: null,
          //   claim_dry_pymt: null,
          //   claim_conti_pymt: null,
          //   dist_vehicle_pymt: null,
          //   dist_claim_pymt: null,
          //   tel_pymt: null,
          //   daily_correct_pymt: null,
          //   daily_incorrect_pymt: null,
          //   daily_wait_pymt: null,
          //   flat_vehicle_pymt: null,
          //   flat_claim_pymt: null,
          // });
          setUser({
            claim_fresh_pymt: 0,
            claim_dry_pymt: 0,
            claim_conti_pymt: 0,
            claim_follow_pymt: 0,
            claim_disband_pymt: 0,
            dist_vehicle_pymt: 0,
            dist_claim_pymt: 0,
            tel_pymt: 0,
            daily_correct_pymt: 0,
            daily_incorrect_pymt: 0,
            daily_wait_pymt: 0,
            flat_claim_fresh_pymt: 0,
            flat_claim_dry_pymt: 0,
            flat_claim_conti_pymt: 0,
            flat_claim_follow_pymt: 0,
            flat_claim_disband_pymt: 0,
            flat_vehicle_pymt: 0,
            flat_claim_pymt: 0,
          });
          setIs_selected("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onPaymentTypeChange(event) {
    setIs_selected(event);
    if (event === "1") setRequired(true);
    else setRequired(false);
  }

  function onImageChange(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImages([e.target.result]);
    };
  }
  function onImageChangeCard(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesCard([e.target.result]);
    };
  }
  function onImageChangeLicense(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesLicense([e.target.result]);
    };
  }
  function onImageChangeBank(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesBank([e.target.result]);
    };
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangeApprove = (event) => {
    setApprove(!approve);
  };
  const handleChangeIs_employee = (event) => {
    setIs_employee(!is_employee);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Typography variant="h6" component="h4" gutterBottom>
        Configure &gt; {subTitle}
      </Typography>

      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth={true}>
                <TextField
                  {...register("compID")}
                  required
                  id="compID"
                  select
                  label="บริษัทประกัน"
                  value={compID}
                  variant="outlined"
                  // onChange={(event) => setCompanyID(event.target.value)}
                  onChange={(event) => getPaymentConfig(event.target.value)}
                >
                  {/* {formTypeList.map((item, index) => (
                    <MenuItem key={`T${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))} */}
                  {companyList.map((item) => (
                    <MenuItem
                      key={`C${item.sys_companyID}`}
                      value={item.sys_companyID}
                    >
                      {item.company_name_th}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <br />
        </div>
        <Grid item md={12}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              รูปแบบการจ่าย
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={is_selected}
              name="radio-buttons-group"
              {...register("is_selected")}
            >
              <div>
                <FormControlLabel
                  // onChange={(event) => setIs_selected("1")}
                  onChange={(event) => onPaymentTypeChange("1")}
                  value="1"
                  control={<Radio />}
                  // label="1. แบบคิดตามระยะทาง"
                  label={
                    <Typography variant="h6" component="h6">
                      1. แบบคิดตามระยะทาง
                    </Typography>
                  }
                />
              </div>

              <div className="pxl">
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography>1.1 ค่าบริการ</Typography>
                    <div className="pxll pxlt">
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>1.1.1 เคลมสด</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("claim_fresh_pymt", {})}
                            aria-invalid={
                              errors.claim_fresh_pymt ? "true" : "false"
                            }
                            id="claim_fresh_pymt"
                            disabled={is_selected === "2"}
                            required={required}
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>1.1.2 เคลมแห้ง</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("claim_dry_pymt")}
                            aria-invalid={
                              errors.claim_dry_pymt ? "true" : "false"
                            }
                            id="claim_dry_pymt"
                            variant="outlined"
                            disabled={is_selected === "2"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>1.1.3 เคลมต่อเนื่อง</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("claim_conti_pymt")}
                            aria-invalid={
                              errors.claim_conti_pymt ? "true" : "false"
                            }
                            id="claim_conti_pymt"
                            variant="outlined"
                            disabled={is_selected === "2"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>1.1.4 เคลมติดตาม</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("claim_follow_pymt")}
                            aria-invalid={
                              errors.claim_follow_pymt ? "true" : "false"
                            }
                            id="claim_follow_pymt"
                            variant="outlined"
                            disabled={is_selected === "2"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>1.1.5 แยกย้าย</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("claim_disband_pymt")}
                            aria-invalid={
                              errors.claim_disband_pymt ? "true" : "false"
                            }
                            id="claim_disband_pymt"
                            variant="outlined"
                            disabled={is_selected === "2"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.2 ค่าพาหนะ (ต่อ กม.)</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("dist_vehicle_pymt")}
                          aria-invalid={
                            errors.dist_vehicle_pymt ? "true" : "false"
                          }
                          id="dist_vehicle_pymt"
                          disabled={is_selected === "2"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.3 ค่าโทรศัพท์</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("tel_pymt")}
                          aria-invalid={errors.tel_pymt ? "true" : "false"}
                          id="tel_pymt"
                          disabled={is_selected === "2"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>

                      <Grid item md={12}>
                        <Typography>
                          1.4 ค่าประจำวัน (จำนวนข้อ X เรตราคา)
                        </Typography>
                        <div className="pxll pxlt">
                          <Grid container spacing={2}>
                            <Grid item md={6} sx={{ mt: 2 }}>
                              <Typography>1.4.1 ถูก</Typography>
                            </Grid>
                            <Grid item md={5} className="right">
                              <TextField
                                {...register("daily_correct_pymt")}
                                aria-invalid={
                                  errors.daily_correct_pymt ? "true" : "false"
                                }
                                id="daily_correct_pymt"
                                variant="outlined"
                                disabled={is_selected === "2"}
                                required
                              />
                            </Grid>
                            <Grid item md={1} sx={{ mt: 2 }}>
                              บาท
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item md={6} sx={{ mt: 2 }}>
                              <Typography>1.4.2 ผิด</Typography>
                            </Grid>
                            <Grid item md={5} className="right">
                              <TextField
                                {...register("daily_incorrect_pymt")}
                                aria-invalid={
                                  errors.daily_incorrect_pymt ? "true" : "false"
                                }
                                id="daily_incorrect_pymt"
                                variant="outlined"
                                disabled={is_selected === "2"}
                                required
                              />
                            </Grid>
                            <Grid item md={1} sx={{ mt: 2 }}>
                              บาท
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item md={6} sx={{ mt: 2 }}>
                              <Typography>1.4.3 รอผลคดี</Typography>
                            </Grid>
                            <Grid item md={5} className="right">
                              <TextField
                                {...register("daily_wait_pymt")}
                                aria-invalid={
                                  errors.daily_wait_pymt ? "true" : "false"
                                }
                                id="daily_wait_pymt"
                                variant="outlined"
                                disabled={is_selected === "2"}
                                required
                              />
                            </Grid>
                            <Grid item md={1} sx={{ mt: 2 }}>
                              บาท
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>

                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>
                          1.5 ค่าเรียกร้อง (จำนวนเงินที่เรียกร้องได้ X
                          จำนวนเปอร์เซ็นต์)
                        </Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          // {...register("dist_claim_pymt", { required: true })}
                          {...register("dist_claim_pymt")}
                          aria-invalid={
                            errors.dist_claim_pymt ? "true" : "false"
                          }
                          id="dist_claim_pymt"
                          disabled={is_selected === "2"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        %
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <br />
              <div>
                <FormControlLabel
                  // onChange={(event) => setIs_selected("2")}
                  onChange={(event) => onPaymentTypeChange("2")}
                  value="2"
                  control={<Radio />}
                  // label="2. แบบเหมาจ่าย"
                  label={
                    <Typography variant="h6" component="h6">
                      2. แบบเหมาจ่าย
                    </Typography>
                  }
                />
              </div>
              <div className="pxl">
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography>2.1 ค่าบริการ</Typography>
                    <div className="pxll pxlt">
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>2.1.1 เคลมสด</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("flat_claim_fresh_pymt")}
                            aria-invalid={
                              errors.flat_claim_fresh_pymt ? "true" : "false"
                            }
                            id="flat_claim_fresh_pymt"
                            variant="outlined"
                            disabled={is_selected === "1"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>2.1.2 เคลมแห้ง</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("flat_claim_dry_pymt")}
                            aria-invalid={
                              errors.flat_claim_dry_pymt ? "true" : "false"
                            }
                            id="flat_claim_dry_pymt"
                            variant="outlined"
                            disabled={is_selected === "1"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>2.1.3 เคลมต่อเนื่อง</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("flat_claim_conti_pymt")}
                            aria-invalid={
                              errors.flat_claim_conti_pymt ? "true" : "false"
                            }
                            id="flat_claim_conti_pymt"
                            variant="outlined"
                            // disabled={is_selected === "FLATPAY"}
                            disabled={is_selected === "1"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>2.1.4 เคลมติดตาม</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("flat_claim_follow_pymt")}
                            aria-invalid={
                              errors.flat_claim_follow_pymt ? "true" : "false"
                            }
                            id="flat_claim_follow_pymt"
                            variant="outlined"
                            disabled={is_selected === "1"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2 }}>
                          <Typography>2.1.5 แยกย้าย</Typography>
                        </Grid>
                        <Grid item md={5} className="right">
                          <TextField
                            {...register("flat_claim_disband_pymt")}
                            aria-invalid={
                              errors.flat_claim_disband_pymt ? "true" : "false"
                            }
                            id="flat_claim_disband_pymt"
                            variant="outlined"
                            disabled={is_selected === "1"}
                            required
                          />
                        </Grid>
                        <Grid item md={1} sx={{ mt: 2 }}>
                          บาท
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      {/* <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1 ค่าพาหนะ</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_vehicle_pymt")}
                          aria-invalid={
                            errors.flat_vehicle_pymt ? "true" : "false"
                          }
                          id="flat_vehicle_pymt"
                          disabled={is_selected === "1"}
                          required={!required}
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid> */}

                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>
                          2.2 ค่าเรียกร้อง (จำนวนเงินที่เรียกร้องได้ X
                          จำนวนเปอร์เซ็นต์)
                        </Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_pymt")}
                          aria-invalid={
                            errors.flat_claim_pymt ? "true" : "false"
                          }
                          id="flat_claim_pymt"
                          disabled={is_selected === "1"}
                          required={!required}
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        %
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </RadioGroup>
          </FormControl>
        </Grid>

        <div className="pxl">
          <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="flex-end">
            <Alert
              severity="error"
              className={errorMessage === "" ? "hide" : ""}
            >
              {errorMessage}
            </Alert>
            <Alert
              severity="success"
              className={successMessage === "" ? "hide" : ""}
            >
              {successMessage}
            </Alert>
            <LoadingButton
              type="submit"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={(event) => getPaymentConfig(compID)}>
              Cancel
            </Button>
          </Grid>
        </div>
      </form>
    </div>
  );
}
