import React, { useState, useEffect, Fragment, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
// import ExportExcel from "../forms/ExportExcel";  //Cancel
import Popover from "@mui/material/Popover";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import { Document, Page, pdfjs } from "react-pdf";
// import pdffile from "./EmploymentContract.pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const dataTypeList = [
  {
    value: "พนักงานสำรวจอุบัติเหตุ",
    label: "พนักงานสำรวจอุบัติเหตุ",
  },
  {
    value: "พนักงานตรวจสภาพรถยนต์",
    label: "พนักงานตรวจสภาพรถยนต์",
  },
];

const statusList = [
  {
    value: "เป็นพนักงาน",
    label: "เป็นพนักงาน",
  },
  {
    value: "ไม่เป็นพนักงาน",
    label: "ไม่เป็นพนักงาน",
  },
  {
    value: "รอสัมภาษณ์",
    label: "รอสัมภาษณ์",
  },
  {
    value: "ผ่านสัมภาษณ์",
    label: "ผ่านสัมภาษณ์",
  },
  {
    value: "ไม่ผ่านสัมภาษณ์",
    label: "ไม่ผ่านสัมภาษณ์",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EmployeeInfo() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [dataType, setDataType] = useState("");
  const [empStatus, setEmpStatus] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [province, setProvince] = useState("");
  const [provinceEmp, setProvinceEmp] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [dp_branchID, setDp_branchID] = useState("");
  const [dp_type, setDp_type] = useState("");
  const [dp_typeTax, setDp_typeTax] = useState("");
  const [birthday, setBirthday] = useState("");
  const [acc_employee, setAcc_employee] = React.useState(0);
  const [insp_employee, setInsp_employee] = React.useState(0);
  const [images, setImages] = useState([]);
  const [imagesCard, setImagesCard] = useState([]);
  const [imagesLicense, setImagesLicense] = useState([]);
  const [imagesBank, setImagesBank] = useState([]);
  const [imagesHouse, setImagesHouse] = useState([]);
  const [imagesContract, setImagesContract] = useState([]);
  const [extID, setExtID] = useState("");
  const [is_employee, setIs_employee] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  // const [waitInterview, setWaitInterview] = useState(false);
  const [interview, setInterview] = useState("");
  const [isPassInterview, setIsPassInterview] = useState(false);
  const [isWaitInterview, setIsWaitInterview] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [is_punish, setIs_punish] = useState("1");
  const [branchList, setBranchList] = React.useState([]);
  const [typeList, setTypeList] = React.useState([
    { id: "SS", name: "SS(Super Survey ที่วิ่งงานเขตกทม/ปริมณฑล)" },
    { id: "SSC", name: "SSC(Super Survey ที่วิ่งงานเขตตจว.)" },
    {
      id: "SSV",
      name: "SSV(Super Survey ที่เป็นพนักงานประจำของบ.ดีพีอยู่แล้ว)",
    },
    { id: "RR", name: "RR (โรบินฮูด)" },
  ]);
  const [typeTax, setTypeTax] = React.useState([
    { id: "tax_3", name: "หักภาษี 3%" },
    { id: "no_tax", name: "ไม่หัก(SSV)" },
  ]);
  const [bankList, setBankList] = React.useState([
    "ธนาคารกรุงเทพ",
    "ธนาคารกสิกรไทย",
    "ธนาคารกรุงไทย",
    "ธนาคารทหารไทยธนชาต",
    "ธนาคารไทยพาณิชย์",
    "ธนาคารกรุงศรีอยุธยา",
    "ธนาคารเกียรตินาคินภัทร",
    "ธนาคารซีไอเอ็มบีไทย",
    "ธนาคารทิสโก้",
    "ธนาคารยูโอบี",
    "ธนาคารไทยเครดิตเพื่อรายย่อย",
    "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    "ธนาคารไอซีบีซี(ไทย)",
    "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
    "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
    "ธนาคารออมสิน",
    "ธนาคารอาคารสงเคราะห์",
    "ธนาคารอิสลามแห่งประเทศไทย",
  ]);
  const [education_qual, setEducation_qual] = useState("");
  const [educationList, setEducationList] = useState([
    "ต่ำกว่ามัธยมศึกษาตอนปลาย / ปวช.",
    "มัธยมศึกษาตอนปลาย / ปวช.",
    "อนุปริญญา / ปวส.",
    "ปริญญาตรี",
    "ปริญญาโท หรือสูงกว่า",
  ]);
  const [ExcelExportData, setExcelExportData] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorUsername, setErrorUsername] = React.useState("");
  // const [openMsgAlert, setOpenMsgAlert] = React.useState(false);
  const [isEmptyUsername, setIsEmptyUsername] = useState(true);
  const [openAlertResetPW, setOpenAlertResetPW] = React.useState(false);
  const [openSuccessResetPW, setOpenSuccessResetPW] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  // // TEST --------------------------------------
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };
  // const openLarge = Boolean(anchorEl);
  // //end TEST -----------------------------------

  // TEST Multiple --------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [anchorEl5, setAnchorEl5] = React.useState(null);
  const [anchorEl6, setAnchorEl6] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handlePopoverOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handlePopoverOpen4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handlePopoverOpen5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };
  const handlePopoverOpen6 = (event) => {
    setAnchorEl6(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverClose2 = () => {
    setAnchorEl2(null);
  };
  const handlePopoverClose3 = () => {
    setAnchorEl3(null);
  };
  const handlePopoverClose4 = () => {
    setAnchorEl4(null);
  };
  const handlePopoverClose5 = () => {
    setAnchorEl5(null);
  };
  const handlePopoverClose6 = () => {
    setAnchorEl6(null);
  };
  const openLarge = Boolean(anchorEl);
  const openLarge2 = Boolean(anchorEl2);
  const openLarge3 = Boolean(anchorEl3);
  const openLarge4 = Boolean(anchorEl4);
  const openLarge5 = Boolean(anchorEl5);
  const openLarge6 = Boolean(anchorEl6);
  //end TEST -------------------------------------------

  // PDF preview page control
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  // function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
  //   setNumPages(numPages);
  // }

  const canvasRef = useRef(null);
  const [thumb, setThumb] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const [user, setUser] = useState(null);
  // const watchShowAge = watch("searchName", false); // you can supply default value as second argument
  // const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  // const watchFields = watch(["searchName", "b_account_no", "b_account_name"]); // you can also target specific fields by their names

  useEffect(() => {
    getProvince();
    getBranch();
    getData(0, rowsPerPage, province, amphur, searchName, dataType, empStatus);
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  useEffect(() => {
    if (errors.username) setErrorMessage("กรุณากรอกข้อมูล Username");
  }, [errors.username]);

  async function onSubmit(data) {
    setIsLoading(true);
    setErrorMessage("");
    setErrorUsername("");

    // Verify username for first times && after approved (อนุมัติให้เป็นพนักงาน)
    if (is_employee === true || isEmployee === true) {
      if (isEmptyUsername) {
        if (data.username === "" || data.username === null) {
          setErrorMessage("กรุณากรอกข้อมูล Username ที่หน้า PERSONAL DETAILS");
          setIsLoading(false);
          // console.log(`ERR: username required`);
          return false;
        }
        var responseUsername = await checkUsername(data.username);
        if (responseUsername.data.status === false) {
          setErrorUsername(responseUsername.data.message);
          setErrorMessage(responseUsername.data.message);
          // setOpenMsgAlert(true);
          setIsLoading(false);
          return false;
        }
      }
    }

    // Verify Bank info is required if already is_employee (อนุมัติ)
    if (
      (is_employee === true || isEmployee === true) &&
      (data.b_account_no === null || data.b_account_name === null)
    ) {
      console.log(`Warning: Bank info is required!`);
      setErrorMessage(`กรุณากรอกข้อมูลบัญชีธนาคารให้ครบ`);
      setIsLoading(false);
      return false;
    }

    if (birthday !== null) {
      if (typeof birthday !== "string") {
        let onlyDate = birthday.$d.toISOString();
        const dateBirthday = new Date(onlyDate);
        data.birthday = dateBirthday.toLocaleDateString();
      }
    }

    var valueImage = {};
    if (images[0] !== null) {
      if (images[0].includes("storage") === false) {
        valueImage["STRAIGHT_FACE"] = images[0];
      }
    }
    if (imagesCard[0] !== null) {
      if (imagesCard[0].includes("storage") === false) {
        valueImage["ID_CARD"] = imagesCard[0];
      }
    }
    if (imagesLicense[0] !== null) {
      if (imagesLicense[0].includes("storage") === false) {
        valueImage["DRIVER_LICENSE"] = imagesLicense[0];
      }
    }
    if (imagesBank[0] !== null) {
      if (imagesBank[0].includes("storage") === false) {
        valueImage["BOOK_BANK"] = imagesBank[0];
      }
    }
    // console.log(imagesHouse);
    if (imagesHouse[0] !== null) {
      if (imagesHouse[0].includes("storage") === false) {
        valueImage["HOUSE_REGIS"] = imagesHouse[0];
      }
    }
    // console.log(imagesContract);
    if (imagesContract.length !== 0) {
      if (imagesContract[0].includes("storage") === false) {
        valueImage["EMP_CONTRACT"] = imagesContract[0];
      }
    }
    // console.log(valueImage);

    // var dataForm = JSON.stringify({
    //   username: data.username,
    //   empcode: data.empcode,
    //   dp_branchID: data.dp_branchID,
    //   dp_type: data.dp_type,
    //   dp_typeTax: data.dp_typeTax,
    //   firstname: data.firstname,
    //   lastname: data.lastname,
    //   gender: data.gender,
    //   address1: data.address1,
    //   address2: data.address2,
    //   birthday: data.birthday,
    //   age: data.age,
    //   emergency_name: data.emergency_name,
    //   emergency_phone: data.emergency_phone,
    //   work_history: data.work_history,
    //   IDcard: data.IDcard,
    //   phone: data.phone,
    //   email: data.email,
    //   amphurID: amphurEmp,
    //   provinceID: provinceEmp,
    //   szone: null,
    //   latitude: 13,
    //   longitude: 100,
    //   remark: "grab",
    //   bank_name: bank_name,
    //   b_account_no: data.b_account_no,
    //   b_account_name: data.b_account_name,
    //   education_desc: data.education_desc,
    //   is_employee: is_employee === true ? 1 : 0,
    //   isActive: isActive === true ? 1 : 0,
    //   acc_employee: acc_employee === true ? 1 : 0,
    //   insp_employee: insp_employee === true ? 1 : 0,
    //   image: valueImage,
    //   wait_interview: waitInterview === true ? 0 : 1,
    // });

    var dataSubmit = {
      empcode: data.empcode,
      dp_branchID: data.dp_branchID,
      dp_type: data.dp_type,
      dp_typeTax: data.dp_typeTax,
      firstname: data.firstname,
      lastname: data.lastname,
      gender: data.gender,
      address1: data.address1,
      address2: data.address2,
      birthday: data.birthday,
      age: data.age,
      emergency_name: data.emergency_name,
      emergency_phone: data.emergency_phone,
      work_history: data.work_history,
      IDcard: data.IDcard,
      phone: data.phone,
      email: data.email,
      amphurID: amphurEmp,
      provinceID: provinceEmp,
      szone: null,
      latitude: 13,
      longitude: 100,
      remark: "grab",
      bank_name: bank_name,
      b_account_no: data.b_account_no,
      b_account_name: data.b_account_name,
      education_desc: data.education_desc,
      is_employee: is_employee === true ? 1 : 0,
      // isActive: isActive === true ? 1 : 0,
      acc_employee: acc_employee === true ? 1 : 0,
      insp_employee: insp_employee === true ? 1 : 0,
      image: valueImage,
      // wait_interview: waitInterview === true ? 0 : 1,
      interview_flag: interview,
    };
    if (isEmptyUsername) {
      dataSubmit["username"] = data.username;
    }
    // Initial set isAcitve=1 when first time approved
    if (is_employee === true && isEmployee === false) {
      dataSubmit["isActive"] = 1;
    } else {
      dataSubmit["isActive"] = isActive === true ? 1 : 0;
    }

    var dataForm = JSON.stringify(dataSubmit);
    // console.log(dataForm);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/user/${extID}/update`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        setValue(0);
        setIsLoading(false);
        setOpen(false);
        //Refresh page
        getData(
          0,
          rowsPerPage,
          province,
          amphur,
          searchName,
          dataType,
          empStatus
        );
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status === 400 &&
          error.response.data.message === "Empcode already exists"
        ) {
          setErrorMessage(`รหัสพนักงานซ้ำ [${error.response.data.message}]`);
        } else {
          setErrorMessage(error.response.data.message);
        }
        setIsLoading(false);
      });
    return false;
  }

  function _delete() {
    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}api/v1/external/user/${extID}`, {
        headers,
      })
      .then((res) => {
        // console.log(res);
        //Refresh page
        getData(
          0,
          rowsPerPage,
          province,
          amphur,
          searchName,
          dataType,
          empStatus
        );
      });
    handleCloseConfirm();
  }

  function resetPassword() {
    setIsLoading(true);
    setErrorMessage("");

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/user/${extID}/resetpassword`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data.status === "success"
        ) {
          setSuccessMessage(response.data.message);
          setIsLoading(false);
          handleCloseConfirmResetPW();
          setOpenSuccessResetPW(true);
        } else {
          setErrorMessage(response.data.message);
          setIsLoading(false);
          handleCloseConfirmResetPW();
          setOpenAlertResetPW(true);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    // return false;
    // handleCloseConfirmResetPW();
  }

  function getBranch() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/systembranch`)
      .then((response) => {
        setBranchList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getProvince() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/province`)
      .then((response) => {
        setProvinceList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAmphur(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        setAmphurList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAmphurEmp(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        setAmphurListEmp(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(
    nowPage,
    nowRowsPerPage,
    provinceID,
    amphurID,
    employeeName,
    dataType,
    empStatus
  ) {
    setLoading(true);
    setDataRow([]);
    var where = "";
    if (dataType !== "") {
      if (dataType === "พนักงานสำรวจอุบัติเหตุ") {
        where = "&acc_employee=1";
      } else {
        where = "&insp_employee=1";
      }
    }
    var filterStatus = "";
    if (empStatus !== "") {
      if (empStatus === "เป็นพนักงาน") {
        filterStatus = "&isActive=1";
      } else if (empStatus === "ไม่เป็นพนักงาน") {
        filterStatus = "&isActive=0&is_employee=1";
      } else if (empStatus === "รอสัมภาษณ์") {
        filterStatus = "&interview_flag=W";
      } else if (empStatus === "ผ่านสัมภาษณ์") {
        filterStatus = "&interview_flag=P&is_employee=0";
      } else if (empStatus === "ไม่ผ่านสัมภาษณ์") {
        filterStatus = "&interview_flag=N";
      }
    }

    var nowPage = nowPage + 1;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/user?page=${nowPage}&perpage=${nowRowsPerPage}&provinceID=${provinceID}&amphurID=${amphurID}&name=${employeeName}${where}${filterStatus}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    // console.log(data);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
        setExcelExportData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleClickOpen(id) {
    setThumb(null);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/user/${id}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };

    axios(config)
      .then(function (response) {
        var item = response.data;
        // console.log(item.dp_type, item);
        a11yProps(0);
        setUser({
          username: item.user,
          empcode: item.empcode,
          firstname: item.firstname,
          lastname: item.lastname,
          gender: item.gender,
          birthday: item.birthday,
          IDcard: item.IDcard,
          phone: item.phone,
          email: item.email,
          address1: item.address1,
          address2: item.address2,
          age: item.age,
          emergency_name: item.emergency_name,
          emergency_phone: item.emergency_phone,
          work_history: item.work_history,
          b_account_name: item.b_account_name,
          b_account_no: item.b_account_no,
          education_desc: item.education_desc,
        });
        setDp_branchID(
          item.dp_branchID !== null ? parseInt(item.dp_branchID) : ""
        );
        setDp_type(item.dp_type !== null ? item.dp_type : "");
        setDp_typeTax(item.dp_typeTax !== null ? item.dp_typeTax : "");
        setExtID(item.extID);
        setProvinceEmp(item.provinceID);
        getAmphurEmp(item.provinceID);
        setAmphurEmp(item.amphurID);
        setBirthday(item.birthday);
        setBank_name(item.bank_name);
        setEducation_qual(item.education_qual);
        setIs_employee(item.is_employee === 1 ? true : false);
        setIsEmployee(item.is_employee === 1 ? true : false);
        //Todo
        setInterview(item.interview_flag);
        setIsPassInterview(item.interview_flag === "N" ? false : true);
        setIsWaitInterview(item.interview_flag === "W" ? true : false);
        setIsActive(item.isActive === 1 ? true : false);
        setAcc_employee(item.acc_employee === 1 ? true : false);
        setInsp_employee(item.insp_employee === 1 ? true : false);
        setIs_punish(item.is_punish !== null ? item.is_punish : "0");
        setImages([item.image.STRAIGHT_FACE]);
        setImagesCard([item.image.ID_CARD]);
        setImagesLicense([item.image.DRIVER_LICENSE]);
        setImagesBank([item.image.BOOK_BANK]);
        setImagesHouse([item.image.HOUSE_REGIS]);
        setImagesContract(
          item.image.EMP_CONTRACT ? [item.image.EMP_CONTRACT] : []
        );
        setIsEmptyUsername(item.user ? false : true);
        setOpen(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function onImageChange(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImages([e.target.result]);
    };
  }
  function onImageChangeCard(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesCard([e.target.result]);
    };
  }
  function onImageChangeLicense(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesLicense([e.target.result]);
    };
  }
  function onImageChangeBank(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesBank([e.target.result]);
    };
  }
  function onImageChangeHouse(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesHouse([e.target.result]);
    };
  }
  function onImageChangeContract(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    const size = files[0].size; // แสดงเป็น bytes
    // console.log(`Test size`, size);

    // กำหนดให้ size ใหญ่ไม่เกิน 2Mb
    if (size / 1024 > 2000) {
      alert("ไฟล์ขนาดใหญ่เกิน 2 Mb");
      return;
    }
    // Verify pdf only
    const fileType = files[0].type;
    if (fileType !== "application/pdf") {
      alert("กรุณาเลือกไฟล์ pdf");
      return;
    }

    reader.onload = (e) => {
      setImagesContract([e.target.result]);
    };
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(
      newPage,
      rowsPerPage,
      province,
      amphur,
      searchName,
      dataType,
      empStatus
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(
      0,
      event.target.value,
      province,
      amphur,
      searchName,
      dataType,
      empStatus
    );
  };
  const handleChangeIs_employee = (event) => {
    setIs_employee(!is_employee);
  };
  // const handleChangeInterview = (event) => {
  //   setWaitInterview(!waitInterview);
  // };

  const handleChangeIsActive = (event) => {
    const watchBA = watch().b_account_no;
    const watchBAN = watch().b_account_name;

    if (!isActive) {
      if (
        watchBA === null ||
        watchBAN === null ||
        watchBA.length === 0 ||
        watchBAN.length === 0
      ) {
        // console.log(`Please fill bank info!`);
        setOpenAlert(true);
      } else {
        setIsActive(!isActive);
      }
    } else {
      setIsActive(!isActive);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setErrorMessage("");
    setErrorUsername("");
    setOpen(false);
  };

  const handleCloseMsgAlert = () => {
    setErrorMessage("");
    // setOpenMsgAlert(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClickOpenConfirm = (id) => {
    // console.log(id);
    setExtID(id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [openConfirmResetPW, setOpenConfirmResetPW] = useState(false);

  const handleClickOpenConfirmResetPW = () => {
    // console.log(`handleClickOpenConfirmResetPW:`, extID);
    setOpenConfirmResetPW(true);
  };
  const handleCloseConfirmResetPW = () => {
    setOpenConfirmResetPW(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function checkUsername(username) {
    var dataForm = JSON.stringify({
      type: "username",
      keyword: username,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/checkduplicate`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  const handleClearClick = () => {
    setProvince("");
    setAmphur("");
    setAmphurList([]);
    getData(0, rowsPerPage, "", "", searchName, dataType, empStatus);
  };
  const handleClearClickAmphur = () => {
    setAmphur("");
    getData(0, rowsPerPage, province, "", searchName, dataType, empStatus);
  };

  const handleClearEmpStatusClick = () => {
    setEmpStatus("");
    getData(0, rowsPerPage, province, amphur, searchName, dataType, "");
  };

  const handleClearDataTypeClick = () => {
    setDataType("");
    getData(0, rowsPerPage, province, amphur, searchName, "", empStatus);
  };

  const handleChangeInterview = (event) => {
    setInterview(event.target.value);
  };

  return (
    <div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlert}
          message="I love snacks"
          autoHideDuration={6000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity="error">
            กรุณากรอกข้อมูลธนาคารให้ครบถ้วน
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        {/* Alert for reset password */}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlertResetPW}
          message="I love snacks"
          autoHideDuration={6000}
          onClose={() => setOpenAlertResetPW(false)}
        >
          <Alert severity="error">
            {errorMessage}
            <IconButton
              aria-label="close"
              // color="inherit"
              // sx={{ p: 0.5 }}
              onClick={() => setOpenAlertResetPW(false)}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSuccessResetPW}
          autoHideDuration={6000}
          onClose={() => setOpenSuccessResetPW(false)}
        >
          <Alert
            onClose={() => setOpenSuccessResetPW(false)}
            severity="success"
            // sx={{ width: "100%" }}
            // color="inherit"
            // sx={{ p: 0.5 }}
          >
            {successMessage}
          </Alert>
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="responsive-dialog-title">
              {"Edit Digital Survey"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="1. Personal details" {...a11yProps(0)} />
                    <Tab label="2. Documents" {...a11yProps(1)} />
                  </Tabs>
                </Box>

                {user && (
                  <div>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography
                            className="bold cb"
                            variant="h6"
                            sx={{ mb: 2, mt: 2 }}
                          >
                            ตั้งค่า Username
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <TextField
                                // {...register("username", { required: true })}
                                {...register("username")}
                                aria-invalid={
                                  errors.username ? "true" : "false"
                                }
                                fullWidth
                                id="username"
                                label="Useranme"
                                // InputLabelProps={{ shrink: true }}
                                required
                                disabled={!isEmptyUsername}
                              />
                              {errors.username?.type === "required" && (
                                <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                              )}
                              {errorUsername !== "" && (
                                <div role="alert" className="cr tmd">
                                  {errorUsername}
                                </div>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                onClick={() => handleClickOpenConfirmResetPW()}
                              >
                                Reset Password
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item md={12}>
                          <Typography
                            className="bold cb"
                            variant="h6"
                            sx={{ mb: 2, mt: 2 }}
                          >
                            ข้อมูลการใช้งาน
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <TextField
                                {...register("empcode")}
                                fullWidth
                                id="empcode"
                                label="รหัสพนักงาน"
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("dp_type")}
                                  required
                                  id="dp_type"
                                  select
                                  label="ประเภทเซอร์เวย์"
                                  value={dp_type}
                                  variant="outlined"
                                  onChange={(event) => {
                                    setDp_type(event.target.value);
                                  }}
                                >
                                  {typeList.map((item) => (
                                    <MenuItem
                                      value={item.id}
                                      data-id={item.name}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                              {dp_type === "" && (
                                <p role="alert">This field is required</p>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("dp_branchID")}
                                  required
                                  id="dp_branchID"
                                  select
                                  label="สาขา"
                                  value={dp_branchID}
                                  variant="outlined"
                                  onChange={(event) => {
                                    setDp_branchID(event.target.value);
                                  }}
                                >
                                  {branchList.map((item) => (
                                    <MenuItem
                                      value={item.sys_branchID}
                                      data-id={item.branch_name}
                                    >
                                      {item.branch_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                              {dp_branchID === "" && (
                                <p role="alert">This field is required</p>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("dp_typeTax")}
                                  required
                                  id="dp_typeTax"
                                  select
                                  label="ประเภทหักภาษี"
                                  value={dp_typeTax}
                                  variant="outlined"
                                  onChange={(event) => {
                                    setDp_typeTax(event.target.value);
                                  }}
                                >
                                  {typeTax.map((item) => (
                                    <MenuItem
                                      value={item.id}
                                      data-id={item.name}
                                    >
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                              {dp_typeTax === "" && (
                                <p role="alert">This field is required</p>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography
                            className="bold cb"
                            variant="h6"
                            sx={{ mb: 2, mt: 2 }}
                          >
                            ข้อมูลส่วนตัว
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("firstname", { required: true })}
                            aria-invalid={errors.firstname ? "true" : "false"}
                            fullWidth
                            id="firstname"
                            label="Firstname"
                            variant="outlined"
                          />
                          {errors.firstname?.type === "required" && (
                            <p role="alert">Firstname is required</p>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("lastname", { required: true })}
                            aria-invalid={errors.lastname ? "true" : "false"}
                            fullWidth
                            id="lastname"
                            label="Lastname"
                            variant="outlined"
                          />
                          {errors.lastname?.type === "required" && (
                            <p role="alert">Lastname is required</p>
                          )}
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("gender")}
                            fullWidth
                            id="gender"
                            label="Gender"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              id="birthday"
                              label="Birthday"
                              value={birthday}
                              onChange={(newValue) => {
                                setBirthday(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  {...register("birthday", { required: true })}
                                  fullWidth
                                  id="birthday"
                                  value={birthday}
                                  variant="outlined"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("phone")}
                            fullWidth
                            id="phone"
                            label="Phone No."
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("IDcard")}
                            fullWidth
                            id="IDcard"
                            label="ID Card No."
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("email")}
                            fullWidth
                            id="email"
                            label="Email"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("address1")}
                            fullWidth
                            id="address1"
                            label="ที่อยู่ตามทะเบียนบ้าน"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("address2")}
                            fullWidth
                            id="address2"
                            label="ที่อยู่ปัจจุบัน"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("age")}
                            fullWidth
                            id="age"
                            label="อายุ (ปี)"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <FormControl fullWidth={true}>
                            <TextField
                              {...register("education_qual")}
                              required
                              id="education_qual"
                              select
                              label="ระดับการศึกษาสูงสุด"
                              value={education_qual}
                              variant="outlined"
                              onChange={(event) =>
                                setEducation_qual(event.target.value)
                              }
                            >
                              {educationList.map((item) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("education_desc")}
                            id="education_desc"
                            name="education_desc"
                            label="ชื่อสถาบัน / สาขาเอกที่สำเร็จการศึกษา"
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("emergency_name")}
                            fullWidth
                            id="emergency_name"
                            label="ชื่อบุคคลสำหรับติดต่อฉุกเฉิน"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("emergency_phone")}
                            fullWidth
                            id="emergency_phone"
                            label="เบอร์โทรบุคคลสำหรับติดต่อฉุกเฉิน"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            {...register("work_history")}
                            multiline
                            fullWidth
                            id="work_history"
                            label="ประวัติการทำงาน"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">
                              ท่านเคยต้องโทษในคดีแพ่งหรืออาญาหรือไม่
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={is_punish}
                              name="radio-buttons-group"
                              {...register("is_punish")}
                            >
                              <FormControlLabel
                                onChange={(event) => setIs_punish("1")}
                                value="1"
                                control={<Radio />}
                                label="ใช่"
                              />
                              <FormControlLabel
                                onChange={(event) => setIs_punish("0")}
                                value="0"
                                control={<Radio />}
                                label="ไม่"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <Typography
                            className="bold cb"
                            variant="h6"
                            sx={{ mb: 2, mt: 2 }}
                          >
                            ข้อมูลสมุดบัญชี
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <TextField
                                {...register("b_account_no")}
                                required
                                fullWidth
                                id="b_account_no"
                                label="เลขที่บัญชี"
                                variant="outlined"
                                disabled={isActive}
                              />
                            </Grid>
                            <Grid item md={12}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("bank_name")}
                                  required
                                  id="bank_name"
                                  select
                                  label="ธนาคาร"
                                  value={bank_name}
                                  variant="outlined"
                                  onChange={(event) =>
                                    setBank_name(event.target.value)
                                  }
                                  disabled={isActive}
                                >
                                  {bankList.map((item) => (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Grid>
                            <Grid item md={12}>
                              <TextField
                                {...register("b_account_name")}
                                required
                                fullWidth
                                id="b_account_name"
                                label="ชื่อบัญชีธนาคาร"
                                variant="outlined"
                                disabled={isActive}
                              />
                            </Grid>
                            <Grid item md={10}>
                              <Typography className="bold">
                                ปัจจุบันเป็นพนักงานของเรา?
                              </Typography>
                              <Typography variant="small">
                                (กด slide เพื่อ Inactive
                                กรณีไม่ใช่พนักงานของเราแล้ว)
                              </Typography>
                            </Grid>
                            <Grid item md={2}>
                              <Switch
                                checked={isActive}
                                onChange={handleChangeIsActive}
                                disabled={!is_employee}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={6}>
                          <Typography
                            className="bold cb"
                            variant="h6"
                            sx={{ mt: 2 }}
                          >
                            ประเภทพนักงาน
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...register("acc_employee")}
                                      checked={acc_employee}
                                      id="acc_employee"
                                      onChange={() =>
                                        setAcc_employee(!acc_employee)
                                      }
                                    />
                                  }
                                  label="พนักงานสำรวจอุบัติเหตุ"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...register("insp_employee")}
                                      checked={insp_employee}
                                      id="insp_employee"
                                      onChange={() =>
                                        setInsp_employee(!insp_employee)
                                      }
                                    />
                                  }
                                  label="พนักงานตรวจสภาพรถยนต์"
                                />
                              </FormGroup>
                            </Grid>
                            <Grid item md={12}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("province")}
                                  id="province"
                                  select
                                  label="จังหวัด"
                                  value={provinceEmp}
                                  onChange={(event) => {
                                    setProvinceEmp(event.target.value);
                                    setAmphurEmp("");
                                    getAmphurEmp(event.target.value);
                                  }}
                                >
                                  {provinceList.map((item) => (
                                    <MenuItem value={item.provinceID}>
                                      {item.provincename}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Grid>
                            <Grid item md={12}>
                              <FormControl fullWidth={true}>
                                <TextField
                                  {...register("amphur")}
                                  id="amphur"
                                  select
                                  label="อำเภอ"
                                  value={amphurEmp}
                                  onChange={(event) => {
                                    setAmphurEmp(event.target.value);
                                  }}
                                >
                                  {amphurListEmp.map((item) => (
                                    <MenuItem
                                      key={`A${item.amphurID}`}
                                      value={item.amphurID}
                                    >
                                      {item.amphurname}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 3 }}
                        justifyContent="flex-end"
                      >
                        <Button variant="contained" onClick={() => setValue(1)}>
                          Continue
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        {/* IMAGE */}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          {images.length > 0
                            ? images.map((item) => (
                                <img src={item} height="500px" />
                              ))
                            : ""}
                        </Popover>
                        {/* IMAGE2 */}
                        <Popover
                          id="mouse-over-popover2"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge2}
                          anchorEl={anchorEl2}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          // transformOrigin={{
                          //   vertical: "top",
                          //   horizontal: "left",
                          // }}
                          onClose={handlePopoverClose2}
                          disableRestoreFocus
                        >
                          {imagesCard.length > 0
                            ? imagesCard.map((item) => (
                                <img src={item} height="500px" />
                              ))
                            : ""}
                        </Popover>
                        {/* IMAGE3 */}
                        <Popover
                          id="mouse-over-popover3"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge3}
                          anchorEl={anchorEl3}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose3}
                          disableRestoreFocus
                        >
                          {imagesLicense.length > 0
                            ? imagesLicense.map((item) => (
                                <img src={item} height="500px" />
                              ))
                            : ""}
                        </Popover>
                        {/* IMAGE4 */}
                        <Popover
                          id="mouse-over-popover4"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge4}
                          anchorEl={anchorEl4}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose4}
                          disableRestoreFocus
                        >
                          {imagesBank.length > 0
                            ? imagesBank.map((item) => (
                                <img src={item} height="500px" />
                              ))
                            : ""}
                        </Popover>
                        {/* IMAGE5 */}
                        <Popover
                          id="mouse-over-popover5"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge5}
                          anchorEl={anchorEl5}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose5}
                          disableRestoreFocus
                        >
                          {imagesHouse.length > 0
                            ? imagesHouse.map((item) => (
                                <img src={item} height="500px" />
                              ))
                            : ""}
                        </Popover>
                        {/* IMAGE6 */}
                        {/* <Popover
                          id="mouse-over-popover6"
                          sx={{
                            pointerEvents: "none",
                          }}
                          open={openLarge6}
                          anchorEl={anchorEl6}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose6}
                          disableRestoreFocus
                        >
                          {imagesContract.length > 0
                            ? imagesContract.map((item) => (
                                // <img src={item} height="500px" />
                                <Document file={item}>
                                  <Page pageNumber={1} width={300} />
                                </Document>
                              ))
                            : ""}
                        </Popover> */}
                        {/* end TEST */}

                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">ถ่ายรูปหน้าตรง</div>
                          </Grid>
                          {/* TEST */}
                          {/* <Typography
                            aria-owns={
                              openLarge ? "mouse-over-popover" : undefined
                            }
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                          >
                            Hover with a Popover.
                          </Typography> */}
                          {/* <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={openLarge}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography sx={{ p: 1 }}>
                              I use Popover.
                            </Typography>
                          </Popover> */}

                          {/* IMAGE */}
                          {/* <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: "none",
                            }}
                            open={openLarge}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <img src="/img/user.png" />
                          </Popover> */}
                          {/* end TEST */}
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {images.length > 0 ? (
                                images.map((item) => (
                                  <Img
                                    src={item}
                                    aria-owns={
                                      openLarge
                                        ? "mouse-over-popover"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                  />
                                ))
                              ) : (
                                <ButtonBase sx={{ height: 90 }}>
                                  <Img alt="complex" src="/img/user.png" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChange}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">
                              บัตรประจำตัวประชาชน
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {imagesCard.length > 0 ? (
                                imagesCard.map((item) => (
                                  <Img
                                    src={item}
                                    aria-owns={
                                      openLarge2
                                        ? "mouse-over-popover2"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen2}
                                    onMouseLeave={handlePopoverClose2}
                                  />
                                ))
                              ) : (
                                <ButtonBase sx={{ height: 90 }}>
                                  <Img alt="complex" src="/img/citizen.png" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChangeCard}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">ใบขับขี่</div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {imagesLicense.length > 0 ? (
                                imagesLicense.map((item) => (
                                  <Img
                                    src={item}
                                    aria-owns={
                                      openLarge3
                                        ? "mouse-over-popover3"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen3}
                                    onMouseLeave={handlePopoverClose3}
                                  />
                                ))
                              ) : (
                                <ButtonBase sx={{ height: 90 }}>
                                  <Img alt="complex" src="/img/driver.png" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChangeLicense}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">สมุดบัญชีธนาคาร</div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {imagesBank.length > 0 ? (
                                imagesBank.map((item) => (
                                  <Img
                                    src={item}
                                    aria-owns={
                                      openLarge4
                                        ? "mouse-over-popover4"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen4}
                                    onMouseLeave={handlePopoverClose4}
                                  />
                                ))
                              ) : (
                                <ButtonBase sx={{ height: 90 }}>
                                  <Img alt="complex" src="/img/driver.png" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChangeBank}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          mt: 2,
                          mb: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">ทะเบียนบ้าน</div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {imagesHouse.length > 0 ? (
                                imagesHouse.map((item) => (
                                  <Img
                                    src={item}
                                    aria-owns={
                                      openLarge5
                                        ? "mouse-over-popover5"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen5}
                                    onMouseLeave={handlePopoverClose5}
                                  />
                                ))
                              ) : (
                                <ButtonBase sx={{ height: 90 }}>
                                  <Img alt="complex" src="/img/home.jpg" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  accept="image/*"
                                  onChange={onImageChangeHouse}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          mt: 2,
                          mb: 2,
                          maxWidth: 500,
                          flexGrow: 1,
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark"
                              ? "#1A2027"
                              : "#EAEAEA",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item md={12}>
                            <div className="txl semiBold">สัญญาจ้างงาน</div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            <Grid item md={6}>
                              {imagesContract.length > 0 ? (
                                imagesContract.map((item) =>
                                  thumb ? (
                                    <img
                                      src={thumb}
                                      alt="pdf preview"
                                      className="rounded overflow-hidden"
                                    />
                                  ) : item.includes("storage") ? (
                                    <a href={item} target="_blank">
                                      View PDF file
                                    </a>
                                  ) : (
                                    <Document file={item}>
                                      <Page
                                        onRenderSuccess={() => {
                                          canvasRef?.current?.toBlob((blob) =>
                                            setThumb(URL.createObjectURL(blob))
                                          );
                                        }}
                                        height={100}
                                        canvasRef={canvasRef}
                                        className="rounded overflow-hidden shadow-lg "
                                        renderTextLayer={false}
                                        pageNumber={1}
                                      />
                                    </Document>
                                  )
                                )
                              ) : (
                                <ButtonBase sx={{ height: 90 }} disabled={true}>
                                  <Img alt="complex" src="/img/contract.png" />
                                </ButtonBase>
                              )}
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                component="label"
                                startIcon={<FileUploadIcon />}
                              >
                                Select file
                                <input
                                  hidden
                                  type="file"
                                  // accept="image/*"
                                  accept="application/pdf"
                                  onChange={onImageChangeContract}
                                  capture
                                />
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                      {isPassInterview && isWaitInterview && (
                        <Grid
                          container
                          sx={{ mt: 3, p: 2, backgroundColor: "#fdf5f2" }}
                          justifyContent="flex-start"
                        >
                          <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">
                              อนุมัติให้ผ่านสัมภาษณ์
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={interview}
                              onChange={handleChangeInterview}
                            >
                              <FormControlLabel
                                value="P"
                                control={<Radio />}
                                label="ผ่าน"
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="ไม่ผ่าน"
                              />
                            </RadioGroup>
                          </FormControl>

                          {/* ----------------- CANCEL---------------- */}

                          {/* <Grid item sm={6}>
                            <Typography className="bold">
                              อนุมัติให้ผ่านสัมภาษณ์
                            </Typography>
                            <Typography variant="small">
                              (กด slide เพื่ออนุมัติ)
                            </Typography>
                          </Grid>

                          <Grid item md={2}>
                            <Switch
                              checked={waitInterview}
                              onChange={handleChangeInterview}
                            />
                          </Grid> */}
                        </Grid>
                      )}

                      {isPassInterview && !isWaitInterview && !isEmployee && (
                        <Grid
                          container
                          sx={{ mt: 3, p: 2, backgroundColor: "#fdf5f2" }}
                          justifyContent="flex-start"
                        >
                          <Grid item sm={6}>
                            <Typography className="bold">
                              อนุมัติให้เป็นพนักงานของเรา
                            </Typography>
                            <Typography variant="small">
                              (กด slide เพื่ออนุมัติ)
                            </Typography>
                          </Grid>

                          <Grid item md={2}>
                            <Switch
                              checked={is_employee}
                              onChange={handleChangeIs_employee}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 3 }}
                        justifyContent="flex-end"
                      >
                        <Alert
                          severity="error"
                          className={errorMessage === "" ? "hide" : ""}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={handleCloseMsgAlert}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                        >
                          {errorMessage}
                        </Alert>
                        <LoadingButton
                          type="submit"
                          loading={isLoading}
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                        <Button onClick={handleClose}>Cancel</Button>
                      </Grid>
                    </TabPanel>
                    {/* Confirm reset password popup*/}
                    <Dialog
                      open={openConfirmResetPW}
                      onClose={handleCloseConfirmResetPW}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Reset Password"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure to confirm reset password?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseConfirmResetPW}>
                          Cancle
                        </Button>
                        <Button onClick={() => resetPassword()} autoFocus>
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                )}
                {!user && (
                  <div className="text-center pmdt">
                    <CircularProgress />
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to confirm delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>Cancle</Button>
            <Button onClick={() => _delete()} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <ExportExcel excelData={ExcelExportData} fileName={"Excel Export"} />
        </Grid> */}
        <Grid item xs={4}>
          <FormControl fullWidth={true}>
            <TextField
              sx={{ flex: 1, p: "0px", mb: "20px" }}
              placeholder="Search employee name"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  setPage(0);
                  getData(
                    0,
                    rowsPerPage,
                    province,
                    amphur,
                    searchName,
                    dataType,
                    empStatus
                  );
                  ev.preventDefault();
                }
              }}
              InputProps={{
                "aria-label": "search employee name",
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      type="button"
                      sx={{ p: "0px" }}
                      aria-label="search"
                      onClick={() =>{
                        setPage(0);
                        getData(
                          0,
                          rowsPerPage,
                          province,
                          amphur,
                          searchName,
                          dataType,
                          empStatus
                        )}
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth={true}>
            <TextField
              id="outlined-select-dataType"
              select
              label="ตำแหน่ง"
              value={dataType}
              onChange={(event) => {
                setPage(0);
                setDataType(event.target.value);
                getData(
                  0,
                  rowsPerPage,
                  province,
                  amphur,
                  searchName,
                  event.target.value,
                  empStatus
                );
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: dataType ? "visible" : "hidden" }}
                    onClick={handleClearDataTypeClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            >
              {dataTypeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth={true}>
            <TextField
              id="outlined-select-province"
              select
              label="จังหวัด"
              value={province}
              onChange={(event) => {
                setPage(0);
                setProvince(event.target.value);
                setAmphur("");
                getAmphur(event.target.value);
                getData(
                  0,
                  rowsPerPage,
                  event.target.value,
                  "",
                  searchName,
                  dataType,
                  empStatus
                );
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: province ? "visible" : "hidden" }}
                    onClick={handleClearClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            >
              {provinceList.map((item) => (
                <MenuItem key={`P${item.provinceID}`} value={item.provinceID}>
                  {item.provincename}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth={true}>
            <TextField
              id="outlined-select-amphur"
              select
              label="อำเภอ"
              value={amphur}
              onChange={(event) => {
                setPage(0);
                setAmphur(event.target.value);
                getData(
                  0,
                  rowsPerPage,
                  province,
                  event.target.value,
                  searchName,
                  dataType,
                  empStatus
                );
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: amphur ? "visible" : "hidden" }}
                    onClick={handleClearClickAmphur}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            >
              {amphurList.map((item) => (
                <MenuItem key={`A${item.amphurID}`} value={item.amphurID}>
                  {item.amphurname}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth={true}>
            <TextField
              id="outlined-select-status"
              select
              label="สถานะ"
              value={empStatus}
              onChange={(event) => {
                setPage(0);
                setEmpStatus(event.target.value);
                getData(
                  0,
                  rowsPerPage,
                  province,
                  amphur,
                  searchName,
                  dataType,
                  event.target.value
                );
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: empStatus ? "visible" : "hidden" }}
                    onClick={handleClearEmpStatusClick}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            >
              {statusList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell></TableCell>
              <TableCell>Employee name</TableCell>
              {/* <TableCell>Login</TableCell> */}
              <TableCell>Location</TableCell>
              <TableCell>Branch</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <div className={loading === true ? "loading" : "hide"}>
              <CircularProgress />
            </div>

            {dataRow.map((row, i) => {
              var statusTitle = "";
              var statusStyle = "cg";
              if (row.is_employee === 1) {
                statusTitle = "เป็นพนักงาน";
                statusStyle = "cgr";
                if (row.isActive === 0) {
                  statusTitle = "ไม่เป็นพนักงาน";
                  statusStyle = "cg";
                }
              } else {
                if (row.interview_flag === "W") {
                  statusTitle = "รอสัมภาษณ์";
                  statusStyle = "corg";
                } else if (row.interview_flag === "P") {
                  statusTitle = "ผ่านสัมภาษณ์";
                  statusStyle = "cs";
                } else if (row.interview_flag === "N") {
                  statusTitle = "ไม่ผ่านสัมภาษณ์";
                  statusStyle = "cr";
                }
              }

              return (
                <TableRow key={row.extID}>
                  <TableCell component="th" scope="row">
                    {i + 1 + page * rowsPerPage}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {row.firstname.slice(3, 4)}
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {row.firstname} {row.lastname}
                    </Typography>
                    <Typography>Phone: {row.phone}</Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography>{row.user}</Typography>
                    <Typography>{row.password}</Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography>จังหวัด: {row.province_desc}</Typography>
                    <Typography>อำเภอ: {row.amphur_desc}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.branch_name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={statusStyle}>
                      {statusTitle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => handleClickOpen(row.extID)}
                    >
                      <EditIcon color="success" />
                    </IconButton>
                    {!row.is_employee ? (
                      <IconButton
                        aria-label="Delete"
                        onClick={() => handleClickOpenConfirm(row.extID)}
                      >
                        <DeleteIcon color="red" />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
