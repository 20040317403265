import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Slide from "@mui/material/Slide";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
// import ExportExcelSSBilling from "../forms/ExportExcelSSBilling";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";

function JobPoolConfig() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(moment());
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [searchName, setSearchName] = useState("");
  const [claimNo, setClaimNo] = useState("");
  const [searchEmpType, setSearchEmpType] = useState("");
  const [approveData, setApproveData] = useState({});
  const [billNo, setBillNo] = useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/jobpool/config`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        if (response.status === 200 && response.data) {
          var item = response.data;
          setUser({
            radius_pool: item.radius_pool,
          });
          setLoading(false);
        } else {
          setUser({
            radius_pool: null,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let NumberForm = new Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  function onSubmit(data) {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Verify number must not less then zero
    if (data.radius_pool < 0) {
      setErrorMessage("กรุณาใส่ค่ามากกว่าศูนย์");
      setIsLoading(false);
      return false;
    }

    var dataForm = JSON.stringify({
      radius_pool: data.radius_pool,
    });
    // console.log(`dataForm`, dataForm);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/jobpool/update`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        setValue(0);
        setIsLoading(false);
        if (response.status === 200 && response.data.success === true) {
          setSuccessMessage(response.data.message);

          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(response.data.message);
        }
        getData(); // Reload config
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    return false;
  }

  return (
    <>
      <Box>
        <Box
          sx={{
            pb: 2,
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Job Pool Config
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item md={12}>
                <FormControl>
                  <div className="pxl">
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Grid container spacing={2}>
                          <Grid item md={6} sx={{ mt: 2 }}>
                            <Typography>รัศมีการจ่าย/แจ้ง Job pool</Typography>
                          </Grid>
                          <Grid item md={5} className="right">
                            <TextField
                              {...register("radius_pool")}
                              required
                              aria-invalid={
                                errors.radius_pool ? "true" : "false"
                              }
                              id="radius_pool"
                              type="number"
                            />
                          </Grid>
                          <Grid item md={1} sx={{ mt: 2 }}>
                            km.
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </FormControl>
              </Grid>

              <div className="pxl">
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 3 }}
                  justifyContent="flex-end"
                >
                  <Alert
                    severity="error"
                    className={errorMessage === "" ? "hide" : ""}
                  >
                    {errorMessage}
                  </Alert>
                  <Alert
                    severity="success"
                    className={successMessage === "" ? "hide" : ""}
                  >
                    {successMessage}
                  </Alert>
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                  {/* <Button onClick={handleClose}>Cancel</Button> */}
                  <Button onClick={(event) => getData()}>Cancel</Button>
                </Grid>
              </div>
            </form>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default function JobPool() {
  return <JobPoolConfig />;
}
