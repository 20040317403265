import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://mui.com/">
                iSurvey DP
            </Link>{" "}
            v{process.env.REACT_APP_VERSION} {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createTheme();

export default function SignIn({ useAuth }) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const { onLogin } = useAuth();
    const handleSubmit = (event) => {
        event.preventDefault();

        setIsLoading(true)
        setErrorMessage('')
        const data = new FormData(event.currentTarget);
        var formData = new FormData();
        formData.append("username", data.get("email"));
        formData.append("password", data.get("password"));

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_API_URL}api/v1/login`,
            headers: {
                "Accept": "application/json",
            },
            data: formData,
        };

        axios(config)
            .then(function (response) {
                // console.log(response);
                var token = response.data.access_token

                var config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}api/v1/user/me`,
                    headers: { 
                      'Authorization': `Bearer ${token}`
                    }
                };
                // console.log(config);
                  
                axios(config)
                .then(function (response) {
                    response.data.token = token
                    onLogin(response.data)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    setErrorMessage(error.message)
                });
            })
            .catch(function (error) {
                setIsLoading(false)
                setErrorMessage(error.message)
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage:
                            "url(/img/logo_dp.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                            t.palette.mode === "light"
                                ? t.palette.grey[50]
                                : t.palette.grey[900],
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                    }}
                />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Alert severity="error" className={errorMessage === "" ? 'hide' : ''} >{errorMessage}</Alert>
                            <LoadingButton
                                type="submit"
                                fullWidth
                                loading={isLoading}
                                loadingIndicator="Loading…"
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </LoadingButton>
                            <Grid container>
                                <Grid item xs>
                                    {/* <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link> */}
                                </Grid>
                                <Grid item>
                                    <Link to="/Register" variant="body2">
                                        {"Register"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
