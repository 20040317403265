import React, { useState, useEffect } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import { useLocation, Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
// import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";

export default function ListMenu() {
  const userRole = JSON.parse(sessionStorage.getItem("token")).rolename;
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);

  useEffect(() => {
    if (
      location.pathname === "/Super/Register" ||
      location.pathname === "/Super/SurInfo" ||
      location.pathname === "/Super/Monitor"
      // location.pathname === "/Super/Payment"
    ) {
      setOpen(true);
    } else if (location.pathname === "/Outsource/Register") {
      setOpen2(true);
    } else if (
      location.pathname === "/Customer/Form" ||
      location.pathname === "/Customer/Report"
    ) {
      setOpen3(true);
    } else if (
      location.pathname === "/Billing/Month" ||
      location.pathname === "/Billing/Super" ||
      location.pathname === "/Billing/List"
    ) {
      setOpen4(true);
    } else if (
      location.pathname === "Payment/Super" ||
      location.pathname === "Payment/Outsource"
    ) {
      setOpen5(true);
    } else if (location.pathname === "Jobpool/Config") {
      setOpen6(true);
    }
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };
  const handleClick6 = () => {
    setOpen6(!open6);
  };

  console.log(location.pathname);
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {userRole === "System Admin" ||
      userRole === "Surveyor Admin" ||
      userRole === "Surveyor Monitor" ? (
        <>
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <PermContactCalendarOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Digital Survey" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {userRole === "System Admin" || userRole === "Surveyor Admin" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Super/Register"
                  selected={location.pathname === "/Super/Register" && true}
                >
                  <ListItemText primary="New Registration" />
                </ListItemButton>
              ) : (
                ""
              )}

              {userRole === "System Admin" || userRole === "Surveyor Admin" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Super/SurInfo"
                  selected={location.pathname === "/Super/SurInfo" && true}
                >
                  <ListItemText primary="Surveyor Info" />
                </ListItemButton>
              ) : (
                ""
              )}

              {userRole === "System Admin" ||
              userRole === "Surveyor Admin" ||
              userRole === "Surveyor Monitor" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Super/Monitor"
                  selected={location.pathname === "/Super/Monitor" && true}
                >
                  <ListItemText primary="Operation Monitor" />
                </ListItemButton>
              ) : (
                ""
              )}
            </List>
          </Collapse>
        </>
      ) : (
        ""
      )}

      {userRole === "System Admin" ? (
        <>
          <ListItemButton onClick={handleClick2}>
            <ListItemIcon>
              <ThumbUpOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Outsource" />
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Outsource/Register"
                selected={location.pathname === "/Outsource/Register" && true}
              >
                <ListItemText primary="Registration" />
              </ListItemButton>
              {/* <ListItemButton
            sx={{ pl: 7 }}
            component={Link}
            to="/Outsource/Payment"
            selected={location.pathname === "/Outsource/Payment" && true}
          >
            <ListItemText primary="Payment Configuration" />
          </ListItemButton> */}
              {/* <ListItemButton
            sx={{ pl: 7 }}
            component={Link}
            to="/Outsource/Billing"
            selected={location.pathname === "/Outsource/Billing" && true}
          >
            <ListItemText primary="Billing Report" />
          </ListItemButton> */}
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Outsource/Monitor"
                selected={location.pathname === "/Outsource/Monitor" && true}
              >
                <ListItemText primary="Operation Monitor" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClick5}>
            <ListItemIcon>
              <PriceChangeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Payment Configuration" />
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Payment/Super"
                selected={location.pathname === "/Payment/Super" && true}
              >
                <ListItemText primary="Digital Survey" />
              </ListItemButton>

              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Payment/Outsource"
                selected={location.pathname === "/Payment/Outsource" && true}
              >
                <ListItemText primary="ค่าบริการบริษัทประกัน" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClick6}>
            <ListItemIcon>
              <AddLocationAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Job Pool" />
            {open6 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open6} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Jobpool/Config"
                selected={location.pathname === "/Jobpool/Config" && true}
              >
                {/* <ListItemText primary="รัศมีการจ่าย-แจ้ง" /> */}
                <ListItemText primary="Job pool config" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={handleClick3}>
            <ListItemIcon>
              <AddReactionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Customer Satisfaction" />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Customer/Form"
                selected={location.pathname === "/Customer/Form" && true}
              >
                <ListItemText primary="Evaluation Form" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 7 }}
                component={Link}
                to="/Customer/Report"
                selected={location.pathname === "/Customer/Report" && true}
              >
                <ListItemText primary="CS Report" />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      ) : (
        ""
      )}

      {userRole === "System Admin" ||
      userRole === "Billing Approve" ||
      userRole === "Billing Account" ? (
        <>
          <ListItemButton onClick={handleClick4}>
            <ListItemIcon>
              <RequestQuoteOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Billing" />
            {open4 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {userRole === "System Admin" || userRole === "Billing Approve" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Billing/Super"
                  selected={location.pathname === "/Billing/Super" && true}
                >
                  <ListItemText primary="อนุมัติจ่าย" />
                </ListItemButton>
              ) : (
                ""
              )}

              {userRole === "System Admin" || userRole === "Billing Account" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Billing/Month"
                  selected={location.pathname === "/Billing/Month" && true}
                >
                  <ListItemText primary="วางบิล" />
                </ListItemButton>
              ) : (
                ""
              )}

              {userRole === "System Admin" ? (
                <ListItemButton
                  sx={{ pl: 7 }}
                  component={Link}
                  to="/Billing/List"
                  selected={location.pathname === "/Billing/List" && true}
                >
                  <ListItemText primary="รายการเลขตั้งเบิก" />
                </ListItemButton>
              ) : (
                ""
              )}
            </List>
          </Collapse>
        </>
      ) : (
        ""
      )}
    </List>
  );
}
