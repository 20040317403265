import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  // Avatar,
  // Box,
  Button,
  styled,
  Typography,
  // TextField,
  // MenuItem,
  // Input,
  // InputAdornment,
  IconButton,
} from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const ExportExcelSSBilling = ({
  fileName,
  province,
  amphur,
  searchName,
  claimNo,
  dateStart,
  dateEnd,
  userData,
  searchEmpType,
  billNo,
}) => {
  const [excelData, setExcelData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [txtConfirm, setTxtConfirm] = useState("");
  const [txtWarning, setTxtWarning] = useState("");

  function getExcelData() {
    setIsLoading(true);

    if (
      (dateStart === null || dateEnd === null) &&
      billNo.length === 0 &&
      searchEmpType.length === 0 &&
      claimNo.length === 0 &&
      searchName.length === 0 &&
      amphur.length === 0 &&
      province.length === 0
    ) {
      // setIsLoading(false);
      setTxtWarning("กรุณากรอกคำค้นหาที่ต้องการ Export");
      return;
    } else if (dateStart === "Invalid Date" || dateEnd === "Invalid Date") {
      // setIsLoading(false);
      setTxtWarning("วันที่จ่ายงานไม่ถูกต้อง ไม่สามารถ Export ได้");
      return;
    }

    // const ssvOnly = "NO";

    //>> Get billing list
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/export/ssbill/paylist?province=${province}&amphur=${amphur}&name=${searchName}&claim_no=${claimNo}&start=${dateStart}&end=${dateEnd}&emptype=${searchEmpType}&bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        if (response.data.length !== 0) {
          setIsLoading(false);
          setExcelData(response.data);
        } else {
          setTxtWarning("ไม่พบข้อมูลสำหรับ Export");
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // handleCloseExport();
  }

  const handleClickExportConfirm = () => {
    setTxtWarning("");
    if (dateStart !== null && dateEnd !== null) {
      setTxtConfirm(
        `รอบบิลวันที่ ${moment(dateStart).format("DD/MM/YYYY")} ถึง ${moment(
          dateEnd
        ).format("DD/MM/YYYY")} ? `
      );
    }
    setOpenConfirm(true);
    getExcelData();
  };
  const handleCloseExport = () => {
    setOpenConfirm(false);
  };

  const wscols = [
    {
      wch: 8,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.claim_no.length)),
      wch: 14,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.company.length)),
      wch: 18,
    },
    {
      // wch: Math.max(
      //   ...excelData.map((exdata) => exdata.dispatch_datetime.length)
      // ),
      wch: 15,
    },
    {
      // wch: Math.max(
      //   ...excelData.map((exdata) => exdata.claim_Mtype_desc.length)
      // ),
      wch: 10,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.acc_zone.length)),
      wch: 15,
    },
    {
      // wch: Math.max(
      //   ...excelData.map((exdata) => exdata.survey_amphur_desc.length)
      // ),
      wch: 13,
    },
    {
      // wch: Math.max(
      //   ...excelData.map((exdata) => exdata.survey_province_desc.length)
      // ),
      wch: 13,
    },
    {
      wch: 15,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.surveyorName.length)),
      wch: 22,
    },
    {
      // service_fee
      wch: 10,
    },
    {
      // wch: Math.max(
      //   ...excelData.map((exdata) => exdata.traveling_expense.length)
      // ),
      wch: 10,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.claim_payment.length)),
      wch: 10,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.other.length)),
      wch: 10,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.invest_payment.length)),
      wch: 25,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.vat.length)),
      wch: 10,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.total.length)),
      wch: 12,
    },
    {
      wch: 10,
    },
    {
      wch: 12,
    },
  ];

  // ******** XLSX with new header *************

  const exportToExcel = (excelData, fileName, wscols) => {
    // const exportToExcel = async () => {

    var ws = XLSX.utils.aoa_to_sheet([
      [
        "id",
        "เลขเคลม",
        "บริษัท",
        "วันที่จ่ายงาน",
        "ประเภทเคลม",
        "พื้นที่",
        "อำเภอ",
        "จังหวัด",
        "ศูนย์พนักงาน",
        "ชื่อพนักงาน",
        "ค่าบริการ",
        "ค่าพาหนะจ่าย",
        "เรียกร้องจ่าย",
        "ค่าใช้จ่ายอื่นๆ",
        "ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี",
        "หักภาษี 3%",
        "ยอดจ่าย",
        "เลขตั้งเบิก",
        "สถานะการอนุมัติ",
      ],
    ]);
    ws["!cols"] = wscols;

    //>> Set Cell style
    // //--> first row style
    // for (let i = 0; i < 9; i++) {
    //   ws[
    //     XLSX.utils.encode_cell({
    //       c: i,
    //       r: 0,
    //     })
    //   ].s = {
    //     font: {
    //       name: "Arial",
    //       sz: 10,
    //       // bold: true,
    //       // color: "#F2F2F2",
    //     },
    //     alignment: {
    //       // vertical: "center",
    //       horizontal: "center",
    //       // wrapText: "1", // any truthy value here
    //     },
    //     fill: {
    //       patternType: "solid",
    //       fgColor: { rgb: "D3EBF7" },
    //       bgColor: { rgb: "D3EBF7" },
    //     },
    //     border: {
    //       top: { style: "thin", color: "black" },
    //       bottom: { style: "thin", color: "black" },
    //       left: { style: "thin", color: "black" },
    //       right: { style: "thin", color: "black" },
    //     },
    //   };
    // }

    //--> Set header style
    // for (let i = 0; i < 16; i++) {
    for (let i = 0; i < 19; i++) {
      ws[
        XLSX.utils.encode_cell({
          c: i,
          r: 0,
        })
      ].s = {
        font: {
          name: "Angsana New",
          sz: 14,
          bold: true,
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D3D3D3" },
          bgColor: { rgb: "D3D3D3" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: "black" },
          bottom: { style: "thin", color: "black" },
          left: { style: "thin", color: "black" },
          right: { style: "thin", color: "black" },
        },
      };

      if (i === 15 || i === 16) {
        ws[
          XLSX.utils.encode_cell({
            c: i,
            r: 0,
          })
        ].s = {
          font: {
            name: "Angsana New",
            sz: 14,
            bold: true,
            // color: { rgb: "FF0000" },
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFF2CC" },
            bgColor: { rgb: "FFF2CC" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin", color: "black" },
            bottom: { style: "thin", color: "black" },
            left: { style: "thin", color: "black" },
            right: { style: "thin", color: "black" },
          },
        };
      }
    }

    // ws["A2"].s = {
    //   font: {
    //     name: "Angsana New",
    //     sz: 16,
    //   },
    // };

    //Convert data string to int
    for (let i = 0; i < excelData.length; i++) {
      excelData[i].invest_payment = parseFloat(excelData[i].invest_payment);
      excelData[i].service_fee = parseFloat(excelData[i].service_fee);
      excelData[i].traveling_expense = parseFloat(
        excelData[i].traveling_expense
      );
      excelData[i].claim_payment = parseFloat(excelData[i].claim_payment);
      excelData[i].other = parseFloat(excelData[i].other);
      excelData[i].vat = parseFloat(excelData[i].vat);
      excelData[i].total = parseFloat(excelData[i].total);

      //Convert status flag to wording
      excelData[i].bill_approve_flag =
        excelData[i].bill_approve_flag === "A"
          ? "วางบิล"
          : excelData[i].bill_approve_flag === "S"
          ? "ตั้งเบิก"
          : excelData[i].bill_approve_flag === "R"
          ? "ยกเลิก"
          : "";
    }

    //ONLY Data Added here
    XLSX.utils.sheet_add_json(ws, excelData, {
      header: [
        "dispID",
        "claim_no",
        "company",
        "dispatch_datetime",
        "claim_Mtype_desc",
        "acc_zone",
        "survey_amphur_desc",
        "survey_province_desc",
        "branch_name",
        "surveyorName",
        "service_fee",
        "traveling_expense",
        "claim_payment",
        "other",
        "invest_payment",
        "vat",
        "total",
        "bill_paymentNo",
        "bill_approve_flag",
      ],
      skipHeader: true,
      origin: "A2", //ok -1
    });

    //--> Set body style
    var maxRow = 1 + excelData.length;
    // for (let i = 0; i < 16; i++) {
    for (let i = 0; i < 19; i++) {
      for (let j = 1; j < maxRow; j++) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
          };
        }

        if (i === 10 || i === 11 || i === 12 || i === 13 || i === 14) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
              // bold: true,
            },
            // fill: {
            //   patternType: "solid",
            //   fgColor: { rgb: "FFF2CC" },
            //   bgColor: { rgb: "FFF2CC" },
            // },
            alignment: {
              vertical: "center",
              horizontal: "right",
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
          };

          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s.numFmt = "#,##0.00";
          }
        }

        // // _ * #,##0.00_ ;_ * -#,##0.00_ ;_ * "-"??_ ;_ @_
        // if (i === 12) {
        //   ws[
        //     XLSX.utils.encode_cell({
        //       c: i,
        //       r: j,
        //     })
        //   ].s = {
        //     font: {
        //       name: "Angsana New",
        //       sz: 14,
        //       // bold: true,
        //     },
        //     // fill: {
        //     //   patternType: "solid",
        //     //   fgColor: { rgb: "FFF2CC" },
        //     //   bgColor: { rgb: "FFF2CC" },
        //     // },
        //     alignment: {
        //       vertical: "center",
        //       horizontal: "right",
        //     },
        //     border: {
        //       top: { style: "thin", color: "black" },
        //       bottom: { style: "thin", color: "black" },
        //       left: { style: "thin", color: "black" },
        //       right: { style: "thin", color: "black" },
        //     },
        //   };

        //   if (
        //     ws[
        //       XLSX.utils.encode_cell({
        //         c: i,
        //         r: j,
        //       })
        //     ]
        //   ) {
        //     ws[
        //       XLSX.utils.encode_cell({
        //         c: i,
        //         r: j,
        //       })
        //       // ].s.numFmt = "_ * #,##0.00_ ;_ * -#,##0.00_ ;_ * " - "??_ ;_ @_";
        //     ].s.numFmt = "#,##0.00"; // Todo
        //   }
        // }

        if (i === 15 || i === 16) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
              // bold: true,
            },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "FFF2CC" },
              bgColor: { rgb: "FFF2CC" },
            },
            alignment: {
              vertical: "center",
              horizontal: "right",
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
          };

          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s.numFmt = "#,##0.00";
          }
        }
      }
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    handleCloseExport();
    setExcelData([]);
  };

  return (
    <>
      <Button
        variant="text"
        type="submit"
        startIcon={<UploadIcon />}
        size="large"
        sx={{ mt: 1, ml: 1 }}
        onClick={() => handleClickExportConfirm()}
      >
        Export
      </Button>

      <Dialog
        open={openConfirm}
        onClose={handleCloseExport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Export"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ต้องการ export ตั้งเบิกค่าพาพนะ(สรุปส่งจ่าย)
            <br />
            {txtConfirm}
            <br />
            <Typography component="span" color="error">
              {txtWarning}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExport}>Cancle</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={(e) => exportToExcel(excelData, fileName, wscols)}
          >
            EXPORT
          </LoadingButton>

          {/* <Button
            onClick={(e) => exportToExcel(excelData, fileName, wscols)}
            autoFocus
          >
            EXPORT
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportExcelSSBilling;
