import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  // Avatar,
  // Box,
  Button,
  styled,
  Typography,
  // TextField,
  // MenuItem,
  // Input,
  // InputAdornment,
  IconButton,
} from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

const ExportExcel2Files = ({
  exFileName1,
  exFileName2,
  // province,
  // amphur,
  // searchName,
  // dateStart,
  // dateEnd,
  userData,
  // searchEmpType,
  billNo,
}) => {
  const [excelDataVehicle, setExcelDataVehicle] = useState([]);
  const [excelDataBank, setExcelDataBank] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [txtConfirm, setTxtConfirm] = useState("");
  const [txtWarning, setTxtWarning] = useState("");
  const [fileName, setFileName] = useState("");
  const [billStartDate, setBillStartDate] = useState("");
  const [billEndDate, setBillEndDate] = useState("");

  async function getBillingPeriod() {
    // Get start - end date of billing no.
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/billperiod?bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async function getExcelDataVehicle() {
    setIsLoading(true);

    var responsePeroid = await getBillingPeriod();
    if (responsePeroid.data.status === true) {
      setBillStartDate(responsePeroid.data.dataret.start_date);
      setBillEndDate(responsePeroid.data.dataret.end_date);
    }
    // console.log(`Test-responsePeroid`, responsePeroid);

    //>> Get monthly bill list
    var config = {
      method: "get",
      // url: `${process.env.REACT_APP_API_URL}api/v1/export/monthlybill/vehiclefee?province=${province}&amphur=${amphur}&name=${searchName}&start=${dateStart}&end=${dateEnd}&emptype=${searchEmpType}&bill_no=${billNo}`,
      url: `${process.env.REACT_APP_API_URL}api/v1/export/monthlybill/vehiclefee?bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        if (response.data.length !== 0) {
          setExcelDataVehicle(response.data);
          setIsLoading(false);
        } else {
          setTxtWarning('กรุณากด "ตั้งเบิก" ก่อน Export');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // handleCloseExport();
  }

  async function getExcelDataBank() {
    setIsLoading(true);

    // CANCEL send ref2
    // // Set period title for dda_ref2
    // var title = "";
    // var yfrom, yto, mfrom, mto, dfrom, dto, period;
    // var txt = "งานวันที่ ";
    // // CANCEL (Refer date from search box) ------------------------
    // // var start = moment(dateStart).locale("th");
    // // var end = moment(dateEnd).locale("th");
    // //-----------------------------------------------------------

    // // Refer date from billing no.
    // // var start = "";
    // // var end = "";
    // var start = moment(null);
    // var end = moment(null);
    // var responsePeroid = await getBillingPeriod();
    // if (responsePeroid.data.status === true) {
    //   start = moment(responsePeroid.data.dataret.start_date).locale("th");
    //   end = moment(responsePeroid.data.dataret.end_date).locale("th");
    // }
    // console.log(`TEST bank billS:`, start);
    // console.log(`TEST bank billE:`, end);

    // // Same year & month
    // if (
    //   start.format("Y") === end.format("Y") &&
    //   start.format("M") === end.format("M")
    // ) {
    //   yfrom = parseInt(start.format("Y")) + 543;
    //   mfrom = start.format("MMMM");
    //   dfrom = start.format("D"); // day from
    //   dto = end.format("D"); // day to
    //   title = `${txt}${dfrom}-${dto} ${mfrom} ${yfrom}`; // 1-14 มีนาคม 2566
    // }
    // // Same year
    // else if (
    //   start.format("Y") === end.format("Y") &&
    //   start.format("M") !== end.format("M")
    // ) {
    //   yfrom = parseInt(start.format("Y")) + 543;
    //   mfrom = start.format("MMMM");
    //   mto = end.format("MMMM");
    //   dfrom = start.format("D"); // day from
    //   dto = end.format("D"); // day to
    //   title = `${txt}${dfrom} ${mfrom} - ${dto} ${mto} ${yfrom}`; // 12 มีนาคม - 14 เมษายน 2566
    // } else {
    //   yfrom = parseInt(start.format("Y")) + 543;
    //   yto = parseInt(end.format("Y")) + 543;
    //   mfrom = start.format("MMMM");
    //   mto = end.format("MMMM");
    //   dfrom = start.format("D"); // day from
    //   dto = end.format("D"); // day to
    //   title = `${txt}${dfrom} ${mfrom} ${yfrom} - ${dto} ${mto} ${yto}`; // 12 มีนาคม 2565 - 14 เมษายน 2566
    // }

    // const ssvOnly = "NO";

    //>> Get monthly bill list (Export for Bank)
    var config = {
      method: "get",
      // url: `${process.env.REACT_APP_API_URL}api/v1/export/monthlybill/bank?province=${province}&amphur=${amphur}&name=${searchName}&start=${dateStart}&end=${dateEnd}&ref2=${title}&emptype=${searchEmpType}&bill_no=${billNo}`,
      url: `${process.env.REACT_APP_API_URL}api/v1/export/monthlybill/bank?bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        if (response.data.length !== 0) {
          setExcelDataBank(response.data);
          setIsLoading(false);
        } else {
          setTxtWarning('กรุณากด "ตั้งเบิก" ก่อน Export');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // handleCloseExport();
  }

  const handleClickExportConfirm = () => {
    setTxtWarning("");
    setTxtConfirm("");
    // if (dateStart !== null && dateEnd !== null) {
    //   // setTxtConfirm(`รอบบิลวันที่ ${dateStart} ถึง ${dateEnd} ? `);
    //   setTxtConfirm(
    //     `รอบบิลวันที่ ${moment(dateStart).format("DD/MM/YYYY")} ถึง ${moment(
    //       dateEnd
    //     ).format("DD/MM/YYYY")} ? `
    //   );
    // } else if (billNo.length > 0) {
    //   setTxtConfirm(`เลขตั้งเบิก ${billNo} ? `);
    // }
    if (billNo.length === 0) {
      setTxtWarning(`กรุณาใส่เลขตั้งเบิก`);
      setOpenConfirm(true);
    } else {
      setTxtConfirm(`เลขตั้งเบิก ${billNo} ? `);
      setOpenConfirm(true);
      getExcelDataVehicle();
      getExcelDataBank();
    }
  };
  const handleCloseExport = () => {
    setOpenConfirm(false);
  };

  const handleClickExport = () => {
    exportToExcelVehicle(excelDataVehicle, exFileName1, wscolsVehicle);
    exportToExcelBank(excelDataBank, exFileName2, wscolsBank);
    handleCloseExport();
  };

  const wscolsVehicle = [
    {
      wch: 6,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.name.length)),
      wch: 22,
    },
    {
      wch: 9,
    },
    {
      // ค่าบริการ
      wch: 12,
    },
    {
      wch: 12,
    },
    {
      wch: 10,
    },
    {
      wch: 10,
    },
    {
      wch: 10,
    },
    {
      wch: 26,
    },
    {
      wch: 12,
    },
    {
      wch: 15,
    },
  ];

  const wscolsBank = [
    {
      //   wch: Math.max(...excelData.map((exdata) => exdata.bank_code.length)),
      wch: 15,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.b_account_no.length)),
      wch: 18,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.name.length)),
      wch: 22,
    },
    {
      wch: 15,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.IDcard.length)),
      wch: 15,
    },
    {
      wch: Math.max(...excelDataBank.map((exdata) => exdata.dda_ref.length)),
    },
    // {
    //   wch: Math.max(...excelDataBank.map((exdata) => exdata.dda_ref2.length)),
    // },
    { wch: 27 },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.email.length)),
      wch: 20,
    },
    {
      // wch: Math.max(...excelData.map((exdata) => exdata.phone.length)),
      wch: 12,
    },
  ];

  // ******** Export for Vehicle *************

  const exportToExcelVehicle = (excelData, fileName, wscols) => {
    var title = "";
    var yfrom, yto, mfrom, mto, dfrom, dto, period;
    var txt =
      "เพื่อโปรดพิจารณาจ่ายค่าพาหนะ,ประจำวัน,ค่ารูปและค่าเรียกร้อง งานตั้งแต่วันที่ ";
    // Set period title
    // CANCEL (Refer date from search box) ------------------------
    // var start = "";
    // var end = "";
    // console.log(`TEST dateS:`, dateStart);
    // console.log(`TEST dateE:`, dateEnd);
    // console.log(`TEST billS:`, billStartDate);
    // console.log(`TEST billE:`, billEndDate);
    // if (dateStart === null || dateEnd === null) {
    //   start = moment(billStartDate).locale("th");
    //   end = moment(billEndDate).locale("th");
    // } else {
    //   start = moment(dateStart).locale("th");
    //   end = moment(dateEnd).locale("th");
    // }
    //-----------------------------------------------------------

    if (billStartDate !== "" && billEndDate != "") {
      // Refer date from billing no.
      var start = moment(billStartDate).locale("th");
      var end = moment(billEndDate).locale("th");

      // Same year & month
      if (
        start.format("Y") === end.format("Y") &&
        start.format("M") === end.format("M")
      ) {
        yfrom = parseInt(start.format("Y")) + 543;
        mfrom = start.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom}-${dto} ${mfrom} ${yfrom}`; // 1-14 มีนาคม 2566
      }
      // Same year
      else if (
        start.format("Y") === end.format("Y") &&
        start.format("M") !== end.format("M")
      ) {
        yfrom = parseInt(start.format("Y")) + 543;
        mfrom = start.format("MMMM");
        mto = end.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom} ${mfrom} - ${dto} ${mto} ${yfrom}`; // 12 มีนาคม - 14 เมษายน 2566
      } else {
        yfrom = parseInt(start.format("Y")) + 543;
        yto = parseInt(end.format("Y")) + 543;
        mfrom = start.format("MMMM");
        mto = end.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom} ${mfrom} ${yfrom} - ${dto} ${mto} ${yto}`; // 12 มีนาคม 2565 - 14 เมษายน 2566
      }
    }

    // Set print date & billing No. to J2 cell
    //--> Ex. 20230900018  วันที่ 12 มีนาคม 2565
    var billInfo = "";
    var printDate = moment().locale("th");
    const yprint = parseInt(printDate.format("Y")) + 543;
    const mprint = printDate.format("MMMM");
    const dprint = printDate.format("D");
    billInfo = `${billNo}   /  วันที่ ${dprint} ${mprint} ${yprint}`;

    var ws = XLSX.utils.aoa_to_sheet([
      ["แบบขออนุมัติจ่ายค่าพาหนะ Digital Survey"],
      ["เรียน กรรมการผู้จัดการ", , , , , , , , , , billInfo],
      // [
      //   "เพื่อโปรดพิจารณาจ่ายค่าพาหนะ,ประจำวัน,ค่ารูปและค่าเรียกร้อง งานตั้งแต่วันที่ 14-28  กุมภาพันธ์   2566",
      // ],
      [title],
      ["รายละเอียดตามแนบ"],
      [
        "ลำดับ",
        "ชื่อพนักงาน",
        "จำนวนงาน",
        "ค่าบริการ",
        "ค่าพาหนะจ่าย",
        "เรียกร้องจ่าย",
        "ชดเชย",
        "ค่าใช้จ่ายอื่นๆ",
        "ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี",
        "หักภาษี 3%",
        "ยอดจ่าย",
      ],
      // ["", "", "", "", "", "", "", "", ""],
      // ["", "", "", "", "", "", "", "", ""],
      // ["", "", "", "", "", "", "", "", ""],
      // ["", "", "", "", "", "", "", "", ""],
      // ["", "", "", "", "", "", "", "", ""],
    ]);
    ws["!cols"] = wscols;
    /*ws["A1"].l = {
      Target: "https://sheetjs.com",
      Tooltip: "Find us @ SheetJS.com!"
    };*/

    //>> Merge cells
    //>> s = start, r = row, c=col, e= end
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
    ];
    ws["!merges"] = merge;

    //>> Set Cell style
    // const range = XLSX.utils.decode_range(ws["!ref"] || "A1");
    // var range = XLSX.utils.decode_range("A4:I4");
    // ws[range].F = {
    //   font: {
    //     name: "Angsana New",
    //     sz: 14,
    //   },
    // };
    ws["A1"].s = {
      font: {
        name: "Angsana New",
        sz: 16,
        bold: true,
        // color: "#F2F2F2",
      },
      alignment: {
        // vertical: "center",
        horizontal: "center",
        // wrapText: "1", // any truthy value here
      },
    };
    ws["A2"].s = {
      font: {
        name: "Angsana New",
        sz: 16,
      },
    };
    ws["A3"].s = {
      font: {
        name: "Angsana New",
        sz: 16,
      },
    };
    ws["A4"].s = {
      font: {
        name: "Angsana New",
        sz: 16,
      },
    };

    // Set J2 style (print date)
    ws[
      XLSX.utils.encode_cell({
        c: 10,
        r: 1,
      })
    ].s = {
      font: {
        name: "Angsana New",
        sz: 14,
      },
      alignment: {
        vertical: "center",
        horizontal: "right",
      },
    };

    // Set header style
    for (let i = 0; i < 11; i++) {
      ws[
        XLSX.utils.encode_cell({
          c: i,
          r: 4,
        })
      ].s = {
        font: {
          name: "Angsana New",
          sz: 14,
          bold: true,
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D3EBF7" },
          bgColor: { rgb: "D3EBF7" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: "black" },
          bottom: { style: "thin", color: "black" },
          left: { style: "thin", color: "black" },
          right: { style: "thin", color: "black" },
        },
      };
    }

    //Convert data string to int
    for (let i = 0; i < excelData.length; i++) {
      excelData[i].claim_payment = parseFloat(excelData[i].claim_payment);
      excelData[i].service_fee = parseFloat(excelData[i].service_fee);
      excelData[i].traveling_expense = parseFloat(
        excelData[i].traveling_expense
      );
      excelData[i].compensate = parseFloat(excelData[i].compensate);
      excelData[i].other = parseFloat(excelData[i].other);
      excelData[i].invest_payment = parseFloat(excelData[i].invest_payment);
      excelData[i].vat = parseFloat(excelData[i].vat);
      excelData[i].total = parseFloat(excelData[i].total);
    }

    //ONLY Data Added here
    XLSX.utils.sheet_add_json(ws, excelData, {
      header: [
        "No",
        "name",
        "job",
        "service_fee",
        "traveling_expense",
        "claim_payment",
        "compensate",
        "other",
        "invest_payment",
        "vat",
        "total",
      ],
      skipHeader: true,
      origin: "A6", //ok -1
      // origin: -1, //ok -1
    });

    //--> Set body style
    var maxRow = 5 + excelData.length;
    for (let i = 0; i < 11; i++) {
      for (let j = 5; j < maxRow; j++) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
              // bold: true,
            },
            // fill: {
            //   patternType: "solid",
            //   fgColor: { rgb: "D3EBF7" },
            //   bgColor: { rgb: "D3EBF7" },
            // },
            // alignment: {
            //   vertical: "center",
            //   horizontal: "center",
            // },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
          };
        }

        if (i === 0 || i === 2) {
          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s = {
              font: {
                name: "Angsana New",
                sz: 14,
              },
              border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thin", color: "black" },
              },
              alignment: {
                horizontal: "center",
              },
            };
          }
        } else if (
          i === 3 ||
          i === 4 ||
          i === 5 ||
          i === 6 ||
          i === 7 ||
          i === 8 ||
          i === 9 ||
          i === 10
        ) {
          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s = {
              font: {
                name: "Angsana New",
                sz: 14,
              },
              border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thin", color: "black" },
              },
              // alignment: {
              //   horizontal: "center",
              // },
            };
          }

          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s.numFmt = "#,##0.00";
          }
        }
      }
    }

    // Summary last row
    var job = excelData
      .map((excelData) => excelData.job)
      .reduce((acc, amount) => acc + amount, 0);
    var service_fee = excelData
      .map((excelData) => excelData.service_fee)
      .reduce((acc, amount) => acc + amount, 0);
    var traveling_expense = excelData
      .map((excelData) => excelData.traveling_expense)
      .reduce((acc, amount) => acc + amount, 0);
    var claim_payment = excelData
      .map((excelData) => excelData.claim_payment)
      .reduce((acc, amount) => acc + amount, 0);
    var compensate = excelData
      .map((excelData) => excelData.compensate)
      .reduce((acc, amount) => acc + amount, 0);
    var other = excelData
      .map((excelData) => excelData.other)
      .reduce((acc, amount) => acc + amount, 0);
    var invest_payment = excelData
      .map((excelData) => excelData.invest_payment)
      .reduce((acc, amount) => acc + amount, 0);
    var vat = excelData
      .map((excelData) => excelData.vat)
      .reduce((acc, amount) => acc + amount, 0);
    var total = excelData
      .map((excelData) => excelData.total)
      .reduce((acc, amount) => acc + amount, 0);

    const summary = [
      "",
      "Grand Total",
      job,
      service_fee,
      traveling_expense,
      claim_payment,
      compensate,
      other,
      invest_payment,
      vat,
      total,
    ];
    XLSX.utils.sheet_add_aoa(ws, [summary], { origin: -1 });

    // Set last row style
    for (let i = 1; i < 11; i++) {
      if (
        ws[
          XLSX.utils.encode_cell({
            c: i,
            r: maxRow,
          })
        ]
      ) {
        ws[
          XLSX.utils.encode_cell({
            c: i,
            r: maxRow,
          })
        ].s = {
          font: {
            name: "Angsana New",
            sz: 14,
            bold: true,
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "D3EBF7" },
            bgColor: { rgb: "D3EBF7" },
          },
          // alignment: {
          //   vertical: "center",
          //   horizontal: "center",
          // },
          border: {
            top: { style: "thin", color: "black" },
            bottom: { style: "thin", color: "black" },
            left: { style: "thin", color: "black" },
            right: { style: "thin", color: "black" },
          },
        };
      }

      if (i === 2) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
              bold: true,
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
            alignment: {
              horizontal: "center",
            },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "D3EBF7" },
              bgColor: { rgb: "D3EBF7" },
            },
          };
        }
      } else if (
        i === 3 ||
        i === 4 ||
        i === 5 ||
        i === 6 ||
        i === 7 ||
        i === 8 ||
        i === 9 ||
        i === 10
      ) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 14,
              bold: true,
              underline: true,
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
            // alignment: {
            //   horizontal: "center",
            // },
            fill: {
              patternType: "solid",
              fgColor: { rgb: "D3EBF7" },
              bgColor: { rgb: "D3EBF7" },
            },
          };
        }

        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: maxRow,
            })
          ].s.numFmt = "#,##0.00";
        }
      }
    }

    //--> Set signer
    var preparer = "ผู้จัดทำ........................................";
    var inspector = "ผู้ตรวจสอบ.....................................";
    var requester = "ผู้ขออนุมัติ.....................................";
    var approver = "ผู้อนุมัติ..........................................";
    var formalname = "     (                                              ) ";

    const signer = [
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", preparer, "", "", "", "", "", "", inspector],
      ["", formalname, "", "", "", "", "", "", formalname],
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", "", "", "", "", "", "", "", ""],
      ["", requester, "", "", "", "", "", "", approver],
      ["", formalname, "", "", "", "", "", "", formalname],
    ];
    XLSX.utils.sheet_add_aoa(ws, signer, { origin: -1 });

    // Set signer style
    var signrow1 = maxRow + 4;
    var signrow2 = maxRow + 5;
    var signrow3 = maxRow + 11;
    var signrow4 = maxRow + 12;
    var signrow = [signrow1, signrow2, signrow3, signrow4];
    for (let i = 0; i < 10; i++) {
      for (let j = 0; j < 5; j++) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: signrow[j],
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: signrow[j],
            })
          ].s = {
            font: {
              name: "Angsana New",
              sz: 16,
            },
          };
        }
      }
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    // handleCloseExport();
    setExcelDataVehicle([]);
  };

  // ******** Export for Bank *************
  const exportToExcelBank = (excelData, fileName, wscols) => {
    //==> Set period title for dda_ref2
    var title = "";
    var yfrom, yto, mfrom, mto, dfrom, dto, period;
    var txt = "งานวันที่ ";
    if (billStartDate !== "" && billEndDate != "") {
      // Refer date from billing no.
      var start = moment(billStartDate).locale("th");
      var end = moment(billEndDate).locale("th");
      // console.log(`TEST bank billS:`, start);
      // console.log(`TEST bank billE:`, end);

      // Same year & month
      if (
        start.format("Y") === end.format("Y") &&
        start.format("M") === end.format("M")
      ) {
        yfrom = parseInt(start.format("Y")) + 543;
        mfrom = start.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom}-${dto} ${mfrom} ${yfrom}`; // 1-14 มีนาคม 2566
      }
      // Same year
      else if (
        start.format("Y") === end.format("Y") &&
        start.format("M") !== end.format("M")
      ) {
        yfrom = parseInt(start.format("Y")) + 543;
        mfrom = start.format("MMMM");
        mto = end.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom} ${mfrom} - ${dto} ${mto} ${yfrom}`; // 12 มีนาคม - 14 เมษายน 2566
      } else {
        yfrom = parseInt(start.format("Y")) + 543;
        yto = parseInt(end.format("Y")) + 543;
        mfrom = start.format("MMMM");
        mto = end.format("MMMM");
        dfrom = start.format("D"); // day from
        dto = end.format("D"); // day to
        title = `${txt}${dfrom} ${mfrom} ${yfrom} - ${dto} ${mto} ${yto}`; // 12 มีนาคม 2565 - 14 เมษายน 2566
      }
    }

    //--> set first row data with 1-9
    var firstRow = [];
    var j = 1;
    for (let i = 0; i < 9; i++) {
      firstRow[i] = j;
      j++;
    }

    var ws = XLSX.utils.aoa_to_sheet([
      firstRow,
      [
        "Receiving Bank Code",
        "Receiving A/C No.",
        "Receiver Name",
        " Transfer Amount",
        "Citizen ID/Tax ID",
        "DDA Ref",
        "Reference No./ DDA Ref 2",
        "Email",
        "Mobile No.",
      ],
    ]);
    ws["!cols"] = wscols;

    //>> Set Cell style
    //--> first row style
    for (let i = 0; i < 9; i++) {
      ws[
        XLSX.utils.encode_cell({
          c: i,
          r: 0,
        })
      ].s = {
        font: {
          name: "Arial",
          sz: 10,
          // bold: true,
          // color: "#F2F2F2",
        },
        alignment: {
          // vertical: "center",
          horizontal: "center",
          // wrapText: "1", // any truthy value here
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D3EBF7" },
          bgColor: { rgb: "D3EBF7" },
        },
        border: {
          top: { style: "thin", color: "black" },
          bottom: { style: "thin", color: "black" },
          left: { style: "thin", color: "black" },
          right: { style: "thin", color: "black" },
        },
      };
    }
    //--> Set header style
    for (let i = 0; i < 9; i++) {
      ws[
        XLSX.utils.encode_cell({
          c: i,
          r: 1,
        })
      ].s = {
        font: {
          name: "Angsana New",
          sz: 14,
          //   bold: true,
        },
        fill: {
          patternType: "solid",
          fgColor: { rgb: "FFFF99" },
          bgColor: { rgb: "FFFF99" },
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: {
          top: { style: "thin", color: "black" },
          bottom: { style: "thin", color: "black" },
          left: { style: "thin", color: "black" },
          right: { style: "thin", color: "black" },
        },
      };

      if (i === 0 || i === 1 || i === 2 || i === 3) {
        ws[
          XLSX.utils.encode_cell({
            c: i,
            r: 1,
          })
        ].s = {
          font: {
            name: "Angsana New",
            sz: 14,
            //   bold: true,
            color: { rgb: "FF0000" },
          },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "FFFF99" },
            bgColor: { rgb: "FFFF99" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
          },
          border: {
            top: { style: "thin", color: "black" },
            bottom: { style: "thin", color: "black" },
            left: { style: "thin", color: "black" },
            right: { style: "thin", color: "black" },
          },
        };
      }
    }

    //Convert data string to int
    for (let i = 0; i < excelData.length; i++) {
      excelData[i].total = parseFloat(excelData[i].total);
      excelData[i].dda_ref2 = title;
    }

    //ONLY Data Added here
    XLSX.utils.sheet_add_json(ws, excelData, {
      header: [
        "bank_code",
        "b_account_no",
        "name",
        "total",
        "IDcard",
        "dda_ref",
        "dda_ref2",
        "email",
        "phone",
      ],
      skipHeader: true,
      origin: "A3", //ok -1
    });

    //--> Set body style
    var maxRow = 2 + excelData.length;
    for (let i = 0; i < 9; i++) {
      for (let j = 2; j < maxRow; j++) {
        if (
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ]
        ) {
          ws[
            XLSX.utils.encode_cell({
              c: i,
              r: j,
            })
          ].s = {
            font: {
              name: "Arial",
              sz: 10,
            },
            border: {
              top: { style: "thin", color: "black" },
              bottom: { style: "thin", color: "black" },
              left: { style: "thin", color: "black" },
              right: { style: "thin", color: "black" },
            },
          };
        }

        if (i === 3) {
          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s = {
              font: {
                name: "Arial",
                sz: 10,
              },
              border: {
                top: { style: "thin", color: "black" },
                bottom: { style: "thin", color: "black" },
                left: { style: "thin", color: "black" },
                right: { style: "thin", color: "black" },
              },
              //   alignment: {
              //     horizontal: "center",
              //   },
            };
          }

          if (
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ]
          ) {
            ws[
              XLSX.utils.encode_cell({
                c: i,
                r: j,
              })
            ].s.numFmt = "#,##0.00";
          }
        }
      }
    }

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    // handleCloseExport();
    setExcelDataBank([]);
  };

  return (
    <>
      <Button
        variant="text"
        type="submit"
        startIcon={<UploadIcon />}
        size="large"
        sx={{ mt: 1, ml: 1 }}
        onClick={() => handleClickExportConfirm()}
        // onClick={(e) => exportToExcel(ExcelExportData, fileName, wscols)}
        // onClick={(e) => exportToCSV(csvData, fileName, wscols)}
        // onClick={(e) => exportToCSV(csvData, fileName)}
      >
        Export ตั้งเบิกค่าพาพนะ และ ไฟล์ส่งแบงค์
      </Button>

      <Dialog
        open={openConfirm}
        onClose={handleCloseExport}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Export"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ต้องการ export แบบขออนุมัติจ่ายค่าพาหนะ Digital Survey
            <br />
            {txtConfirm}
            <br />
            <Typography component="span" color="error">
              {txtWarning}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExport}>Cancle</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            // onClick={(e) => exportToExcelVehicle(excelData, fileName, wscols)}
            onClick={() => handleClickExport()}
            // disabled={billNo.length === 11 ? false : true}
            disabled={
              billNo.length === 11 &&
              excelDataBank.length !== 0 &&
              excelDataVehicle.length !== 0
                ? false
                : true
            }
          >
            EXPORT
          </LoadingButton>

          {/* <Button
            onClick={(e) => exportToExcel(excelData, fileName, wscols)}
            autoFocus
          >
            EXPORT
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportExcel2Files;
