import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import LoadingButton from "@mui/lab/LoadingButton";
import EastIcon from "@mui/icons-material/East";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Img = styled("img")({
  maxWidth: "100%",
  maxHeight: 120,
});

export default function UserForm({ onSave }) {
  const [errorCitizen, setErrorCitizen] = React.useState("");
  const [errorPhone, setErrorPhone] = React.useState("");
  const [errorUsername, setErrorUsername] = React.useState("");
  const [pdpa, setPdpa] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();

  async function onSubmit(data) {
    // console.log(data);
    setIsLoading(true);
    setErrorCitizen("");
    setErrorPhone("");
    setErrorUsername("");
    var responseCitizenReal = await checkCitizenReal(data.IDcard);
    if (responseCitizenReal === true) {
      var responseCitizen = await checkCitizen(data.IDcard);
      if (responseCitizen.data.status === false) {
        setErrorCitizen(responseCitizen.data.message);
        setIsLoading(false);
      } else {
        var responsePhone = await checkPhone(data.phone);
        if (responsePhone.data.status === false) {
          setErrorPhone(responsePhone.data.message);
          setIsLoading(false);
        } else {
          // var responseUsername = await checkUsername(data.username);
          // if (responseUsername.data.status === false) {
          //     setErrorUsername(responseUsername.data.message)
          //     setIsLoading(false);
          // } else {
          onSave(data);
          setIsLoading(false);
          // }
        }
      }
    } else {
      setErrorCitizen("รหัสบัตรประชาชนไม่ถูกต้อง");
      setIsLoading(false);
    }
  }

  async function checkCitizenReal(id) {
    if (id.length != 13) return false;
    var i = 0;
    var sum = 0;
    for (i = 0, sum = 0; i < 12; i++)
      sum += parseFloat(id.charAt(i)) * (13 - i);
    if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
    return true;
  }

  async function checkCitizen(id) {
    var dataForm = JSON.stringify({
      type: "idcard",
      keyword: id,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/checkduplicate`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async function checkPhone(phone) {
    var dataForm = JSON.stringify({
      type: "phone",
      keyword: phone,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/checkduplicate`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async function checkUsername(username) {
    var dataForm = JSON.stringify({
      type: "username",
      keyword: username,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/checkduplicate`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    return await axios(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <div>ความยินยอมเรื่องข้อมูลส่วนบุคคล</div>
            <Typography variant="subtitle2">
              รายละเอียดเกี่ยวกับคำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy
              Notice) บริษัท ดี พี เซอร์เวย์ แอนด์ลอว์ จำกัด
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item md={12} sx={{ textAlign: "center" }}>
                  <Img
                    alt="complex"
                    src="/img/policy.png"
                    sx={{ mt: 2, mb: 2, maxHeight: 160 }}
                  />
                  <div className="center">
                    <Typography variant="subtitle2">
                      ท่านรับทราบและยินยอมให้ข้อมูลกับบริษัท ดี พี เซอร์เวย์
                      แอนด์ลอว์ จำกัด หรือไม่
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setPdpa(false);
                setOpen(false);
              }}
              sx={{ mt: 3, ml: 1 }}
              color="secondary"
            >
              ไม่ยินยอม
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setPdpa(true);
                setOpen(false);
              }}
              endIcon={<EastIcon />}
              sx={{ mt: 3, ml: 1 }}
            >
              ยินยอม
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              {...register("IDcard", {
                required: true,
                minLength: 13,
                maxLength: 13,
              })}
              aria-invalid={errors.IDcard ? "true" : "false"}
              fullWidth
              id="IDcard"
              label="เลขที่บัตรประจำตัวประชาชน 13 หลัก"
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 13);
              }}
              InputLabelProps={{ shrink: true }}
              required
            />
            {errors.IDcard?.type === "required" && (
              <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
            )}
            {errorCitizen !== "" && (
              <div role="alert" className="cr tmd">
                {errorCitizen}
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              {...register("phone", {
                required: true,
                minLength: 10,
                maxLength: 10,
              })}
              aria-invalid={errors.phone ? "true" : "false"}
              fullWidth
              id="phone"
              label="เบอร์โทรศัพท์มือถือ"
              onInput={(e) => {
                e.target.value = e.target.value.slice(0, 10);
              }}
              InputLabelProps={{ shrink: true }}
              required
            />
            {errors.phone?.type === "required" && (
              <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
            )}
            {errorPhone !== "" && (
              <div role="alert" className="cr tmd">
                {errorPhone}
              </div>
            )}
          </Grid>
          {/* <Grid item xs={12} sm={12}>
                        <TextField
                            {...register("username", { required: true })}
                            aria-invalid={errors.username ? "true" : "false"}
                            fullWidth
                            id="username"
                            label="ตั้งรหัสผู้ใช้งาน"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.username?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                        {errorUsername !== "" && (
                            <div role="alert" className="cr tmd">{errorUsername}</div>
                        )}
                    </Grid> */}
          {/* <Grid item xs={12} sm={12}>
                        <TextField
                            {...register("password", { required: true })}
                            aria-invalid={errors.password ? "true" : "false"}
                            fullWidth
                            id="password"
                            type="password"
                            label="ตั้งรหัสผ่าน"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.password?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                    </Grid> */}
          {/* <Grid item xs={12} sm={12}>
                        <TextField
                            {...register("passwordConfirm", { required: true,
                                validate: (value) => {
                                    const { password } = getValues();
                                    return password === value || "Passwords should match!";
                                  } })}
                            aria-invalid={errors.passwordConfirm ? "true" : "false"}
                            fullWidth
                            id="passwordConfirm"
                            type="password"
                            label="ยืนยันรหัสผ่าน"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.passwordConfirm?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                    </Grid> */}
          {/* <Grid item xs={12} sm={12}>
                        <TextField
                            {...register("pin", { required: true, minLength: 6, maxLength: 6 })}
                            aria-invalid={errors.pin ? "true" : "false"}
                            fullWidth
                            id="pin"
                            type="number"
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                            }}
                            label="ตั้งรหัส PIN code ด้วยตัวเลข 6 หลัก"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.pin?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                    </Grid> */}
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("pdpa")}
                    checked={pdpa}
                    id="pdpa"
                    onChange={() => setOpen(true)}
                  />
                }
                label="ยอมรับ Privacy Policy หรือ นโยบายคุ้มครองข้อมูลส่วนบุคคล"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingButton
            type="submit"
            loading={isLoading}
            loadingPosition="end"
            endIcon={<EastIcon />}
            variant="contained"
            disabled={!pdpa}
            sx={{ mt: 3, ml: 1 }}
          >
            สมัคร
          </LoadingButton>
        </Box>
      </form>
    </React.Fragment>
  );
}
