import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase, FormControl, Grid, Paper, styled, Typography, TextField, MenuItem, InputAdornment, IconButton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';

import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const currencies = [
    {
        value: 'Test',
        label: 'Test',
    }
];

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createDataSub(name, calories, fat, carbs, protein, price) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein,
      price,
      history: [
        {
          date: '2020-01-05',
          customerId: '11091700',
          amount: 3,
        },
        {
          date: '2020-01-02',
          customerId: 'Anonymous',
          amount: 1,
        },
      ],
    };
}
  
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.calories}</TableCell>
          <TableCell>{row.fat}</TableCell>
          <TableCell>{row.carbs}</TableCell>
          <TableCell>{row.protein}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="Car type" variant="outlined" />
                    </Grid>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="เลขทะเบียนรถ" variant="outlined" />
                    </Grid>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="จังหวัด" variant="outlined" />
                    </Grid>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="เลขที่ใบขับขี่" variant="outlined" />
                    </Grid>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="วันหมดอายุ" variant="outlined" />
                    </Grid>
                    <Grid item md={4}>
                        <TextField fullWidth id="" label="Status" variant="outlined" />
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3, mb: 3 }}>
                    <Button variant="contained" autoFocus onClick={() => setOpen(!open)}>
                        Update
                    </Button>
                    <Button onClick={() => setOpen(!open)}>
                        Cancel
                    </Button>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired,
    }).isRequired,
  };
  
  const rowsSub = [
    createDataSub(1, 'นายทดสอบ ทำงาน', 'กข 9999', '123456789', 'Active'),
    createDataSub(2, 'นายทดสอบ ทำงาน', 'กข 9999', '123456789', 'Active'),
    createDataSub(3, 'นายทดสอบ ทำงาน', 'กข 9999', '123456789', 'Active'),
    createDataSub(4, 'นายทดสอบ ทำงาน', 'กข 9999', '123456789', 'Active'),
    createDataSub(5, 'นายทดสอบ ทำงาน', 'กข 9999', '123456789', 'Active')
  ];


function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, SubPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / SubPerPage) - 1));
    };


    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / SubPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / SubPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, location, job_type, total_job, status) {
    return { name, location, job_type, total_job, status };
}

const rows = [
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
    createData('Cupcake', 'ชลบุรี', 'Car Inspector', 3, 'Wait to confirm'),
];

export default function Outsource() {
    const [currency, setCurrency] = React.useState('');

    const handleChangeDropDown = (event) => {
        setCurrency(event.target.value);
    };

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openConfirm, setOpenConfirm] = React.useState(false);

    const handleClickOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">
                        {"Edit outsource employee"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="1. Company details" {...a11yProps(0)} />
                                    <Tab label="2. Driver details" {...a11yProps(1)} />
                                    <Tab label="3. Documents" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="company_name" label="Company name" variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="phone_no" label="Phone No." variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Address 1" variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Email" variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Address 2" variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Fax No." variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Tax Number" variant="outlined" />
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField fullWidth id="" label="Insurance name" variant="outlined" />
                                    </Grid>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            mt: 2,
                                            flexGrow: 1,
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark' ? '#1A2027' : '#EAEAEA',
                                        }}
                                    >
                                        <Grid container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center" spacing={2}>
                                            <Grid item >
                                                <Typography className="bold">Still be our outsource</Typography>
                                                <Typography variant='small'>Slide button to inactive Outsource</Typography>
                                            </Grid>
                                            <Grid item >
                                                <Switch  defaultChecked />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Item</TableCell>
                                            <TableCell>Driver name</TableCell>
                                            <TableCell>เลขทะเบียนรถ</TableCell>
                                            <TableCell>เลขที่ใบขับขี่</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rowsSub.map((row) => (
                                            <Row key={row.name} row={row} />
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        mb: 2,
                                        maxWidth: 500,
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#EAEAEA',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <div className="txl semiBold">โลโก้บริษัท</div>
                                        </Grid>
                                        <Grid item xs={12} sm 
                                            container
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center">
                                            <Grid item md={6}>
                                                <ButtonBase sx={{ height: 90 }}>
                                                    <Img alt="complex" src="/img/user.png" />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button variant="contained" component="label" startIcon={<FileUploadIcon />}>
                                                    Select file
                                                    <input hidden accept="image/*" multiple type="file" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper
                                    sx={{
                                        p: 2,
                                        mb: 2,
                                        maxWidth: 500,
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#EAEAEA',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <div className="txl semiBold">ภพ 20</div>
                                        </Grid>
                                        <Grid item xs={12} sm 
                                            container
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center">
                                            <Grid item md={6}>
                                                <ButtonBase sx={{ height: 90 }}>
                                                    <Img alt="complex" src="/img/citizen.png" />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button variant="contained" component="label" startIcon={<FileUploadIcon />}>
                                                    Select file
                                                    <input hidden accept="image/*" multiple type="file" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper
                                    sx={{
                                        p: 2,
                                        mb: 2,
                                        maxWidth: 500,
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#EAEAEA',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <div className="txl semiBold">เอกสารจดทะเบียนบริษัท</div>
                                        </Grid>
                                        <Grid item xs={12} sm 
                                            container
                                            direction="row"
                                            justifyContent="space-around"
                                            alignItems="center">
                                            <Grid item md={6}>
                                                <ButtonBase sx={{ height: 90 }}>
                                                    <Img alt="complex" src="/img/driver.png" />
                                                </ButtonBase>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Button variant="contained" component="label" startIcon={<FileUploadIcon />}>
                                                    Select file
                                                    <input hidden accept="image/*" multiple type="file" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper
                                    sx={{
                                        p: 2,
                                        flexGrow: 1,
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark' ? '#1A2027' : '#ffefef',
                                    }}
                                >
                                    <Grid container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center" spacing={2}>
                                        <Grid item >
                                            <Typography className="bold">Approve outsource</Typography>
                                            <Typography variant='small'>Slide button to unapprove employee</Typography>
                                        </Grid>
                                        <Grid item >
                                            <Switch  defaultChecked />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </TabPanel>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose} autoFocus>
                            Continue
                        </Button>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to confirm delete?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirm}>Cancle</Button>
                        <Button onClick={handleCloseConfirm} autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                        <TextField
                            sx={{ flex: 1, p: '0px', mb: '20px' }}
                            placeholder="Search Outsource name"
                            InputProps={{
                                'aria-label': 'search google maps',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton type="button" sx={{ p: '0px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth={true}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Type"
                            value={currency}
                            onChange={handleChangeDropDown}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth={true}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Province"
                            value={currency}
                            onChange={handleChangeDropDown}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth={true}>
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="District"
                            value={currency}
                            onChange={handleChangeDropDown}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Outsource name</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Total jobs</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                                </TableCell>
                                <TableCell>
                                    <Typography>{row.name}</Typography>
                                    <Typography>Phone no: 099-000-0000</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>จังหวัด: {row.location}</Typography>
                                    <Typography>อำเภอ: {row.location}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>Type: {row.job_type}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{row.total_job}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{row.status}</Typography>
                                </TableCell>
                                <TableCell>
                                    <IconButton aria-label="Edit" onClick={handleClickOpen}>
                                        <EditIcon color="success" />
                                    </IconButton>
                                    <IconButton aria-label="Delete" onClick={handleClickOpenConfirm}>
                                        <DeleteIcon color="red" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </React.Fragment>
    );
}
