import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "moment/locale/th";
import ExportExcel from "../forms/ExportExcel";

// import { LocalizationProvider } from "@mui/x-date-pickers-pro";
// import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const gradeList = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "E",
    label: "E",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function createData(name, detail, type, status, col5, col6, col7, col8, col9) {
  return { name, detail, type, status, col5, col6, col7, col8, col9 };
}
const dataMockup = [
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00002",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "70",
    "B"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00005",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "60",
    "C"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
  createData(
    "Claim-00001",
    "อุบัติเหตุรถชน",
    "หห234 กทม",
    "นายขับขี่ ปลอดภัย",
    "089-999-2345",
    "01-Jan-2023",
    "นายสำรวจ ดีมาก",
    "80",
    "A"
  ),
].sort((a, b) => (a.detail < b.detail ? -1 : 1));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 12,
  // },
}));

export default function SatisReportForm() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [dataGrade, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceEmp, setProvinceEmp] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchClaimNo, setSearchClaimNo] = useState("");
  const [form_type, setFormType] = useState("");
  const [extID, setExtID] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [formTypeList, setFormTypeList] = React.useState([
    "ให้คะแนน",
    "ช่องทำเครื่องหมาย",
    "ตอบคำถามแบบสั้น",
  ]);
  const [form_status, setFormStatus] = useState("");
  const [formStatusList, setFormStatusList] = useState(["Active", "Inactive"]);
  // const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  // const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [ExcelExportData, setExcelExportData] = useState([]);
  const [firstload, setFirstLoad] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getData(
      0,
      rowsPerPage,
      searchClaimNo,
      searchName,
      dataGrade,
      dateStart,
      dateEnd
    );
  }, []);

  function getData(
    nowPage,
    nowRowsPerPage,
    searchClaimNo,
    searchName,
    dataGrade,
    dateStart,
    dateEnd
  ) {
    setLoading(true);
    setDataRow([]);

    var grade = "";
    if (dataGrade && dataGrade !== "All") {
      grade = "&grade=" + dataGrade;
    }
    var notistart = "";
    if (dateStart && dateStart !== undefined) {
      notistart = "&start=" + dateStart;
    }
    var notiend = "";
    if (dateEnd && dateEnd !== undefined) {
      notiend = "&end=" + dateEnd;
    }

    var nowPage = nowPage + 1;

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/csreport?page=${nowPage}&perpage=${nowRowsPerPage}&claimno=${searchClaimNo}&name=${searchName}${grade}${notistart}${notiend}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
        setExcelExportData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(
      newPage,
      rowsPerPage,
      searchClaimNo,
      searchName,
      dataGrade,
      dateStart,
      dateEnd
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(
      0,
      event.target.value,
      searchClaimNo,
      searchName,
      dataGrade,
      dateStart,
      dateEnd
    );
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeDateStart = (newValue) => {
    if (newValue) {
      setDateStart(newValue.format("YYYY-MM-DD"));
    } else {
      setDateStart(null);
    }
  };
  const handleChangeDateEnd = (newValue) => {
    if (newValue) {
      setDateEnd(newValue.format("YYYY-MM-DD"));
    } else {
      setDateEnd(null);
    }
  };

  return (
    <div>
      <Grid container>
        <ExportExcel
          excelData={ExcelExportData}
          fileName={"CustomerSatisfactionReport"}
        />
      </Grid>
      <br />

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ flex: 1, p: "0px", mb: "20px" }}
        rowSpacing={{ xs: 2, sm: 2, md: 2 }}
        spacing={{ xs: 1, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={2} md>
          <FormControl fullWidth={true}>
            <TextField
              // size="small"
              // sx={{ flex: 1, p: "0px", mb: "20px" }}
              placeholder="เลขที่รับแจ้ง"
              onChange={(e) => {
                setSearchClaimNo(e.target.value);
              }}
              // onKeyPress={(ev) => {
              //   if (ev.key === "Enter") {
              //     getData(
              //       0,
              //       rowsPerPage,
              //       // province,
              //       // amphur,
              //       searchClaimNo,
              //       searchName,
              //       dataGrade
              //     );
              //     ev.preventDefault();
              //   }
              // }}
              InputProps={{
                "aria-label": "search claim number",
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      type="button"
                      sx={{ p: "0px" }}
                      aria-label="searchClaim"
                      onClick={() =>
                        getData(
                          0,
                          rowsPerPage,
                          // province,
                          // amphur,
                          searchClaimNo,
                          searchName,
                          dataGrade,
                          dateStart,
                          dateEnd
                        )
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={2} md>
          <FormControl fullWidth={true}>
            <TextField
              // size="small"
              // sx={{ flex: 1, p: "0px", mb: "20px" }}
              placeholder="ผู้สำรวจ"
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              // onKeyPress={(ev) => {
              //   if (ev.key === "Enter") {
              //     getData(
              //       0,
              //       rowsPerPage,
              //       // province,
              //       // amphur,
              //       searchClaimNo,
              //       searchName,
              //       dataGrade
              //     );
              //     ev.preventDefault();
              //   }
              // }}
              InputProps={{
                "aria-label": "search surveyor name",
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      type="button"
                      sx={{ p: "0px" }}
                      aria-label="searchSurveyor"
                      onClick={() =>
                        getData(
                          0,
                          rowsPerPage,
                          // province,
                          // amphur,
                          searchClaimNo,
                          searchName,
                          dataGrade,
                          dateStart,
                          dateEnd
                        )
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={2} md>
          <FormControl fullWidth={true}>
            <TextField
              // size="small"
              id="outlined-select-dataGrade"
              select
              label="เกรด"
              value={dataGrade}
              onChange={(event) => {
                setDataType(event.target.value);
                // getData(
                //   0,
                //   rowsPerPage,
                //   // province,
                //   // amphur,
                //   searchClaimNo,
                //   searchName,
                //   event.target.value,
                //   dateStart,
                //   dateEnd
                // );
              }}
            >
              {gradeList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={4} sm={2} md>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              disableFuture
              label="วันที่รับแจ้ง"
              // inputFormat="YYYY-MM-DD"
              inputFormat="DD/MM/YYYY"
              value={dateStart}
              onChange={handleChangeDateStart}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          {/* No have license to use date range Pro version */}
          {/* <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "วันที่รับแจ้ง", end: "ถึงวันที่" }}
          >
            <DateRangePicker
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider> */}
        </Grid>

        <Grid item xs={4} sm={2} md>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="ถึงวันที่"
              // inputFormat="YYYY-MM-DD"
              inputFormat="DD/MM/YYYY"
              value={dateEnd}
              onChange={handleChangeDateEnd}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={4} sm={2} md>
          <Button
            variant="contained"
            // size="large"
            sx={{ mt: 1, ml: 1 }}
            style={{ backgroundColor: "#32B917", marginRight: "5px" }}
            // onClick={() => setOpen(true)}
            onClick={() => {
              setPage(0);
              getData(
                0,
                rowsPerPage,
                searchClaimNo,
                searchName,
                dataGrade,
                dateStart,
                dateEnd
              );
            }}
          >
            <Typography fontSize={14}>แสดงรายงาน</Typography>
          </Button>
        </Grid>
        {/* <Grid item xs>
          <ExportExcel
            excelData={ExcelExportData}
            fileName={"CustomerSatisfactionReport"}
          />
        </Grid> */}
      </Grid>
      <div className={loading === true ? "loading" : "hide"}>
        <CircularProgress />
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {/* <TableCell>No.</TableCell> */}
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell>เลขที่รับแจ้ง</StyledTableCell>
              <StyledTableCell>ประเภทเคลม</StyledTableCell>
              <StyledTableCell>ทะเบียนรถ+จังหวัด</StyledTableCell>
              <StyledTableCell>ผู้ขับขี่รถประกัน</StyledTableCell>
              <StyledTableCell>หมายเลขติดต่อ</StyledTableCell>
              <StyledTableCell>วันที่รับแจ้ง</StyledTableCell>
              <StyledTableCell>ชื่อพนักงานสำรวจ</StyledTableCell>
              <StyledTableCell>คะแนนประเมิน</StyledTableCell>
              <StyledTableCell>เกรด</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((row, index) => {
              // var statusTitle = "Offline";
              // var statusStyle = "cg";
              // if (row.is_allowWork === 0) {
              //   statusTitle = "ไม่อนุมัติให้รับงาน";
              //   statusStyle = "cr";
              // } else if (row.is_allowWork === 1) {
              //   statusTitle = "พร้อมรับงาน";
              //   statusStyle = "cgr";
              // }
              return (
                <TableRow key={`${row.caseID}-${row.ins_flag}`}>
                  <TableCell component="th" scope="row">
                    {index + 1 + page * rowsPerPage}
                  </TableCell>
                  <TableCell>
                    <Typography>{row.claim_no}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.claim_Mtype}</Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Typography>{row.plate_info}</Typography> */}
                    <Typography>***</Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Typography>{row.drv_name}</Typography> */}
                    <Typography>***</Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Typography>{row.drv_phone}</Typography> */}
                    <Typography>***</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.notified_date}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.surveyor_info}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.sum_score_ratio}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.eval_grade}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
