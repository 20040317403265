import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Slide from "@mui/material/Slide";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
// import ExportExcelSSBilling from "../forms/ExportExcelSSBilling";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
// import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
// import { visuallyHidden } from "@mui/utils";
// import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { alpha } from "@mui/material/styles";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TaskIcon from "@mui/icons-material/Task";

const dataTypeList = [
  {
    value: "พนักงานสำรวจอุบัติเหตุ",
    label: "พนักงานสำรวจอุบัติเหตุ",
  },
  {
    value: "พนักงานตรวจสภาพรถยนต์",
    label: "พนักงานตรวจสภาพรถยนต์",
  },
];

const billStatList = [
  {
    value: "วางบิล",
    label: "วางบิล",
  },
  {
    value: "ตั้งเบิก",
    label: "ตั้งเบิก",
  },
  {
    value: "ยกเลิก",
    label: "ยกเลิก",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 12,
  // },
}));

function BillingListContent() {
  // const [userId, setUserId] = useState(
  //   sessionStorage.setItem("token", JSON.stringify(token))
  // );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  // const [totalRows, setTotlaRows] = useState({ total: 0 });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  // const [veh_typeList, setVeh_typeList] = useState([]);
  const [is_employee, setIs_employee] = useState(false);
  const [value, setValue] = useState(moment());
  const [ExcelExportData, setExcelExportData] = useState([]);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [searchName, setSearchName] = useState("");
  const [claimNo, setClaimNo] = useState("");
  const [searchEmpType, setSearchEmpType] = useState("");
  const [approveData, setApproveData] = useState({});
  const [billNo, setBillNo] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [txtConfirm, setTxtConfirm] = useState("");
  const [txtWarning, setTxtWarning] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const [billStatus, setBillStatus] = useState("");

  useEffect(() => {
    // getProvince();
    getData(0, rowsPerPage, billNo, billStatus);
  }, []);

  const handleClearClick = () => {
    setProvince("");
    setAmphur("");
    setAmphurList([]);
  };
  const handleClearClickAmphur = () => {
    setAmphur("");
  };

  function getData(nowPage, nowRowsPerPage, billNo, billStatus) {
    setLoading(true);
    setDataRow([]);

    var filterStatus = "";
    if (billStatus !== "") {
      if (billStatus === "วางบิล") {
        filterStatus = "A";
      } else if (billStatus === "ตั้งเบิก") {
        filterStatus = "S";
      } else if (billStatus === "ยกเลิก") {
        filterStatus = "R";
      }
    }

    var nowPage = nowPage + 1;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/billinglist?page=${nowPage}&perpage=${nowRowsPerPage}&bill_no=${billNo}&bill_status=${filterStatus}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeDateStart = (newValue) => {
    if (newValue) {
      setDateStart(newValue.format("YYYY-MM-DD"));
    } else {
      setDateStart(null);
    }
  };

  const handleChangeDateEnd = (newValue) => {
    if (newValue) {
      setDateEnd(newValue.format("YYYY-MM-DD"));
    } else {
      setDateEnd(null);
    }
  };

  const handleClickBillApprove = () => {
    setTxtWarning("");
    // // setTxtConfirm(`รอบบิลวันที่ ${dateStart} ถึง ${dateEnd} ? `);
    // if (dateStart !== null && dateEnd !== null) {
    //   setTxtConfirm(
    //     `รอบบิลวันที่ ${moment(dateStart).format("DD/MM/YYYY")} ถึง ${moment(
    //       dateEnd
    //     ).format("DD/MM/YYYY")} ? `
    //   );
    // } else {
    //   setTxtConfirm(`ตามรายการที่เลือกไว้ ${selected.length} รายการ`);
    // }

    setOpenConfirm(true);
  };
  const handleCloseBillApprove = () => {
    setOpenConfirm(false);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, rowsPerPage, billNo, billStatus);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(0, event.target.value, billNo, billStatus);
  };

  let NumberForm = new Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const columns = [
    // { field: "caseID", headerName: "No.", width: 70 },
    { field: "claim_no", headerName: "เลขเคลม", width: 120 },
    { field: "company", headerName: "บริษัท", width: 120 },
    {
      field: "dispatch_datetime",
      headerName: "วันที่จ่ายงาน",
      // type: "number",
      width: 120,
    },
    {
      field: "claim_Mtype_desc",
      headerName: "ประเภทเคลม",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      // width: 160,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    { field: "acc_zone", headerName: "เขต", width: 120 },
    {
      field: "survey_province_desc",
      headerName: "จังหวัดที่ตรวจสอบ",
      width: 120,
    },
    { field: "survey_amphur_desc", headerName: "อำเภอที่ตรวจสอบ", width: 120 },
    { field: "surveyorName", headerName: "ชื่อพนักงานสำรวจ", width: 120 },
    { field: "traveling_expense", headerName: "ค่าพาหนะจ่าย", width: 120 },
    { field: "claim_payment", headerName: "เรียกร้องจ่าย", width: 120 },
    { field: "other", headerName: "ค่าใช้จ่ายอื่นๆ", width: 120 },
    {
      field: "invest_payment",
      headerName: "ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี",
      width: 120,
    },
    { field: "vat", headerName: "ภาษี 3 %", width: 120 },
    { field: "total", headerName: "ยอดจ่ายหลังหักภาษี", width: 120 },
    { field: "bill_paymentNo", headerName: "เลขตั้งเบิก", width: 120 },
    { field: "bill_approve_flag", headerName: "สถานะการอนุมัติ", width: 120 },
  ];

  return (
    <>
      <Box
      // sx={{
      //   // display: "flex",
      //   // justifyContent: "space-between",
      //   // marginTop: 2,
      //   // width: "100%",
      //   // overflowX: "scroll",
      //   button: {
      //     flex: "none",
      //   },
      // }}
      >
        <Box
          sx={{
            pb: 2,
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            รายการเลขตั้งเบิก
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <div>
              <Grid container>
                {/* <ExportExcelSSBilling
                  fileName={"ตั้งเบิกค่าพาพนะ(สรุปส่งจ่าย)"}
                  province={province}
                  amphur={amphur}
                  searchName={searchName}
                  claimNo={claimNo}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  userData={userData}
                  searchEmpType={searchEmpType}
                  billNo={billNo}
                /> */}
              </Grid>
              <br />

              <Grid
                container
                // spacing={2}
                justifyContent="flex-start"
                alignItems="center"
                sx={{ flex: 1, p: "0px", mb: "20px" }}
                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                spacing={{ xs: 1, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={4}>
                  <FormControl fullWidth={true}>
                    <TextField
                      // sx={{ flex: 1, p: "0px", mb: "20px" }}
                      placeholder="เลขตั้งเบิก"
                      onChange={(e) => {
                        setBillNo(e.target.value);
                      }}
                      InputProps={{
                        "aria-label": "search billing number",
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              type="button"
                              sx={{ p: "0px" }}
                              aria-label="search"
                              onClick={() =>
                                getData(0, rowsPerPage, billNo, billStatus)
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth={true}>
                    <TextField
                      id="outlined-select-billStatus"
                      select
                      label="สถานะบิล"
                      value={billStatus}
                      onChange={(event) => {
                        setBillStatus(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{
                              visibility: billStatus ? "visible" : "hidden",
                            }}
                            onClick={() => {
                              setBillStatus("");
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    >
                      {billStatList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={2}>
                  <Button
                    variant="contained"
                    // size="large"
                    sx={{ mt: 1, ml: 1 }}
                    style={{ backgroundColor: "#32B917", marginRight: "5px" }}
                    onClick={() => {
                      setPage(0);
                      getData(0, rowsPerPage, billNo, billStatus);
                    }}
                  >
                    <Typography fontSize={14}>แสดงรายงาน</Typography>
                  </Button>
                </Grid>
              </Grid>
              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box> */}

              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>No.</StyledTableCell>
                      <StyledTableCell>เลขตั้งเบิก</StyledTableCell>
                      <StyledTableCell>สถานะการอนุมัติ</StyledTableCell>
                      <StyledTableCell>ผู้อนุมัติ</StyledTableCell>
                      <StyledTableCell>ผู้วางบิล</StyledTableCell>
                      <StyledTableCell>ผู้ยกเลิก</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <tr>
                      <td>
                        <div className={loading === true ? "loading" : "hide"}>
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>

                    {dataRow.map((row, i) => {
                      var statusTitle = "รอวางบิล";
                      var statusStyle = "cr";
                      if (row.bill_approve_flag === "A") {
                        // statusTitle = "Approve";
                        statusTitle = "วางบิล";
                        statusStyle = "cs";
                      } else if (row.bill_approve_flag === "S") {
                        statusTitle = "ตั้งเบิก";
                        statusStyle = "cgr";
                      } else if (row.bill_approve_flag === "R") {
                        statusTitle = "ยกเลิก";
                        statusStyle = "corg";
                      }
                      return (
                        <TableRow key={row.bill_paymentNo}>
                          <TableCell component="th" scope="row">
                            {i + 1 + page * rowsPerPage}
                          </TableCell>

                          <TableCell>
                            <Typography>{row.bill_paymentNo}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={statusStyle}>
                              {statusTitle}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.approve_by}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.stamp_by}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{row.revert_by}</Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={data.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default function BillingList() {
  return <BillingListContent />;
}
