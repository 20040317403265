import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "moment/locale/th";
// import ExportExcel from "../forms/ExportExcel";
// import ExportExcelVehicleFee from "../forms/ExportExcelVehicleFee";
// import ExportExcelBank from "../forms/ExportExcelBank";
import ExportExcel2Files from "../forms/ExportExcel2Files";
import Swal from "sweetalert2";

// import { LocalizationProvider } from "@mui/x-date-pickers-pro";
// import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const gradeList = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "E",
    label: "E",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 12,
  // },
}));

const StyledSummaryTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function BillMonthlyForm() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [moneyTotal, setMoneyTotal] = useState(0);
  const [dataService, setDataService] = useState(0);
  const [dataTravel, setDataTravel] = useState(0);
  const [dataMoney, setDataMoney] = useState(0);
  const [dataCompensate, setDataCompensate] = useState(0);
  const [dataOther, setDataOther] = useState(0);
  const [dataInvest, setDataInvest] = useState(0);
  const [dataVat, setDataVat] = useState(0);
  const [dataGrade, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [form_type, setFormType] = useState("");
  const [extID, setExtID] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );

  const [form_status, setFormStatus] = useState("");
  const [formStatusList, setFormStatusList] = useState(["Active", "Inactive"]);
  // const [billPeriod, setBillPeriod] = useState(moment().format("YYYY-MM"));
  // const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  // const [ExcelExportData, setExcelExportData] = useState([]);
  const [firstload, setFirstLoad] = useState(true);
  // const [billMonth, setBillMonth] = useState(moment().format("MM"));
  // const [billYear, setBillYear] = useState(moment().format("YYYY"));
  // const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  // const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [searchEmpType, setSearchEmpType] = useState("");
  const [stampData, setStampData] = useState({});
  const [showRevertData, setShowRevertData] = React.useState(0);
  const [billTitle, setBillTitle] = useState("");
  const [billNo, setBillNo] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openCfRevert, setOpenCfRevert] = useState(false);
  const [txtConfirm, setTxtConfirm] = useState("");
  const [txtWarning, setTxtWarning] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getProvince();
    getData(
      0,
      rowsPerPage,
      province,
      amphur,
      searchName,
      dateStart,
      dateEnd,
      searchEmpType,
      billNo,
      showRevertData
    );
  }, []);

  function getData(
    nowPage,
    nowRowsPerPage,
    province,
    amphur,
    searchName,
    dateStart,
    dateEnd,
    searchEmpType,
    billNo,
    showRevertData
  ) {
    setLoading(true);
    setDataRow([]);
    setMoneyTotal(0);
    setDataMoney(0);
    setDataService(0);
    setDataTravel(0);
    setDataCompensate(0);
    setDataOther(0);
    setDataInvest(0);
    setDataVat(0);

    const showRevert = showRevertData === true ? 1 : 0;

    // Set bill title
    if (!showRevertData) setBillTitle("รายการบิลรอตั้งเบิก");
    else setBillTitle("รายการบิลที่ตั้งเบิกแล้ว (ต้องการยกเลิก)");

    // var where = "";
    // //Todo: add month and year filter
    // if (billPeriod !== "" && billPeriod !== null) {
    //   where = `&month=${billMonth}&year=${billYear}`;
    // }

    // if ((dateStart === null || dateEnd === null) && billNo.length < 1) {
    if (
      (dateStart === null || dateEnd === null) &&
      billNo.length === 0 &&
      searchEmpType.length === 0 &&
      searchName.length === 0 &&
      amphur.length === 0 &&
      province.length === 0
    ) {
      setLoading(false);
      return;
    } else if (dateStart === "Invalid Date" || dateEnd === "Invalid Date") {
      setLoading(false);
      return;
    }

    // // Set stamp data
    // var stampBuf = {
    //   // province: province,
    //   // amphur: amphur,
    //   // name: searchName,
    //   // start: dateStart,
    //   // end: dateEnd,
    //   // emptype: searchEmpType,
    //   bill_no: billNo,
    //   userID: userData.userID,
    // };
    // setStampData(stampBuf);

    var nowPage = nowPage + 1;

    //>> Get monthly bill list
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/monthlybill?page=${nowPage}&perpage=${nowRowsPerPage}&province=${province}&amphur=${amphur}&name=${searchName}&start=${dateStart}&end=${dateEnd}&emptype=${searchEmpType}&revert=${showRevert}&bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
        // Set stamp data
        var stampBuf = {
          bill_no: billNo,
          userID: userData.userID,
        };
        setStampData(stampBuf);
      })
      .catch(function (error) {
        console.log(error);
      });

    //>> Get summary data
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/monthlybillsum?province=${province}&amphur=${amphur}&name=${searchName}&start=${dateStart}&end=${dateEnd}&emptype=${searchEmpType}&revert=${showRevert}&bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setLoading(false);

        setMoneyTotal(response.data.sum_total);
        setDataMoney(response.data.sum_money);
        setDataService(response.data.sum_service);
        setDataTravel(response.data.sum_travel);
        setDataVat(response.data.sum_vat);
        setDataCompensate(response.data.sum_compensate);
        setDataOther(response.data.sum_other);
        setDataInvest(response.data.sum_invest);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(
      newPage,
      rowsPerPage,
      province,
      amphur,
      searchName,
      dateStart,
      dateEnd,
      searchEmpType,
      billNo,
      showRevertData
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(
      0,
      event.target.value,
      province,
      amphur,
      searchName,
      dateStart,
      dateEnd,
      searchEmpType,
      billNo,
      showRevertData
    );
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function updStampData() {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    var dataForm = JSON.stringify(stampData);

    //>> Update stamp bill
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/monthlybill-stamp`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    // console.log(config);
    axios(config)
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data.success === true
        ) {
          setSuccessMessage(response.data.message);
          setIsLoading(false);
          setOpenConfirm(false);
          setData({ total: 0 }); // Reset display button
          Swal.fire({
            position: "center",
            icon: "success",
            // title: response.data.message,
            // text: `ตั้งเบิก สำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            text: response.data.message,
            title: `ตั้งเบิกสำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            // showConfirmButton: false,
            // timer: 5000,
          });
          //Refresh
          getData(
            0,
            rowsPerPage,
            province,
            amphur,
            searchName,
            dateStart,
            dateEnd,
            searchEmpType,
            billNo,
            showRevertData
          );
        } else {
          setErrorMessage(response.data.message);
          setTxtWarning("ไม่พบข้อมูลที่ต้องการตั้งเบิก");
          setIsLoading(false);
          setOpenConfirm(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "ไม่สามารถตั้งเบิกได้",
            text: "ไม่พบข้อมูลที่ต้องการตั้งเบิก.",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        // console.log(`ERR:`, error);
        if (error.response.status === 422) {
          if (error.response.data.errors.bill_no[0]) {
            setTxtWarning("กรุณาใส่เลขตั้งเบิกที่ต้องการตั้งเบิก");
            setIsLoading(false);
          }
        }
      });

    // handleCloseExport();
  }

  function updRevertData() {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    var dataForm = JSON.stringify(stampData);

    //>> Update revert bill
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/monthlybill-revert`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    // console.log(config);
    axios(config)
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data.success === true
        ) {
          setSuccessMessage(response.data.message);
          setIsLoading(false);
          setOpenCfRevert(false);
          setData({ total: 0 }); // Reset display button
          Swal.fire({
            position: "center",
            icon: "success",
            // title: response.data.message,
            // text: `ยกเลิก สำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            text: response.data.message,
            title: `ยกเลิกสำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            // showConfirmButton: false,
            // timer: 5000,
          });
          //Refresh
          getData(
            0,
            rowsPerPage,
            province,
            amphur,
            searchName,
            dateStart,
            dateEnd,
            searchEmpType,
            billNo,
            showRevertData
          );
        } else {
          setErrorMessage(response.data.message);
          setTxtWarning("ไม่พบข้อมูลที่ต้องการยกเลิก");
          setIsLoading(false);
          setOpenCfRevert(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "ไม่สามารถยกเลิกได้",
            text: "รายการที่แสดงรายงานถูกยกเลิกแล้ว.",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 422) {
          if (error.response.data.errors.bill_no[0]) {
            setTxtWarning("กรุณาใส่เลขตั้งเบิกที่ต้องการยกเลิก");
            setIsLoading(false);
          }
        }
      });

    // handleCloseExport();
  }

  // const handleChangeBillPeriod = (newValue) => {
  //   if (newValue) {
  //     setBillPeriod(newValue.format("YYYY-MM"));
  //     setBillMonth(newValue.format("MM"));
  //     setBillYear(newValue.format("YYYY"));
  //   } else {
  //     setBillPeriod(null);
  //     setBillMonth(null);
  //     setBillYear(null);
  //   }
  // };

  const handleChangeDateStart = (newValue) => {
    if (newValue) {
      setDateStart(newValue.format("YYYY-MM-DD"));
    } else {
      setDateStart(null);
    }
  };

  const handleChangeDateEnd = (newValue) => {
    if (newValue) {
      setDateEnd(newValue.format("YYYY-MM-DD"));
    } else {
      setDateEnd(null);
    }
  };

  const handleChangeRevert = (newValue) => {
    setShowRevertData(!showRevertData);
    // Reset display button
    setData({ total: 0 });
  };

  const handleClickBillStamp = () => {
    setTxtWarning("");
    setTxtConfirm("");

    // if (dateStart !== null && dateEnd !== null) {
    //   // setTxtConfirm(`รอบบิลวันที่ ${dateStart} ถึง ${dateEnd} ? `);
    //   setTxtConfirm(
    //     `รอบบิลวันที่ ${moment(dateStart).format("DD/MM/YYYY")} ถึง ${moment(
    //       dateEnd
    //     ).format("DD/MM/YYYY")} ? `
    //   );
    // } else if (billNo.length > 0) {
    //   setTxtConfirm(`เลขตั้งเบิก ${billNo} ? `);
    // }
    if (billNo.length > 0) {
      setTxtConfirm(`เลขตั้งเบิก ${billNo} ? `);
    }

    // if (
    //   data.total > 0 &&
    //   ((dateStart !== null && dateEnd !== null) || billNo.length === 11)
    // )
    if (data.total > 0 && billNo.length === 11) setOpenConfirm(true);
  };
  const handleCloseBillStamp = () => {
    setOpenConfirm(false);
  };

  const handleClickBillRevert = () => {
    setTxtWarning("");
    // setTxtConfirm(`รอบบิลวันที่ ${dateStart} ถึง ${dateEnd} ? `);

    // Cancel get from edit box
    // if (billNo.length > 0) {
    //   setTxtConfirm(`เลขตั้งเบิก ${billNo} ? `);
    // }
    // if (data.total > 0 && billNo.length === 11) setOpenCfRevert(true);

    // Confirm from search result
    if (stampData.bill_no) {
      setTxtConfirm(`เลขตั้งเบิก ${stampData.bill_no} ? `);
    }
    if (stampData && stampData.bill_no === billNo) setOpenCfRevert(true);
  };
  const handleCloseBillRevert = () => {
    setOpenCfRevert(false);
  };

  function getProvince() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/province`)
      .then((response) => {
        setProvinceList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getAmphur(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        // console.log(response.data);
        setAmphurList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClearClick = () => {
    setProvince("");
    setAmphur("");
    setAmphurList([]);
  };
  const handleClearClickAmphur = () => {
    setAmphur("");
  };

  let ThaiBaht = new Intl.NumberFormat("th-TH", {
    style: "currency",
    currency: "THB",
  });

  let NumberForm = new Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <div>
      <Grid container>
        <ExportExcel2Files
          exFileName1={"ตั้งเบิกค่าพาพนะ"}
          exFileName2={"ไฟล์ส่งแบงค์"}
          // province={province}
          // amphur={amphur}
          // searchName={searchName}
          // dateStart={dateStart}
          // dateEnd={dateEnd}
          userData={userData}
          // searchEmpType={searchEmpType}
          billNo={billNo}
        />

        {/* <ExportExcelVehicleFee
          // excelData={ExcelExportData}
          fileName={"ตั้งเบิกค่าพาพนะ"}
          // wscols={wscols}
          province={province}
          amphur={amphur}
          searchName={searchName}
          dateStart={dateStart}
          dateEnd={dateEnd}
          userData={userData}
          searchEmpType={searchEmpType}
        /> */}
        {/* <ExportExcelVehicleFeeSSV
          // excelData={ExcelExportData}
          fileName={"ตั้งเบิกค่าพาพนะ-SSV"}
          // wscols={wscols}
          province={province}
          amphur={amphur}
          searchName={searchName}
          dateStart={dateStart}
          dateEnd={dateEnd}
          userData={userData}
        /> */}

        {/* <ExportExcelBank
          fileName={"ไฟล์ส่งแบงค์"}
          province={province}
          amphur={amphur}
          searchName={searchName}
          dateStart={dateStart}
          dateEnd={dateEnd}
          userData={userData}
          searchEmpType={searchEmpType}
        /> */}
        {/* <ExportExcelBankSSV
          fileName={"ไฟล์ส่งแบงค์-SSV"}
          province={province}
          amphur={amphur}
          searchName={searchName}
          dateStart={dateStart}
          dateEnd={dateEnd}
          userData={userData}
        /> */}
      </Grid>
      <br />
      <Grid container direction="column">
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          // alignItems="flex-start"
          sx={{ flex: 1, p: "0px", mb: "20px" }}
          rowSpacing={{ xs: 2, sm: 2, md: 2 }}
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DesktopDatePicker
              disableFuture
              views={["year", "month"]}
              label="รอบบิล"
              // inputFormat="YYYY-MM"
              value={billPeriod}
              onChange={handleChangeBillPeriod}
              renderInput={(params) => <TextField {...params} />}
            /> */}
              <DesktopDatePicker
                disableFuture
                label="รอบบิลวันที่"
                // inputFormat="YYYY-MM-DD"
                inputFormat="DD/MM/YYYY"
                value={dateStart}
                onChange={handleChangeDateStart}
                renderInput={(params) => <TextField {...params} />}
                componentsProps={{
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} sm={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="ถึงวันที่"
                // inputFormat="YYYY-MM-DD"
                inputFormat="DD/MM/YYYY"
                value={dateEnd}
                onChange={handleChangeDateEnd}
                renderInput={(params) => <TextField {...params} />}
                componentsProps={{
                  actionBar: {
                    actions: ["clear", "today"],
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} sm={4}>
            <FormControl fullWidth={true}>
              <TextField
                // sx={{ flex: 1, p: "0px", mb: "20px" }}
                placeholder="เลขตั้งเบิก"
                onChange={(e) => {
                  setBillNo(e.target.value);
                }}
                InputProps={{
                  "aria-label": "search billing number",
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        type="button"
                        sx={{ p: "0px" }}
                        aria-label="search"
                        onClick={() =>
                          getData(
                            0,
                            rowsPerPage,
                            province,
                            amphur,
                            searchName,
                            dateStart,
                            dateEnd,
                            searchEmpType,
                            billNo,
                            showRevertData
                          )
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ flex: 1, p: "0px", mb: "20px" }}
          rowSpacing={{ xs: 2, sm: 2, md: 2 }}
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={2} md>
            <FormControl fullWidth={true}>
              <TextField
                placeholder="ประเภทพนักงาน"
                onChange={(e) => {
                  setSearchEmpType(e.target.value);
                }}
                InputProps={{
                  "aria-label": "search claim number",
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        type="button"
                        sx={{ p: "0px" }}
                        aria-label="searchClaim"
                        onClick={() =>
                          getData(
                            0,
                            rowsPerPage,
                            province,
                            amphur,
                            searchName,
                            dateStart,
                            dateEnd,
                            searchEmpType,
                            billNo,
                            showRevertData
                          )
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={2} md>
            <FormControl fullWidth={true}>
              <TextField
                placeholder="ชื่อพนักงาน"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                // onKeyPress={(ev) => {
                //   if (ev.key === "Enter") {
                //     getData(
                //       0,
                //       rowsPerPage,
                //       // province,
                //       // amphur,
                //       searchZone,
                //       searchName,
                //       dataGrade
                //     );
                //     ev.preventDefault();
                //   }
                // }}
                InputProps={{
                  "aria-label": "search claim number",
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        type="button"
                        sx={{ p: "0px" }}
                        aria-label="searchClaim"
                        onClick={() =>
                          getData(
                            0,
                            rowsPerPage,
                            province,
                            amphur,
                            searchName,
                            dateStart,
                            dateEnd,
                            searchEmpType,
                            billNo,
                            showRevertData
                          )
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={2} md>
            <FormControl fullWidth={true}>
              <TextField
                id="outlined-select-province"
                select
                label="จังหวัด"
                value={province}
                onChange={(event) => {
                  setProvince(event.target.value);
                  setAmphur("");
                  getAmphur(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: province ? "visible" : "hidden" }}
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              >
                {provinceList.map((item) => (
                  <MenuItem key={`P${item.provinceID}`} value={item.provinceID}>
                    {item.provincename}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={2} md>
            <FormControl fullWidth={true}>
              <TextField
                id="outlined-select-amphur"
                select
                label="อำเภอ"
                value={amphur}
                onChange={(event) => {
                  setAmphur(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: amphur ? "visible" : "hidden" }}
                      onClick={handleClearClickAmphur}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              >
                {amphurList.map((item) => (
                  <MenuItem key={`A${item.amphurID}`} value={item.amphurID}>
                    {item.amphurname}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container>
          <Grid container justifyContent="flex-end" alignItems="center">
            {/* Check box for Display data for Revert */}
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRevertData}
                    id="showRevertData"
                    // onChange={() => setShowRevertData(!showRevertData)}
                    onChange={handleChangeRevert}
                  />
                }
                label="แสดงข้อมูลที่ต้องการยกเลิก (จากเลขตั้งเบิก)"
              />
            </Grid>

            <Grid>
              <Button
                variant="contained"
                // size="large"
                sx={{ mt: 1, ml: 1 }}
                style={{ backgroundColor: "#32B917", marginRight: "5px" }}
                onClick={() => {
                  setPage(0);
                  getData(
                    0,
                    rowsPerPage,
                    province,
                    amphur,
                    searchName,
                    dateStart,
                    dateEnd,
                    searchEmpType,
                    billNo,
                    showRevertData
                  );
                }}
              >
                <Typography fontSize={14}>แสดงรายงาน</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

      <Grid container justifyContent="flex-end" alignItems="flex-start">
        {userData.rolename === "Billing Account" ||
        userData.rolename === "System Admin" ? (
          data.total > 0 &&
          billNo.length === 11 &&
          stampData.bill_no === billNo ? (
            showRevertData ? (
              <Button
                variant="contained"
                sx={{ mt: 1, ml: 1 }}
                style={{ backgroundColor: "#1972f7", marginRight: "5px" }}
                onClick={() => handleClickBillRevert()}
              >
                <Typography fontSize={14}>ยกเลิก</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ mt: 1, ml: 1 }}
                style={{ backgroundColor: "#f78c19", marginRight: "5px" }}
                onClick={() => handleClickBillStamp()}
              >
                <Typography fontSize={14}>ตั้งเบิก</Typography>
              </Button>
            )
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Grid>

      {/* <br /> */}
      <Typography variant="h6" gutterBottom>
        {billTitle}
      </Typography>

      {/* Data Summary Table */}
      <div align="right">หน่วย: บาท</div>
      <TableContainer>
        <Table
          sx={{ maxWidth: 1000 }}
          aria-label="customized table"
          tablerightborder={{ borderWidth: 1 }}
        >
          <TableHead>
            <TableRow>
              <StyledSummaryTableCell align="center">
                ค่าบริการ
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ค่าพาหนะ
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ค่าเรียกร้อง
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ชดเชย
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ค่าใช้จ่ายอื่นๆ
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ภาษีหัก ณ ที่จ่าย&nbsp;3%
              </StyledSummaryTableCell>
              <StyledSummaryTableCell align="center">
                ยอดจ่ายรวมสุทธิ
              </StyledSummaryTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="summary">
              <TableCell align="center">
                <Typography>{NumberForm.format(dataService)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataTravel)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataMoney)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataCompensate)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataOther)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataInvest)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(dataVat)}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{NumberForm.format(moneyTotal)}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <div className={loading === true ? "loading" : "hide"}>
        <CircularProgress />
      </div>
      {/* Data list table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell>ชื่อพนักงาน</StyledTableCell>
              <StyledTableCell>จำนวนงาน</StyledTableCell>
              <StyledTableCell>ค่าบริการ</StyledTableCell>
              <StyledTableCell>ค่าพาหนะ</StyledTableCell>
              <StyledTableCell>ค่าเรียกร้อง</StyledTableCell>
              <StyledTableCell>ชดเชย</StyledTableCell>
              <StyledTableCell>ค่าใช้จ่ายอื่นๆ</StyledTableCell>
              <StyledTableCell>
                ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี
              </StyledTableCell>
              <StyledTableCell>ภาษีหัก ณ ที่จ่าย 3%</StyledTableCell>
              <StyledTableCell>ยอดจ่าย</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((row, i) => {
              return (
                <TableRow key={row.extID}>
                  <TableCell component="th" scope="row">
                    {i + 1 + page * rowsPerPage}
                  </TableCell>
                  <TableCell>
                    <Typography>{row.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{row.job}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {NumberForm.format(row.service_fee)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {NumberForm.format(row.traveling_expense)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {NumberForm.format(row.claim_payment)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{NumberForm.format(row.compensate)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{NumberForm.format(row.other)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      {NumberForm.format(row.invest_payment)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>{NumberForm.format(row.vat)}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {/* <Typography>{ThaiBaht.format(row.total)}</Typography> */}
                    <Typography>{NumberForm.format(row.total)}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />

      <Dialog
        open={openConfirm}
        onClose={handleCloseBillStamp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ต้องการตั้งเบิกค่าพาพนะ
            <br />
            {txtConfirm}
            <br />
            <Typography component="span" color="error">
              {txtWarning}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBillStamp}>Cancle</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={(e) => updStampData()}
          >
            ยืนยันตั้งเบิก
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Confirm dialog for Revert billing */}
      <Dialog
        open={openCfRevert}
        onClose={handleCloseBillRevert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ต้องการยกเลิกตั้งเบิกค่าพาพนะ
            <br />
            {txtConfirm}
            <br />
            <Typography component="span" color="error">
              {txtWarning}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBillRevert}>Cancle</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={(e) => updRevertData()}
          >
            ยกเลิกตั้งเบิก
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
