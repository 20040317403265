import React, { useState, useEffect, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  FormControl,
  styled,
  Snackbar,
  IconButton,
  MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EastIcon from "@mui/icons-material/East";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  height: 160,
});

export default function DocumentForm({ onSave, handleBack, dataStep }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  const [open, setOpen] = React.useState(false);
  const [openCard, setOpenCard] = React.useState(false);
  const [openLicense, setOpenLicense] = React.useState(false);
  const [openBank, setOpenBank] = React.useState(false);
  const [openHouse, setOpenHouse] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imagesCard, setImagesCard] = React.useState([]);
  const [imagesLicense, setImagesLicense] = React.useState([]);
  const [imagesBank, setImagesBank] = React.useState([]);
  const [imagesHouse, setImagesHouse] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [bank_name, setBank_name] = React.useState("ธนาคารกรุงไทย");
  const [b_account_no, setB_account_no] = React.useState("");
  const [b_account_name, setB_account_name] = React.useState("");
  const [bankList, setBankList] = React.useState([
    "ธนาคารกรุงเทพ",
    "ธนาคารกสิกรไทย",
    "ธนาคารกรุงไทย",
    "ธนาคารทหารไทยธนชาต",
    "ธนาคารไทยพาณิชย์",
    "ธนาคารกรุงศรีอยุธยา",
    "ธนาคารเกียรตินาคินภัทร",
    "ธนาคารซีไอเอ็มบีไทย",
    "ธนาคารทิสโก้",
    "ธนาคารยูโอบี",
    "ธนาคารไทยเครดิตเพื่อรายย่อย",
    "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    "ธนาคารไอซีบีซี(ไทย)",
    "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
    "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
    "ธนาคารออมสิน",
    "ธนาคารอาคารสงเคราะห์",
    "ธนาคารอิสลามแห่งประเทศไทย",
  ]);

  function getData() {
    // console.log(dataStep);
    if (dataStep !== "") {
      setUser({
        b_account_no: dataStep.b_account_no,
        b_account_name: dataStep.b_account_name,
      });
      setImages([dataStep.STRAIGHT_FACE]);
      setImagesCard([dataStep.ID_CARD]);
      setImagesLicense([dataStep.DRIVER_LICENSE]);
      setImagesBank([dataStep.BOOK_BANK]);
      setImagesHouse([dataStep.HOUSE_REGIS]);
      setBank_name(dataStep.bank_name);
      setB_account_no(dataStep.b_account_no);
      setB_account_name(dataStep.b_account_name);
    }
  }

  function onImageChange(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImages([e.target.result]);
    };
  }
  function onImageChangeCard(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesCard([e.target.result]);
    };
  }
  function onImageChangeLicense(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesLicense([e.target.result]);
    };
  }
  function onImageChangeBank(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesBank([e.target.result]);
    };
  }
  function onImageChangeHouse(event) {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setImagesHouse([e.target.result]);
    };
  }

  const onSubmit = (data) => {
    setB_account_no(data.b_account_no);
    setB_account_name(data.b_account_name);
    setOpenBank(false);
  };

  function _submit() {
    setIsLoading(true);
    var status = true;
    var data = [];
    if (images.length <= 0) {
      status = false;
    } else if (imagesCard.length <= 0) {
      status = false;
    }
    // else if (imagesBank.length <= 0) {
    //   status = false;
    // } else if (imagesHouse.length <= 0) {
    //   status = false;
    // }
    // console.log("SUBMIT", status);

    if (status === true) {
      data = {
        bank_name: bank_name,
        b_account_no: b_account_no,
        b_account_name: b_account_name,
        STRAIGHT_FACE: images[0],
        ID_CARD: imagesCard[0],
        DRIVER_LICENSE: imagesLicense[0],
        BOOK_BANK: imagesBank[0],
        HOUSE_REGIS: imagesHouse[0],
      };
      onSave(data);
    } else {
      setIsLoading(false);
      setOpenAlert(true);
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          mt: 3,
          display: "grid",
          gridTemplateRows: "repeat(3, 1fr)",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          message="I love snacks"
          autoHideDuration={6000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity="error">
            กรุณาอัพโหลดเอกสารให้ครบถ้วน
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <div>รูปถ่ายหน้าตรง</div>
            <Typography variant="subtitle2" sx={{ color: "#FF0000" }}>
              อัพโหลดรูปถ่ายหน้าตรงของคุณ (บังคับ)
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Button
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onImageChange}
                      capture
                    />
                    {images.length > 0 ? (
                      images.map((item) => <Img src={item} />)
                    ) : (
                      <PhotoCamera sx={{ fontSize: 60 }} />
                    )}
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <Img alt="complex" src="/img/user.png" />
                  </Button>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#16aa00",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    ตัวอย่างที่ดี
                  </Typography>
                </Grid>
              </Grid>

              <div>รูปถ่ายหน้าตรงที่ดี ควรมีลักษณะดังนี้</div>
              <ul>
                <li>หน้าตรงใบหน้าชัดเจน</li>
                <li>ไม่มีบุคคล สัตว์ และหรือ สิ่งของอยู่ในพื้นหลัง</li>
                <li>ไม่สวมเครื่องประดับบังใบหน้า (หมวก, แว่นกันแดด)</li>
                <li>แต่งกายเหมาะสม</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpen(false)}
              endIcon={<EastIcon />}
              sx={{ mt: 3, ml: 1 }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openCard}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={() => setOpenCard(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <div>บัตรประจำตัวประชาชน</div>
            <Typography variant="subtitle2" sx={{ color: "#FF0000" }}>
              อัพโหลดบัตรประจำตัวประชาชน (บังคับ)
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Button
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onImageChangeCard}
                      capture
                    />
                    {imagesCard.length > 0 ? (
                      imagesCard.map((item) => <Img src={item} />)
                    ) : (
                      <PhotoCamera sx={{ fontSize: 60 }} />
                    )}
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <Img alt="complex" src="/img/citizen.png" />
                  </Button>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#16aa00",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    ตัวอย่างที่ดี
                  </Typography>
                </Grid>
              </Grid>

              <div>รูปถ่ายบัตรประชาชนที่ดี ควรมีลักษณะดังนี้</div>
              <ul>
                <li>xxx</li>
                <li>xxx</li>
                <li>xxx</li>
                <li>xxx</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpenCard(false)}
              endIcon={<EastIcon />}
              sx={{ mt: 3, ml: 1 }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openBank}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={() => setOpenBank(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
              <div>สมุดบัญชีธนาคาร</div>
              <Typography variant="subtitle2" sx={{ color: "#FF0000" }}>
                อัพโหลดสมุดบัญชีธนาคาร (บังคับ)
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Button
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        minHeight: 170,
                        maxHeight: 170,
                        marginBottom: 3,
                      }}
                    >
                      <input
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={onImageChangeBank}
                        capture
                      />
                      {imagesBank.length > 0 ? (
                        imagesBank.map((item) => <Img src={item} />)
                      ) : (
                        <PhotoCamera sx={{ fontSize: 60 }} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("b_account_no", { required: true })}
                      required
                      id="b_account_no"
                      label="เลขบัญชีธนาคาร"
                      fullWidth
                      onInput={(e) => {
                        // console.log(e.target.value);
                        e.target.value = e.target.value.slice(0, 10);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        {...register("bank_name")}
                        required
                        id="bank_name"
                        select
                        label="ธนาคาร"
                        value={bank_name}
                        onChange={(event) => setBank_name(event.target.value)}
                      >
                        {bankList.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("b_account_name", { required: true })}
                      required
                      id="b_account_name"
                      label="ชื่อบัญชีธนาคาร"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                endIcon={<EastIcon />}
                sx={{ mt: 3, ml: 1 }}
              >
                บันทึก
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={openHouse}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="sm"
          keepMounted
          onClose={() => setOpenHouse(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <div>ทะเบียนบ้าน</div>
            <Typography variant="subtitle2" sx={{ color: "#FF0000" }}>
              อัพโหลดทะเบียนบ้าน (บังคับ)
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Button
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onImageChangeHouse}
                      capture
                    />
                    {imagesHouse.length > 0 ? (
                      imagesHouse.map((item) => <Img src={item} />)
                    ) : (
                      <PhotoCamera sx={{ fontSize: 60 }} />
                    )}
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 170,
                      maxHeight: 170,
                    }}
                  >
                    <Img alt="complex" src="/img/home.jpg" />
                  </Button>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#16aa00",
                      textAlign: "center",
                      mt: 1,
                    }}
                  >
                    ตัวอย่างที่ดี
                  </Typography>
                </Grid>
              </Grid>

              <div>ทะเบียนบ้านที่ดี ควรมีลักษณะดังนี้</div>
              <ul>
                <li>xxx</li>
                <li>xxx</li>
                <li>xxx</li>
                <li>xxx</li>
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpenHouse(false)}
              endIcon={<EastIcon />}
              sx={{ mt: 3, ml: 1 }}
            >
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          item
          xs={12}
          sm
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          boxShadow={3}
          sx={{
            p: 3,
            mb: 3,
            cursor: "pointer",
            backgroundColor: images.length > 0 ? "#1daf00" : null,
          }}
          onClick={() => setOpen(true)}
        >
          <Box sx={{ display: "flex" }}>
            <UploadFileIcon sx={{ mr: 3 }} />
            <Typography>รูปถ่ายหน้าตรง</Typography>
          </Box>
          {images.length > 0 ? <CheckIcon /> : <CloseIcon />}
        </Grid>
        <Grid
          item
          xs={12}
          sm
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          boxShadow={3}
          sx={{
            p: 3,
            mb: 3,
            cursor: "pointer",
            backgroundColor: imagesCard.length > 0 ? "#1daf00" : null,
          }}
          onClick={() => setOpenCard(true)}
        >
          <Box sx={{ display: "flex" }}>
            <UploadFileIcon sx={{ mr: 3 }} />
            <Typography>บัตรประจำตัวประชาชน</Typography>
          </Box>
          {imagesCard.length > 0 ? <CheckIcon /> : <CloseIcon />}
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          boxShadow={3}
          sx={{
            p: 3,
            mb: 3,
            cursor: "pointer",
            backgroundColor: imagesBank.length > 0 ? "#1daf00" : null,
          }}
          onClick={() => setOpenBank(true)}
        >
          <Box sx={{ display: "flex" }}>
            <UploadFileIcon sx={{ mr: 3 }} />
            <Typography>สมุดบัญชีธนาคาร</Typography>
          </Box>
          {imagesBank.length > 0 ? <CheckIcon /> : <CloseIcon />}
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          sm
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          boxShadow={3}
          sx={{
            p: 3,
            mb: 3,
            cursor: "pointer",
            backgroundColor: imagesHouse.length > 0 ? "#1daf00" : null,
          }}
          onClick={() => setOpenHouse(true)}
        >
          <Box sx={{ display: "flex" }}>
            <UploadFileIcon sx={{ mr: 3 }} />
            <Typography>ทะเบียนบ้าน</Typography>
          </Box>
          {imagesHouse.length > 0 ? <CheckIcon /> : <CloseIcon />}
        </Grid> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
            ย้อนกลับ
          </Button>

          <LoadingButton
            onClick={() => _submit()}
            loading={isLoading}
            loadingPosition="end"
            endIcon={<EastIcon />}
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
          >
            ถัดไป
          </LoadingButton>
        </Box>
      </Box>
    </React.Fragment>
  );
}
