import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Slide from "@mui/material/Slide";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import ExportExcelSSBilling from "../forms/ExportExcelSSBilling";
// import ExportExcelSSBillingSSV from "../forms/ExportExcelSSBillingSSV";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
// import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
// import { visuallyHidden } from "@mui/utils";
// import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { alpha } from "@mui/material/styles";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TaskIcon from "@mui/icons-material/Task";

const dataTypeList = [
  {
    value: "พนักงานสำรวจอุบัติเหตุ",
    label: "พนักงานสำรวจอุบัติเหตุ",
  },
  {
    value: "พนักงานตรวจสภาพรถยนต์",
    label: "พนักงานตรวจสภาพรถยนต์",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 12,
  // },
}));

function SuperBillingContent() {
  // const [userId, setUserId] = useState(
  //   sessionStorage.setItem("token", JSON.stringify(token))
  // );

  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  // const [totalRows, setTotlaRows] = useState({ total: 0 });
  // const [dataType, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  // const [veh_typeList, setVeh_typeList] = useState([]);
  const [is_employee, setIs_employee] = useState(false);
  const [value, setValue] = useState(moment());
  const [ExcelExportData, setExcelExportData] = useState([]);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  const [dateEnd, setDateEnd] = useState(moment().format("YYYY-MM-DD"));
  const [searchName, setSearchName] = useState("");
  const [claimNo, setClaimNo] = useState("");
  const [searchEmpType, setSearchEmpType] = useState("");
  const [approveData, setApproveData] = useState({});
  const [billNo, setBillNo] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [txtConfirm, setTxtConfirm] = useState("");
  const [txtWarning, setTxtWarning] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [rowIndex, setRowIndex] = useState("");
  const [editValue, setEditValue] = useState("");
  const [editValueTransPay, setEditValueTransPay] = useState("");
  const [editValueServiceFee, setEditValueServiceFee] = useState("");
  const [dataID, setDataID] = useState("");

  //Selecting table ---------------------------------------------------
  // const [order, setOrder] = React.useState("asc");
  // const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataRow.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // handleEditRow = index => {
  //   const arr = this.state.allRowsData.map((item, i) => {
  //     if (i === index) {
  //       return {
  //         isEditing: true,
  //         rowData: item.rowData
  //       };
  //     } else return item;
  //   });
  //   this.setState({ allRowsData: arr, editingIndex: index, isEditing: true });
  // };
  const handleEditRow = (event, id) => {
    dataRow.map((item, i) => {
      if (item.id === id) {
        setEditValue(item.other);
        setEditValueTransPay(item.traveling_expense);
        setEditValueServiceFee(item.service_fee);
      }
    });

    setIsEditing(true);
    setRowIndex(id);
    setDataID(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataRow.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  // Sub Selecting Table ----------------------------------------------------
  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      // order,
      orderBy,
      numSelected,
      rowCount,
      // onRequestSort,
    } = props;
    // const createSortHandler = (property) => (event) => {
    //   onRequestSort(event, property);
    // };

    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox"> */}
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all items",
              }}
            />
          </StyledTableCell>
          {/* </TableCell> */}

          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
              {headCell.label}
              {/* {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null} */}
              {/* </TableSortLabel> */}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    // onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    // order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    // orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const headCells = [
    {
      // id: "caseID",
      numeric: false,
      disablePadding: true,
      label: "No.",
    },
    {
      id: "claim_no",
      // numeric: true,
      disablePadding: false,
      label: "เลขเคลม",
    },
    {
      id: "company",
      // numeric: true,
      disablePadding: false,
      label: "บริษัท",
    },
    {
      id: "dispatch_datetime",
      // numeric: true,
      disablePadding: false,
      label: "วันที่จ่ายงาน",
    },
    {
      id: "claim_Mtype_desc",
      disablePadding: false,
      label: "ประเภทเคลม",
    },
    {
      id: "acc_zone",
      disablePadding: false,
      label: "เขต",
    },
    {
      id: "survey_province_desc",
      disablePadding: false,
      label: "จังหวัดที่ตรวจสอบ",
    },
    {
      id: "survey_amphur_desc",
      disablePadding: false,
      label: "อำเภอที่ตรวจสอบ",
    },
    {
      id: "surveyorName",
      disablePadding: false,
      label: "ชื่อพนักงานสำรวจ",
    },
    {
      id: "service_fee",
      numeric: true,
      disablePadding: false,
      label: "ค่าบริการ",
    },
    {
      id: "traveling_expense",
      numeric: true,
      disablePadding: false,
      label: "ค่าพาหนะจ่าย",
    },
    {
      id: "claim_payment",
      numeric: true,
      disablePadding: false,
      label: "เรียกร้องจ่าย",
    },
    {
      id: "other",
      numeric: true,
      disablePadding: false,
      label: "ค่าใช้จ่ายอื่นๆ",
    },
    {
      id: "invest_payment",
      numeric: true,
      disablePadding: false,
      label: "ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี",
    },
    {
      id: "vat",
      numeric: true,
      disablePadding: false,
      label: "ภาษี 3 %",
    },
    {
      id: "total",
      numeric: true,
      disablePadding: false,
      label: "ยอดจ่ายหลังหักภาษี",
    },
    {
      id: "bill_paymentNo",
      disablePadding: false,
      label: "เลขตั้งเบิก",
    },
    {
      id: "bill_approve_flag",
      disablePadding: false,
      label: "สถานะการอนุมัติ",
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action",
    },
  ];

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            เลือกรายการที่ต้องการอนุมัติ
          </Typography>
        )}

        {numSelected > 0 ? (
          userData.rolename === "Billing Approve" ||
          userData.rolename === "System Admin" ? (
            <Button
              variant="contained"
              disableElevation
              sx={{ mt: 1, ml: 1, whiteSpace: "nowrap" }}
              style={{ backgroundColor: "#f78c19", marginRight: "5px" }}
              onClick={() => handleClickBillApprove()}
            >
              <Typography fontSize={14}>วางบิล</Typography>
            </Button>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  // Sub End of Selecting table ---------------------------------------------------

  //End of Selecting table ---------------------------------------------------

  useEffect(() => {
    getProvince();
    getData(
      0,
      rowsPerPage,
      province,
      amphur,
      searchName,
      claimNo,
      dateStart,
      dateEnd,
      searchEmpType,
      billNo
    );
  }, []);

  // useEffect(() => {
  //   // reset form with user data
  //   reset(user);
  // }, [user]);

  function getProvince() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/province`)
      .then((response) => {
        setProvinceList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAmphur(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        // console.log(response.data);
        setAmphurList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClearClick = () => {
    setProvince("");
    setAmphur("");
    setAmphurList([]);
  };
  const handleClearClickAmphur = () => {
    setAmphur("");
  };

  function getData(
    nowPage,
    nowRowsPerPage,
    province,
    amphur,
    searchName,
    claimNo,
    dateStart,
    dateEnd,
    searchEmpType,
    billNo
  ) {
    setLoading(true);
    setDataRow([]);
    setSelected([]);

    if (
      (dateStart === null || dateEnd === null) &&
      billNo.length === 0 &&
      searchEmpType.length === 0 &&
      claimNo.length === 0 &&
      searchName.length === 0 &&
      amphur.length === 0 &&
      province.length === 0
    ) {
      setLoading(false);
      return;
    } else if (dateStart === "Invalid Date" || dateEnd === "Invalid Date") {
      setLoading(false);
      return;
    }

    // Set approve data
    var appvBuf = {
      province: province,
      amphur: amphur,
      name: searchName,
      claim_no: claimNo,
      start: dateStart,
      end: dateEnd,
      emptype: searchEmpType,
      userID: userData.userID,
    };
    setApproveData(appvBuf);

    var nowPage = nowPage + 1;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/billingreport?page=${nowPage}&perpage=${nowRowsPerPage}&province=${province}&amphur=${amphur}&name=${searchName}&claim_no=${claimNo}&start=${dateStart}&end=${dateEnd}&emptype=${searchEmpType}&bill_no=${billNo}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setExcelExportData(response.data.data);
        setLoading(false);
        // setData(response.data);
        // setDataRow(response.data.data);
        //>> Cancel paginate
        setDataRow(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Hold! >> Wait P'Art confirm user on Mon. 21-Aug-23
  // Continue: 23-Aug-23 >> Add new field to cl/oth_accident_info table (Do not create new table!)
  function updApproveData() {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Get from selecting row to approve
    approveData["selected"] = selected;
    // console.log(`dataSelected:`, selected);

    var dataForm = JSON.stringify(approveData);

    // console.log(`dataForm`, dataForm);

    //>> Update approve bill
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/bill-approve`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    // console.log(config);
    axios(config)
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data.success === true
        ) {
          setSuccessMessage(response.data.message);
          setIsLoading(false);
          setOpenConfirm(false);
          Swal.fire({
            position: "center",
            icon: "success",
            // title: response.data.message,
            // text: `วางบิลสำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            text: response.data.message,
            title: `วางบิลสำเร็จทั้งหมด ${response.data.dataret} รายการ`,
            // showConfirmButton: false,
            // timer: 5000,
          });
          setSelected([]);
          getData(
            0,
            rowsPerPage,
            province,
            amphur,
            searchName,
            claimNo,
            dateStart,
            dateEnd,
            searchEmpType,
            billNo
          );
        } else {
          setErrorMessage(response.data.message);
          setTxtWarning("ไม่พบข้อมูลที่ต้องการวางบิล");
          setIsLoading(false);
          setOpenConfirm(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            title: "ไม่สามารถวางบิลได้",
            text: "รายการที่เลือกถูกวางบิลแล้ว.",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // handleCloseExport();
  }

  function updOtherPayData() {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Get from selecting row to approve
    const editData = {
      other: editValue ? editValue : 0,
      trans: editValueTransPay ? editValueTransPay : 0,
      service: editValueServiceFee ? editValueServiceFee : 0,
    };

    var dataForm = JSON.stringify(editData);

    // console.log(`dataForm`, dataForm);

    //>> Update approve bill
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/${dataID}/editbill`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    // console.log(config);
    axios(config)
      .then(function (response) {
        if (
          response &&
          response.status === 200 &&
          response.data.success === true
        ) {
          setSuccessMessage(response.data.message);
          setIsLoading(false);
          setOpenConfirm(false);
          Swal.fire({
            position: "center",
            icon: "success",
            text: response.data.message,
            // title: `อัพเดทสำเร็จ`,
            // showConfirmButton: false,
            // timer: 5000,
          });
          setEditValue("");
          setEditValueTransPay("");
          setEditValueServiceFee("");
          setIsEditing(false);
          getData(
            0,
            rowsPerPage,
            province,
            amphur,
            searchName,
            claimNo,
            dateStart,
            dateEnd,
            searchEmpType,
            billNo
          );
        } else {
          setErrorMessage(response.data.message);
          setTxtWarning("ไม่สามารถแก้ไขข้อมูลได้");
          setIsLoading(false);
          setOpenConfirm(false);
          Swal.fire({
            position: "center",
            icon: "warning",
            // title: "อัพเดทไม่สำเร็จ",
            text: "ไม่สามารถแก้ไขข้อมูลได้",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // handleCloseExport();
  }

  const handleChangeDateStart = (newValue) => {
    if (newValue) {
      setDateStart(newValue.format("YYYY-MM-DD"));
    } else {
      setDateStart(null);
    }
  };

  const handleChangeDateEnd = (newValue) => {
    if (newValue) {
      setDateEnd(newValue.format("YYYY-MM-DD"));
    } else {
      setDateEnd(null);
    }
  };

  const handleClickBillApprove = () => {
    setTxtWarning("");
    // setTxtConfirm(`รอบบิลวันที่ ${dateStart} ถึง ${dateEnd} ? `);
    if (dateStart !== null && dateEnd !== null) {
      setTxtConfirm(
        `รอบบิลวันที่ ${moment(dateStart).format("DD/MM/YYYY")} ถึง ${moment(
          dateEnd
        ).format("DD/MM/YYYY")} ? `
      );
    } else {
      setTxtConfirm(`ตามรายการที่เลือกไว้ ${selected.length} รายการ`);
    }

    setOpenConfirm(true);
  };
  const handleCloseBillApprove = () => {
    setOpenConfirm(false);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   getData(
  //     newPage,
  //     rowsPerPage,
  //     province,
  //     amphur,
  //     searchName,
  //     claimNo,
  //     dateStart,
  //     dateEnd,
  //     searchEmpType,
  //     billNo
  //   );
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  //   getData(
  //     0,
  //     event.target.value,
  //     province,
  //     amphur,
  //     searchName,
  //     claimNo,
  //     dateStart,
  //     dateEnd,
  //     searchEmpType,
  //     billNo
  //   );
  // };

  let NumberForm = new Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  // const columns = [
  //   // { field: "caseID", headerName: "No.", width: 70 },
  //   { field: "claim_no", headerName: "เลขเคลม", width: 120 },
  //   { field: "company", headerName: "บริษัท", width: 120 },
  //   {
  //     field: "dispatch_datetime",
  //     headerName: "วันที่จ่ายงาน",
  //     // type: "number",
  //     width: 120,
  //   },
  //   {
  //     field: "claim_Mtype_desc",
  //     headerName: "ประเภทเคลม",
  //     // description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     // width: 160,
  //     // valueGetter: (params) =>
  //     //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  //   },
  //   { field: "acc_zone", headerName: "เขต", width: 120 },
  //   {
  //     field: "survey_province_desc",
  //     headerName: "จังหวัดที่ตรวจสอบ",
  //     width: 120,
  //   },
  //   { field: "survey_amphur_desc", headerName: "อำเภอที่ตรวจสอบ", width: 120 },
  //   { field: "surveyorName", headerName: "ชื่อพนักงานสำรวจ", width: 120 },
  //   { field: "service_fee", headerName: "ค่าบริการ", width: 120 },
  //   { field: "traveling_expense", headerName: "ค่าพาหนะจ่าย", width: 120 },
  //   { field: "claim_payment", headerName: "เรียกร้องจ่าย", width: 120 },
  //   { field: "other", headerName: "ค่าใช้จ่ายอื่นๆ", width: 120 },
  //   {
  //     field: "invest_payment",
  //     headerName: "ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี",
  //     width: 120,
  //   },
  //   { field: "vat", headerName: "ภาษี 3 %", width: 120 },
  //   { field: "total", headerName: "ยอดจ่ายหลังหักภาษี", width: 120 },
  //   { field: "bill_paymentNo", headerName: "เลขตั้งเบิก", width: 120 },
  //   { field: "bill_approve_flag", headerName: "สถานะการอนุมัติ", width: 120 },
  //   // { field: "edit", headerName: "Action", width: 120 },
  // ];

  return (
    <>
      <Box
      // sx={{
      //   // display: "flex",
      //   // justifyContent: "space-between",
      //   // marginTop: 2,
      //   // width: "100%",
      //   // overflowX: "scroll",
      //   button: {
      //     flex: "none",
      //   },
      // }}
      >
        <Box
          sx={{
            pb: 2,
          }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            อนุมัติจ่าย
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <div>
              <Grid container>
                <ExportExcelSSBilling
                  fileName={"ตั้งเบิกค่าพาพนะ(สรุปส่งจ่าย)"}
                  province={province}
                  amphur={amphur}
                  searchName={searchName}
                  claimNo={claimNo}
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  userData={userData}
                  searchEmpType={searchEmpType}
                  billNo={billNo}
                />

                {/* <ExportExcelSSBillingSSV
                fileName={"ตั้งเบิกค่าพาพนะ(สรุปส่งจ่าย)-SSV"}
                province={province}
                amphur={amphur}
                searchName={searchName}
                claimNo={claimNo}
                dateStart={dateStart}
                dateEnd={dateEnd}
                userData={userData}
              /> */}
              </Grid>
              <br />
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                sx={{ flex: 1, p: "0px", mb: "20px" }}
                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                spacing={{ xs: 1, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={4} sm={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      disableFuture
                      label="วันที่จ่ายงาน"
                      // inputFormat="YYYY-MM-DD"
                      inputFormat="DD/MM/YYYY"
                      value={dateStart}
                      onChange={handleChangeDateStart}
                      renderInput={(params) => <TextField {...params} />}
                      componentsProps={{
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="ถึงวันที่"
                      // inputFormat="YYYY-MM-DD"
                      inputFormat="DD/MM/YYYY"
                      value={dateEnd}
                      onChange={handleChangeDateEnd}
                      renderInput={(params) => <TextField {...params} />}
                      componentsProps={{
                        actionBar: {
                          actions: ["clear", "today"],
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <FormControl>
                    <TextField
                      // sx={{ flex: 1, p: "0px", mb: "20px" }}
                      placeholder="เลขตั้งเบิก"
                      onChange={(e) => {
                        setBillNo(e.target.value);
                      }}
                      InputProps={{
                        "aria-label": "search billing number",
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              type="button"
                              sx={{ p: "0px" }}
                              aria-label="search"
                              onClick={() =>
                                getData(
                                  0,
                                  rowsPerPage,
                                  province,
                                  amphur,
                                  searchName,
                                  claimNo,
                                  dateStart,
                                  dateEnd,
                                  searchEmpType,
                                  billNo
                                )
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                // spacing={2}
                justifyContent="space-between"
                alignItems="center"
                sx={{ flex: 1, p: "0px", mb: "20px" }}
                rowSpacing={{ xs: 2, sm: 2, md: 2 }}
                // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                spacing={{ xs: 1, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      // sx={{ flex: 1, p: "0px", mb: "20px" }}
                      placeholder="เลขเคลม"
                      onChange={(e) => {
                        setClaimNo(e.target.value);
                      }}
                      InputProps={{
                        "aria-label": "search employee name",
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              type="button"
                              sx={{ p: "0px" }}
                              aria-label="search"
                              onClick={() =>
                                getData(
                                  0,
                                  rowsPerPage,
                                  province,
                                  amphur,
                                  searchName,
                                  claimNo,
                                  dateStart,
                                  dateEnd,
                                  searchEmpType,
                                  billNo
                                )
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      // sx={{ flex: 1, p: "0px", mb: "20px" }}
                      placeholder="ประเภทพนักงาน"
                      onChange={(e) => {
                        setSearchEmpType(e.target.value);
                      }}
                      InputProps={{
                        "aria-label": "search claim number",
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              type="button"
                              sx={{ p: "0px" }}
                              aria-label="searchClaim"
                              onClick={() =>
                                getData(
                                  0,
                                  rowsPerPage,
                                  province,
                                  amphur,
                                  searchName,
                                  dateStart,
                                  dateEnd,
                                  searchEmpType,
                                  billNo
                                )
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      // sx={{ flex: 1, p: "0px", mb: "20px" }}
                      placeholder="ชื่อพนักงานสำรวจ"
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                      InputProps={{
                        "aria-label": "search claim number",
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              type="button"
                              sx={{ p: "0px" }}
                              aria-label="searchClaim"
                              onClick={() =>
                                getData(
                                  0,
                                  rowsPerPage,
                                  province,
                                  amphur,
                                  searchName,
                                  dateStart,
                                  dateEnd,
                                  searchEmpType,
                                  billNo
                                )
                              }
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      id="outlined-select-province"
                      select
                      label="จังหวัด"
                      value={province}
                      onChange={(event) => {
                        setProvince(event.target.value);
                        setAmphur("");
                        getAmphur(event.target.value);
                        // getData(
                        //   0,
                        //   rowsPerPage,
                        //   event.target.value,
                        //   "",
                        //   searchName,
                        //   claimNo,
                        //   dateStart,
                        //   dateEnd
                        // );
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: province ? "visible" : "hidden" }}
                            onClick={handleClearClick}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    >
                      {provinceList.map((item) => (
                        <MenuItem
                          key={`P${item.provinceID}`}
                          value={item.provinceID}
                        >
                          {item.provincename}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <FormControl fullWidth={true}>
                    <TextField
                      id="outlined-select-amphur"
                      select
                      label="อำเภอ"
                      value={amphur}
                      onChange={(event) => {
                        setAmphur(event.target.value);
                        // getData(
                        //   0,
                        //   rowsPerPage,
                        //   province,
                        //   event.target.value,
                        //   searchName,
                        //   claimNo,
                        //   dateStart,
                        //   dateEnd
                        // );
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{ visibility: amphur ? "visible" : "hidden" }}
                            onClick={handleClearClickAmphur}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    >
                      {amphurList.map((item) => (
                        <MenuItem
                          key={`A${item.amphurID}`}
                          value={item.amphurID}
                        >
                          {item.amphurname}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2}>
                  <Button
                    variant="contained"
                    // size="large"
                    sx={{ mt: 1, ml: 1 }}
                    style={{ backgroundColor: "#32B917", marginRight: "5px" }}
                    onClick={() => {
                      setPage(0);
                      getData(
                        0,
                        rowsPerPage,
                        province,
                        amphur,
                        searchName,
                        claimNo,
                        dateStart,
                        dateEnd,
                        searchEmpType,
                        billNo
                      );
                    }}
                  >
                    <Typography fontSize={14}>แสดงรายงาน</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>

              {/* <Grid container justifyContent="flex-end" alignItems="flex-start">
                {userData.rolename === "System Admin" ? (
                  <Button
                    variant="contained"
                    // size="large"
                    sx={{ mt: 1, ml: 1 }}
                    style={{ backgroundColor: "#f78c19", marginRight: "5px" }}
                    // onClick={() =>
                    //   getData(
                    //     0,
                    //     rowsPerPage,
                    //     province,
                    //     amphur,
                    //     searchName,
                    //     claimNo,
                    //     dateStart,
                    //     dateEnd,
                    //     searchEmpType
                    //   )
                    // }
                    onClick={() => handleClickBillApprove()}
                  >
                    <Typography fontSize={14}>Approve</Typography>
                  </Button>
                ) : (
                  ""
                )}
              </Grid> */}
              <br />

              <EnhancedTableToolbar numSelected={selected.length} />
              <div align="right">หน่วย: บาท</div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  {/* <TableHead>
                    <TableRow>
                      <StyledTableCell>No.</StyledTableCell>
                      <StyledTableCell>เลขเคลม</StyledTableCell>
                      <StyledTableCell>บริษัท</StyledTableCell>
                      <StyledTableCell>วันที่จ่ายงาน</StyledTableCell>
                      <StyledTableCell>ประเภทเคลม</StyledTableCell>
                      <StyledTableCell>เขต</StyledTableCell>
                      <StyledTableCell>จังหวัดที่ตรวจสอบ</StyledTableCell>
                      <StyledTableCell>อำเภอที่ตรวจสอบ</StyledTableCell>
                      <StyledTableCell>ชื่อพนักงานสำรวจ</StyledTableCell>
                      <StyledTableCell>ยอดจ่ายค่าสำรวจพนักงานก่อนหักภาษี</StyledTableCell>
                      <StyledTableCell>ค่าพาหนะจ่าย</StyledTableCell>
                      <StyledTableCell>เรียกร้องจ่าย</StyledTableCell>
                      <StyledTableCell>ค่าใช้จ่ายอื่นๆ</StyledTableCell>
                      <StyledTableCell>ภาษี 3 %</StyledTableCell>
                      <StyledTableCell>ยอดจ่ายหลังหักภาษี</StyledTableCell>
                      <StyledTableCell>เลขตั้งเบิก</StyledTableCell>
                      <StyledTableCell>สถานะการอนุมัติ</StyledTableCell>
                    </TableRow>
                  </TableHead> */}

                  <EnhancedTableHead
                    numSelected={selected.length}
                    // order={order}
                    // orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    // onRequestSort={handleRequestSort}
                    rowCount={dataRow.length}
                    // rowCount={data.total}
                  />
                  <TableBody>
                    <tr>
                      <td>
                        <div className={loading === true ? "loading" : "hide"}>
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>

                    {dataRow.length > 0
                      ? (rowsPerPage > 0
                          ? dataRow.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                          : dataRow
                        ).map((row, i) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${i}`;

                          // var isStamped = false;
                          // if (row.bill_stamp_flag === "Y") {
                          //   isStamped = true;
                          // }

                          // var statusTitle = "Wait approve";
                          var statusTitle = "รอวางบิล";
                          var statusStyle = "cr";
                          if (row.bill_approve_flag === "A") {
                            // statusTitle = "Approve";
                            statusTitle = "วางบิล";
                            statusStyle = "cs";
                          } else if (row.bill_approve_flag === "S") {
                            statusTitle = "ตั้งเบิก";
                            statusStyle = "cgr";
                          } else if (row.bill_approve_flag === "R") {
                            statusTitle = "ยกเลิก";
                            statusStyle = "corg";
                          }
                          return (
                            // <TableRow key={row.caseID}>
                            <TableRow
                              // hover
                              // onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              // sx={{ cursor: "pointer" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  onClick={(event) =>
                                    handleClick(event, row.id)
                                  }
                                />
                              </TableCell>
                              <TableCell component="th">
                                {i + 1 + page * rowsPerPage}
                              </TableCell>

                              {/* <TableCell component="th" scope="row"> */}
                              <TableCell
                                component="th"
                                id={labelId}
                                // scope="row"
                                padding="none"
                              >
                                <Typography>{row.claim_no}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row.company}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row.dispatch_datetime}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row.claim_Mtype_desc}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row.acc_zone}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {row.survey_province_desc}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {row.survey_amphur_desc}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <Typography>{row.surveyorName}</Typography>
                              </TableCell>
                              {/* <TableCell align="right">
                                <Typography>
                                  {NumberForm.format(row.service_fee)}
                                </Typography>
                              </TableCell> */}
                              <TableCell align="right">
                                <Typography>
                                  {isEditing && rowIndex === row.id ? (
                                    <Box
                                      component="form"
                                      sx={{
                                        "& > :not(style)": {
                                          m: 1,
                                          width: "10ch",
                                        },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <TextField
                                        id="service_pay"
                                        type="number"
                                        size="small"
                                        defaultValue={editValueServiceFee}
                                        onChange={(event) => {
                                          setEditValueServiceFee(
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    NumberForm.format(row.service_fee)
                                  )}
                                </Typography>
                              </TableCell>

                              <TableCell align="right">
                                <Typography>
                                  {/* {NumberForm.format(row.traveling_expense)} */}
                                  {isEditing && rowIndex === row.id ? (
                                    <Box
                                      component="form"
                                      sx={{
                                        "& > :not(style)": {
                                          m: 1,
                                          width: "10ch",
                                        },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <TextField
                                        id="trans_pay"
                                        type="number"
                                        size="small"
                                        defaultValue={editValueTransPay}
                                        onChange={(event) => {
                                          setEditValueTransPay(
                                            event.target.value
                                          );
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    NumberForm.format(row.traveling_expense)
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>
                                  {NumberForm.format(row.claim_payment)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>
                                  {/* {NumberForm.format(row.other)} */}
                                  {isEditing && rowIndex === row.id ? (
                                    <Box
                                      component="form"
                                      sx={{
                                        "& > :not(style)": {
                                          m: 1,
                                          width: "10ch",
                                        },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <TextField
                                        id="other_pay"
                                        type="number"
                                        size="small"
                                        defaultValue={editValue}
                                        onChange={(event) => {
                                          setEditValue(event.target.value);
                                        }}
                                      />
                                    </Box>
                                  ) : (
                                    NumberForm.format(row.other)
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>
                                  {NumberForm.format(row.invest_payment)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>
                                  {NumberForm.format(row.vat)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>
                                  {NumberForm.format(row.total)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography>{row.bill_paymentNo}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={statusStyle}>
                                  {statusTitle}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {isEditing && rowIndex === row.id ? (
                                  <>
                                    <LoadingButton
                                      loading={isLoading}
                                      loadingPosition="start"
                                      startIcon={<SaveIcon />}
                                      variant="contained"
                                      onClick={(e) => updOtherPayData()}
                                    >
                                      Save
                                    </LoadingButton>

                                    <Button
                                      onClick={() => {
                                        setIsEditing(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : row.bill_approve_flag !== "S" &&
                                  row.bill_approve_flag !== "A" ? (
                                  <Button
                                    // disabled={isAdding || isEditing}
                                    // className={classNames(classes.editBtn, `editBtn_${tableName}`)}
                                    onClick={(event) => {
                                      handleEditRow(event, row.id);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : ""}

                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                // count={data.total}
                count={dataRow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </Paper>
        </Box>
      </Box>

      <Dialog
        open={openConfirm}
        onClose={handleCloseBillApprove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ต้องการวางบิลตั้งเบิกค่าพาพนะ(สรุปส่งจ่าย)
            <br />
            {txtConfirm}
            <br />
            <Typography component="span" color="error">
              {txtWarning}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBillApprove}>Cancle</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={(e) => updApproveData()}
          >
            ยืนยันวางบิล
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function SuperBilling() {
  return <SuperBillingContent />;
}
