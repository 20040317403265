import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
} from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import UploadIcon from "@mui/icons-material/Upload";


const ExportExcel = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Button
        variant="text"
        type="submit"
        startIcon={<UploadIcon />}
        size="large"
        sx={{ mt: 1, ml: 1 }}
        onClick={(e) => exportToExcel(fileName)}
      >
        Export
      </Button>
    </>
  );
};

export default ExportExcel;
