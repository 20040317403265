import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { Padding } from "@mui/icons-material";

// Select Chip-------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getStylesDist(emptype, empTypeDist, theme) {
  return {
    fontWeight:
      empTypeDist.indexOf(emptype) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function getStylesFlat(emptype, empTypeFlat, theme) {
  return {
    fontWeight:
      empTypeFlat.indexOf(emptype) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

//-------------------------------------------END

const dataTypeList = [
  {
    value: "พนักงานสำรวจอุบัติเหตุ",
    label: "พนักงานสำรวจอุบัติเหตุ",
  },
  {
    value: "พนักงานตรวจสภาพรถยนต์",
    label: "พนักงานตรวจสภาพรถยนต์",
  },
];

const Img = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: 80,
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function toFindDuplicates(arry) {
  const uniqueElements = new Set(arry);
  const filteredElements = arry.filter((item) => {
    if (uniqueElements.has(item)) {
      uniqueElements.delete(item);
    } else {
      return item;
    }
  });

  return [...new Set(uniqueElements)];
}

// export default function PaymentForm() {
export default function PaymentForm(props) {
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const { zoneID } = props;
  const [companyList, setCompanyList] = useState([]);
  const [empTypeList, setEmpTypeList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [compID, setCompanyID] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [subTitleZone, setSubTitleZone] = useState("");
  // const [subTitleCompany, setSubTitleCompany] = useState("");
  // const [formTypeList, setFormTypeList] = React.useState([
  //   "TIP",
  //   "InsurVerse",
  //   "Others",
  // ]);
  // const [is_selected, setIs_selected] = useState("DIST");
  const [required, setRequired] = useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  // TEST ------------------------------------------------------
  const theme = useTheme();
  // const [personName, setPersonName] = React.useState([]);
  const [empTypeDist, setEmpTypeDist] = React.useState([]);
  const [empTypeFlat, setEmpTypeFlat] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };
  const handleChangeDistType = (event) => {
    const {
      target: { value },
    } = event;
    setEmpTypeDist(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChangeFlatType = (event) => {
    const {
      target: { value },
    } = event;
    setEmpTypeFlat(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // END TEST ------------------------------------------------------

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (zoneID) {
      if (zoneID === "1") {
        setSubTitle("กรุงเทพฯ");
        setSubTitleZone("กรุงเทพฯ");
      } else if (zoneID === "2") {
        setSubTitle("ปริมณฑล");
        setSubTitleZone("ปริมณฑล");
      } else if (zoneID === "3") {
        setSubTitle("ต่างจังหวัดในอำเภอเมือง");
        setSubTitleZone("ต่างจังหวัดในอำเภอเมือง");
      } else if (zoneID === "4") {
        setSubTitle("ต่างจังหวัดนอกอำเภอเมือง");
        setSubTitleZone("ต่างจังหวัดนอกอำเภอเมือง");
      } else {
        setSubTitle("Empty");
        setSubTitleZone("Empty");
      }
    }

    getCompany();
    getEmployeeType();
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  // setTimeout(() => {
  //   setSuccessMessage("");
  //   setErrorMessage("");
  // }, 6000);

  function onSubmit(data) {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    // Verify have duplicate data ---------------------------------------
    // concatenate the above arrays using spread operator
    const join_arr = [...empTypeDist, ...empTypeFlat];
    // console.log(join_arr);
    // remove duplicate from above array using Set() function
    const result = [...new Set(join_arr)];
    // console.log(result);

    if (join_arr.length !== result.length) {
      // Find duplicates in an array
      const toFindDuplicates = (join_arr) =>
        join_arr.filter((item, index) => join_arr.indexOf(item) !== index);
      const duplicateElements = toFindDuplicates(join_arr);
      // console.log(duplicateElements);

      setErrorMessage(`Duplicate employee type of ${duplicateElements}`);
      setIsLoading(false);
      return;
    }
    // END -------------------------------------------------------------

    // CANCEL
    // //Verify ถ้า "แยกย้าย" มีค่า ต้องให้ 1.2 ค่าพาหนะ เป็นศูนย์
    // var isDisband = 1;
    // if (
    //   data.claim_disband_pymt === null ||
    //   data.claim_disband_pymt === "0" ||
    //   data.claim_disband_pymt === ""
    // ) {
    //   isDisband = 0;
    // }

    var dataForm = JSON.stringify({
      zoneID: zoneID,
      compID: data.compID,
      // payment_type: is_selected,
      // payment_type: "FLATPAY",
      claim_fresh_pymt: data.claim_fresh_pymt,
      claim_dry_pymt: data.claim_dry_pymt,
      claim_conti_pymt: data.claim_conti_pymt,
      claim_follow_pymt: data.claim_follow_pymt,
      claim_disband_pymt: data.claim_disband_pymt,
      // dist_vehicle_pymt: isDisband === 1 ? 0 : data.dist_vehicle_pymt,   //CANCEL
      dist_vehicle_pymt: data.dist_vehicle_pymt,
      dist_claim_pymt: data.dist_claim_pymt,
      tel_pymt: data.tel_pymt,
      daily_pymt: data.daily_pymt,
      flat_claim_fresh_pymt: data.flat_claim_fresh_pymt,
      flat_claim_dry_pymt: data.flat_claim_dry_pymt,
      flat_claim_conti_pymt: data.flat_claim_conti_pymt,
      flat_claim_follow_pymt: data.flat_claim_follow_pymt,
      flat_claim_disband_pymt: data.flat_claim_disband_pymt,
      // flat_vehicle_pymt: data.flat_vehicle_pymt,
      flat_vehicle_pymt: 0,
      flat_claim_pymt: data.flat_claim_pymt,
      emp_type_dist: empTypeDist,
      emp_type_flat: empTypeFlat,
    });
    // console.log(`dataForm`, dataForm);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/payconfig/add`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        setValue(0);
        setIsLoading(false);
        // console.log(response);
        if (response.status === 200 || response.status === 201) {
          setSuccessMessage(response.data.message);

          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(response.data.message);
        }
        getPaymentConfig(compID); // Reload config
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
    return false;
  }

  function getCompany() {
    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/ss/list/confcomp`, {
        headers,
      })
      .then((response) => {
        setCompanyList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getEmployeeType() {
    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/ss/list/emptype`, {
        headers,
      })
      .then((response) => {
        setEmpTypeList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getPaymentConfig(comp) {
    setErrorMessage("");
    setCompanyID(comp);

    // Set sub title
    if (comp) {
      const compName = companyList.filter((company) => {
        return company.compID === comp;
      });
      setSubTitle(`${subTitleZone} > ${compName[0].company_abbv}`);
    }

    // Get payment config
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/paymentconfig?zoneID=${zoneID}&compID=${comp}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.status === 200 && response.data) {
          var item = response.data;

          // Get employee type
          setEmpTypeDist([]);
          setEmpTypeFlat([]);
          var dist = [];
          var flat = [];
          item.employee_type.map((emp) => {
            if (emp.payment_type === "DIST") {
              dist.push(emp.emptype);
            } else if (emp.payment_type === "FLAT") {
              flat.push(emp.emptype);
            }
          });
          setEmpTypeDist(dist);
          setEmpTypeFlat(flat);

          setUser({
            // payment_type: item.payment_type,
            claim_fresh_pymt: item.claim_fresh_pymt,
            claim_dry_pymt: item.claim_dry_pymt,
            claim_conti_pymt: item.claim_conti_pymt,
            claim_follow_pymt: item.claim_follow_pymt,
            claim_disband_pymt: item.claim_disband_pymt,
            dist_vehicle_pymt: item.dist_vehicle_pymt,
            dist_claim_pymt: item.dist_claim_pymt,
            tel_pymt: item.tel_pymt,
            daily_pymt: item.daily_pymt,
            flat_claim_fresh_pymt: item.flat_claim_fresh_pymt,
            flat_claim_dry_pymt: item.flat_claim_dry_pymt,
            flat_claim_conti_pymt: item.flat_claim_conti_pymt,
            flat_claim_follow_pymt: item.flat_claim_follow_pymt,
            flat_claim_disband_pymt: item.flat_claim_disband_pymt,
            // flat_vehicle_pymt: item.flat_vehicle_pymt,
            flat_claim_pymt: item.flat_claim_pymt,
            // emp_type_dist: typeDist,
            // emp_type_flat: typeFlat,
          });
          // setIs_selected(item.payment_type === "FLATPAY" ? "FLATPAY" : "");
        } else {
          // console.log(`No have data`, response);
          // setUser({
          //   claim_fresh_pymt: null,
          //   claim_dry_pymt: null,
          //   claim_conti_pymt: null,
          //   claim_follow_pymt: null,
          //   claim_disband_pymt: null,
          //   dist_vehicle_pymt: null,
          //   dist_claim_pymt: null,
          //   tel_pymt: null,
          //   daily_pymt: null,
          //   flat_claim_fresh_pymt: null,
          //   flat_claim_dry_pymt: null,
          //   flat_claim_conti_pymt: null,
          //   flat_claim_follow_pymt: null,
          //   flat_claim_disband_pymt: null,
          //   // flat_vehicle_pymt: null,
          //   flat_claim_pymt: null,
          //   // emp_type_dist: null,
          //   // emp_type_flat: null,
          // });
          setUser({
            claim_fresh_pymt: 0,
            claim_dry_pymt: 0,
            claim_conti_pymt: 0,
            claim_follow_pymt: 0,
            claim_disband_pymt: 0,
            dist_vehicle_pymt: 0,
            dist_claim_pymt: 0,
            tel_pymt: 0,
            daily_pymt: 0,
            flat_claim_fresh_pymt: 0,
            flat_claim_dry_pymt: 0,
            flat_claim_conti_pymt: 0,
            flat_claim_follow_pymt: 0,
            flat_claim_disband_pymt: 0,
            flat_vehicle_pymt: 0,
            flat_claim_pymt: 0,
            // emp_type_dist: 0,
            // emp_type_flat: 0,
          });
          setEmpTypeDist([]);
          setEmpTypeFlat([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function onPaymentTypeChange(event) {
  //   setIs_selected(event);
  //   if (event === "DIST") setRequired(true);
  //   else setRequired(false); // FLATPAY
  // }

  const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleDelete = () => {
  //   console.log("You clicked the delete icon.");
  // };

  return (
    <div>
      {/* <Typography>Title</Typography> */}
      <Typography variant="h6" component="h4" gutterBottom>
        Configure &gt; {subTitle}
      </Typography>

      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={8} sm={6} md={4} lg={2}>
              <FormControl fullWidth={true}>
                <TextField
                  {...register("compID")}
                  required
                  id="compID"
                  select
                  label="บริษัทประกัน"
                  value={compID}
                  variant="outlined"
                  // onChange={(event) => setCompanyID(event.target.value)}
                  onChange={(event) => getPaymentConfig(event.target.value)}
                >
                  {/* {formTypeList.map((item, index) => (
                    <MenuItem key={`T${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))} */}
                  {companyList.map((item) => (
                    <MenuItem key={`C${item.compID}`} value={item.compID}>
                      {item.company_abbv}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <br />
        </div>
        <Grid item md={12}>
          <FormControl>
            {/* <FormLabel id="demo-radio-buttons-group-label">
              รูปแบบการจ่าย
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={is_selected}
              name="radio-buttons-group"
              {...register("is_selected")}
            >
              <FormControlLabel
                // onChange={(event) => setIs_selected("1")}
                onChange={(event) => onPaymentTypeChange("DIST")}
                value="DIST"
                control={<Radio />}
                // label="1. แบบคิดตามระยะทาง"
                label={
                  <Typography variant="h6" component="h6">
                    1. แบบคิดตามระยะทาง
                  </Typography>
                }
              /> */}

            <Typography variant="h6" component="h6">
              1. แบบคิดตามระยะทาง
            </Typography>

            <div className="pxl">
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={6} lg={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="dist-multiple-chip-label">
                      ประเภทพนักงาน
                    </InputLabel>
                    <Select
                      labelId="dist-multiple-chip-label"
                      id="dist-multiple-chip"
                      multiple
                      value={empTypeDist}
                      onChange={handleChangeDistType}
                      input={
                        <OutlinedInput
                          id="dist-select-multiple-chip"
                          label="ประเภทพนักงาน"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {empTypeList.map((item) => (
                        <MenuItem
                          key={item.emptype}
                          value={item.emptype}
                          style={getStylesDist(
                            item.emptype,
                            empTypeDist,
                            theme
                          )}
                        >
                          {item.emptype}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="pxl">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography>1.1 ค่าบริการ</Typography>
                  <div className="pxll pxlt">
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.1.1 เคลมสด</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("claim_fresh_pymt")}
                          aria-invalid={
                            errors.claim_fresh_pymt ? "true" : "false"
                          }
                          id="claim_fresh_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.1.2 เคลมแห้ง</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          // {...register("claim_dry_pymt", { required: true })}
                          {...register("claim_dry_pymt")}
                          aria-invalid={
                            errors.claim_dry_pymt ? "true" : "false"
                          }
                          id="claim_dry_pymt"
                          variant="outlined"
                          // disabled={is_selected === "FLATPAY"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.1.3 เคลมต่อเนื่อง</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          // {...register("claim_conti_pymt", {
                          //   required: true,
                          // })}
                          {...register("claim_conti_pymt")}
                          aria-invalid={
                            errors.claim_conti_pymt ? "true" : "false"
                          }
                          id="claim_conti_pymt"
                          variant="outlined"
                          // disabled={is_selected === "FLATPAY"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.1.4 เคลมติดตาม</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("claim_follow_pymt")}
                          aria-invalid={
                            errors.claim_follow_pymt ? "true" : "false"
                          }
                          id="claim_follow_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>1.1.5 แยกย้าย</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("claim_disband_pymt")}
                          aria-invalid={
                            errors.claim_disband_pymt ? "true" : "false"
                          }
                          id="claim_disband_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>1.2 ค่าพาหนะ (ต่อ กม.)</Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        // {...register("dist_vehicle_pymt", { required: true })}
                        {...register("dist_vehicle_pymt")}
                        aria-invalid={
                          errors.dist_vehicle_pymt ? "true" : "false"
                        }
                        id="dist_vehicle_pymt"
                        // disabled={is_selected === "FLATPAY"}
                        // required
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      บาท
                    </Grid>
                    <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>1.3 ค่าโทรศัพท์</Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        // {...register("tel_pymt", { required: true })}
                        {...register("tel_pymt")}
                        aria-invalid={errors.tel_pymt ? "true" : "false"}
                        id="tel_pymt"
                        // disabled={is_selected === "FLATPAY"}
                        required
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      บาท
                    </Grid>
                    <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>
                        1.4 ค่าประจำวัน (จำนวนข้อ X เรตราคา)
                      </Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        // {...register("daily_pymt", { required: true })}
                        {...register("daily_pymt")}
                        aria-invalid={errors.daily_pymt ? "true" : "false"}
                        id="daily_pymt"
                        // disabled={is_selected === "FLATPAY"}
                        required
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      บาท
                    </Grid>
                    <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>
                        1.5 ค่าเรียกร้อง (จำนวนเงินที่เรียกร้องได้ X
                        จำนวนเปอร์เซ็นต์)
                      </Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        // {...register("dist_claim_pymt", { required: true })}
                        {...register("dist_claim_pymt")}
                        aria-invalid={errors.dist_claim_pymt ? "true" : "false"}
                        id="dist_claim_pymt"
                        // disabled={is_selected === "FLATPAY"}
                        required
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      %
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            {/* <br />
            <FormControlLabel
              // onChange={(event) => setIs_selected("2")}
              onChange={(event) => onPaymentTypeChange("FLATPAY")}
              value="FLATPAY"
              control={<Radio />}
              // label="2. แบบเหมาจ่าย"
              label={
                <Typography variant="h6" component="h6">
                  2. แบบเหมาจ่าย
                </Typography>
              }
            /> */}

            <br />
            <Divider />
            <br />
            <Typography variant="h6" component="h6">
              2. แบบเหมาจ่าย
            </Typography>

            <div className="pxl">
              <Grid container spacing={2}>
                <Grid item xs={8} sm={8} md={6} lg={4}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="flat-multiple-chip-label">
                      ประเภทพนักงาน
                    </InputLabel>
                    <Select
                      labelId="flat-multiple-chip-label"
                      id="flat-multiple-chip"
                      multiple
                      value={empTypeFlat}
                      onChange={handleChangeFlatType}
                      input={
                        <OutlinedInput
                          id="flat-select-multiple-chip"
                          label="ประเภทพนักงาน"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {empTypeList.map((item) => (
                        <MenuItem
                          key={item.emptype}
                          value={item.emptype}
                          style={getStylesFlat(
                            item.emptype,
                            empTypeFlat,
                            theme
                          )}
                        >
                          {item.emptype}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </div>

            <div className="pxl">
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography>2.1 ค่าบริการ</Typography>
                  <div className="pxll pxlt">
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1.1 เคลมสด</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_fresh_pymt")}
                          aria-invalid={
                            errors.flat_claim_fresh_pymt ? "true" : "false"
                          }
                          id="flat_claim_fresh_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1.2 เคลมแห้ง</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_dry_pymt")}
                          aria-invalid={
                            errors.flat_claim_dry_pymt ? "true" : "false"
                          }
                          id="flat_claim_dry_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1.3 เคลมต่อเนื่อง</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_conti_pymt")}
                          aria-invalid={
                            errors.flat_claim_conti_pymt ? "true" : "false"
                          }
                          id="flat_claim_conti_pymt"
                          variant="outlined"
                          // disabled={is_selected === "FLATPAY"}
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1.4 เคลมติดตาม</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_follow_pymt")}
                          aria-invalid={
                            errors.flat_claim_follow_pymt ? "true" : "false"
                          }
                          id="flat_claim_follow_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item md={6} sx={{ mt: 2 }}>
                        <Typography>2.1.5 แยกย้าย</Typography>
                      </Grid>
                      <Grid item md={5} className="right">
                        <TextField
                          {...register("flat_claim_disband_pymt")}
                          aria-invalid={
                            errors.flat_claim_disband_pymt ? "true" : "false"
                          }
                          id="flat_claim_disband_pymt"
                          variant="outlined"
                          required
                        />
                      </Grid>
                      <Grid item md={1} sx={{ mt: 2 }}>
                        บาท
                      </Grid>
                    </Grid>
                  </div>
                </Grid>

                <Grid item md={12}>
                  <Grid container spacing={2}>
                    {/* <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>2.1 ค่าพาหนะ</Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        {...register("flat_vehicle_pymt")}
                        aria-invalid={
                          errors.flat_vehicle_pymt ? "true" : "false"
                        }
                        id="flat_vehicle_pymt"
                        required
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      บาท
                    </Grid> */}

                    <Grid item md={6} sx={{ mt: 2 }}>
                      <Typography>
                        2.2 ค่าเรียกร้อง (จำนวนเปอร์เซ็นต์)
                      </Typography>
                    </Grid>
                    <Grid item md={5} className="right">
                      <TextField
                        // {...register("flat_claim_pymt", { required: true })}
                        {...register("flat_claim_pymt")}
                        aria-invalid={errors.flat_claim_pymt ? "true" : "false"}
                        id="flat_claim_pymt"
                        // disabled={is_selected === "DIST"}
                        // required={!required}
                        // type="number"
                        required
                        // value={value_flat_claim_pymt}
                      />
                    </Grid>
                    <Grid item md={1} sx={{ mt: 2 }}>
                      %
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            {/* </RadioGroup> */}
          </FormControl>
        </Grid>

        <div className="pxl">
          <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="flex-end">
            <Alert
              severity="error"
              className={errorMessage === "" ? "hide" : ""}
            >
              {errorMessage}
            </Alert>
            <Alert
              severity="success"
              className={successMessage === "" ? "hide" : ""}
            >
              {successMessage}
            </Alert>

            <LoadingButton
              type="submit"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={(event) => getPaymentConfig(compID)}>
              Cancel
            </Button>
          </Grid>
        </div>
      </form>
    </div>
  );
}
