import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  FormControl,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import StyledCard from "../style/StyledCard";

import ClearIcon from "@mui/icons-material/Clear";


const dataTypeList = [
  {
    value: "พนักงานสำรวจอุบัติเหตุ",
    label: "พนักงานสำรวจอุบัติเหตุ",
  },
  {
    value: "พนักงานตรวจสภาพรถยนต์",
    label: "พนักงานตรวจสภาพรถยนต์",
  },
];

const Img = styled("img")({
  width: "100%",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>,
//   },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(props,ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function SuperMonitorContent() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [dataType, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [province, setProvince] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [veh_typeList, setVeh_typeList] = useState([]);
  const [is_employee, setIs_employee] = useState(false);
  const [isStatus, setIsStatus] = useState("");
  const [all_count, setAll_count] = useState(0);
  const [ready_count, setReady_count] = useState(0);
  const [working_count, setWorking_count] = useState(0);
  const [offline_count, setOffline_count] = useState(0);
  const [notAllow_count, setNotAllow_count] = useState(0);
  const [empName, setEmpName] = useState("");
  const [empImage, setempImage] = useState("");
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getProvince();
    getVehType();
    getData(0, rowsPerPage, province, amphur, searchName, dataType, isStatus);
  }, []);

  useEffect(() => {
    // reset form with user data
    reset(user);
  }, [user]);

  function getProvince() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/province`)
      .then((response) => {
        setProvinceList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAmphur(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        // console.log(response.data);
        setAmphurList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAmphurEmp(provinceID) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`
      )
      .then((response) => {
        // console.log(response.data);
        setAmphurListEmp(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getVehType() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/opt/vehicletypes`)
      .then((response) => {
        // console.log(response.data);
        setVeh_typeList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(
    nowPage,
    nowRowsPerPage,
    provinceID,
    amphurID,
    employeeName,
    dataType,
    isStatus
  ) {
    // console.log(dataType);
    setLoading(true);
    setDataRow([]);
    var where = "";
    if (dataType !== "") {
      if (dataType === "พนักงานสำรวจอุบัติเหตุ") {
        where = "&acc_employee=1";
      } else {
        where = "&insp_employee=1";
      }
    }
    if (isStatus !== "") {
      where = "&tapStatus=" + isStatus;
    }

    var nowPage = nowPage + 1;
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/operation/list/user?page=${nowPage}&perpage=${nowRowsPerPage}&provinceID=${provinceID}&amphurID=${amphurID}&name=${employeeName}${where}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
        setAll_count(response.data.all_count);
        setReady_count(response.data.ready_count);
        setWorking_count(response.data.working_count);
        setOffline_count(response.data.offline_count);
        setNotAllow_count(response.data.notAllow_count);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleClickOpen(i) {
    // console.log(dataRow[i]);
    // console.log(dataRow[i].image.STRAIGHT_FACE);
    setEmpName(`${dataRow[i].firstname} ${dataRow[i].lastname}`);
    setempImage(dataRow[i].image.STRAIGHT_FACE);
    setOpen(true);
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(
      newPage,
      rowsPerPage,
      province,
      amphur,
      searchName,
      dataType,
      isStatus
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(
      0,
      event.target.value,
      province,
      amphur,
      searchName,
      dataType,
      isStatus
    );
  };

  function handleStatusCase(i, status) {
    var is_allowWork = status === true ? 1 : 0;
    dataRow[i].is_allowWork = is_allowWork;
    setIs_employee(!is_employee);
    var dataForm = JSON.stringify({
      is_allowWork: is_allowWork,
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/external/operation/user/${dataRow[i].extID}/status`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };

    axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  function handleStatus(value) {
    setPage(0);
    setIsStatus(value);
    getData(0, rowsPerPage, province, amphur, searchName, dataType, value);
  }

  const handleClearDataTypeClick = () => {
    setDataType("");
    getData(0, rowsPerPage, province, amphur, searchName, "", isStatus);
  };

  const handleClearClick = () => {
    setProvince("");
    setAmphur("");
    setAmphurList([]);
    getData(0, rowsPerPage, "", "", searchName, dataType, isStatus);
  };
  const handleClearClickAmphur = () => {
    setAmphur("");
    getData(0, rowsPerPage, province, "", searchName, dataType, isStatus);
  };

  return (
    <Box>
      <Box
        sx={{
          pb: 2,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Digital Survey &gt; Operation Monitor
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <div>
            <div>
              <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                TransitionComponent={Transition}
                sx={{
                  left: "60%",
                }}
              >
                <DialogTitle id="responsive-dialog-title">
                  {"ตรวจสอบพนักงานก่อนเริ่มงาน"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <div className="txl cbs">{empName}</div>
                    <div className="txl pxlt mxlt">ภาพเซลฟี่ก่อนเริ่มงาน</div>
                    <Img alt="complex" src={empImage} className="pxlt mxlt" />
                    <div className="txl pxlt mxlt">
                      ภาพถ่ายเมื่อ{" "}
                      <span className="cbs">00:00:00 20-Nov-2022</span>
                    </div>
                  </DialogContentText>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </div>
            <Grid container spacing={2} sx={{ mb: "13px" }}>
              <Grid item xs={2}>
                <StyledCard
                  status={handleStatus}
                  dataStatus=""
                  data={all_count}
                  active={isStatus === "" ? true : ""}
                >
                  พนักงานทั้งหมด
                </StyledCard>
              </Grid>
              <Grid item xs={2}>
                <StyledCard
                  status={handleStatus}
                  dataStatus="ready"
                  data={ready_count}
                  active={isStatus === "ready" ? true : ""}
                >
                  อนุมัติให้รับงาน
                </StyledCard>
              </Grid>
              <Grid item xs={2}>
                <StyledCard
                  status={handleStatus}
                  dataStatus="working"
                  active={isStatus === "working" ? true : ""}
                  data={working_count}
                >
                  กำลังปฎิบัติงาน
                </StyledCard>
              </Grid>
              <Grid item xs={2}>
                <StyledCard
                  status={handleStatus}
                  dataStatus="offline"
                  active={isStatus === "offline" ? true : ""}
                  data={offline_count}
                >
                  Offline
                </StyledCard>
              </Grid>
              <Grid item xs={2}>
                <StyledCard
                  status={handleStatus}
                  dataStatus="notAllow"
                  active={isStatus === "notAllow" ? true : ""}
                  data={notAllow_count}
                >
                  ไม่อนุมัติให้รับงาน
                </StyledCard>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormControl fullWidth={true}>
                  <TextField
                    sx={{ flex: 1, p: "0px", mb: "20px" }}
                    placeholder="Search employee name"
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        setPage(0);
                        getData(
                          0,
                          rowsPerPage,
                          province,
                          amphur,
                          searchName,
                          dataType,
                          isStatus
                        );
                        ev.preventDefault();
                      }
                    }}
                    InputProps={{
                      "aria-label": "search employee name",
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            type="button"
                            sx={{ p: "0px" }}
                            aria-label="search"
                            onClick={() =>{
                              setPage(0);
                              getData(
                                0,
                                rowsPerPage,
                                province,
                                amphur,
                                searchName,
                                dataType,
                                isStatus
                              )
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-select-dataType"
                    select
                    label="ตำแหน่ง"
                    value={dataType}
                    onChange={(event) => {
                      setPage(0);
                      setDataType(event.target.value);
                      getData(
                        0,
                        rowsPerPage,
                        province,
                        amphur,
                        searchName,
                        event.target.value,
                        isStatus
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{ visibility: dataType ? "visible" : "hidden" }}
                          onClick={handleClearDataTypeClick}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  >
                    {dataTypeList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-select-province"
                    select
                    label="จังหวัด"
                    value={province}
                    onChange={(event) => {
                      setPage(0);
                      setProvince(event.target.value);
                      setAmphur("");
                      getAmphur(event.target.value);
                      getData(
                        0,
                        rowsPerPage,
                        event.target.value,
                        "",
                        searchName,
                        dataType
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{ visibility: province ? "visible" : "hidden" }}
                          onClick={handleClearClick}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  >
                    {provinceList.map((item) => (
                      <MenuItem
                        key={`P${item.provinceID}`}
                        value={item.provinceID}
                      >
                        {item.provincename}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth={true}>
                  <TextField
                    id="outlined-select-amphur"
                    select
                    label="อำเภอ"
                    value={amphur}
                    onChange={(event) => {
                      setPage(0);
                      setAmphur(event.target.value);
                      getData(
                        0,
                        rowsPerPage,
                        province,
                        event.target.value,
                        searchName,
                        dataType,
                        isStatus
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{ visibility: amphur ? "visible" : "hidden" }}
                          onClick={handleClearClickAmphur}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  >
                    {amphurList.map((item) => (
                      <MenuItem key={`A${item.amphurID}`} value={item.amphurID}>
                        {item.amphurname}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  mt={2}
                  align="center"
                  gutterBottom
                >
                  วันที่ปฎิบัติงาน {moment().format("L")}
                </Typography>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Employee name</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Branch</TableCell>
                    <TableCell>Total jobs</TableCell>
                    <TableCell>Last login</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <div className={loading === true ? "loading" : "hide"}>
                    <CircularProgress />
                  </div>

                  {dataRow.map((row, i) => {
                    var statusTitle = "Offline";
                    var statusStyle = "cg";
                    if (row.is_allowWork === 0) {
                      statusTitle = "ไม่อนุมัติให้รับงาน";
                      statusStyle = "cr";
                    } else if (row.is_allowWork === 1) {
                      statusTitle = "อนุมัติให้รับงาน";
                      statusStyle = "cgr";
                    }
                    return (
                      <TableRow key={row.extID}>
                        <TableCell component="th" scope="row">
                          {i + 1 + page * rowsPerPage}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {row.firstname.slice(3, 4)}
                          </Avatar>
                        </TableCell>
                        <TableCell
                          className="cursor"
                          onClick={() => handleClickOpen(i)}
                        >
                          <Typography>
                            {row.firstname} {row.lastname}
                          </Typography>
                          <Typography>Phone: {row.phone}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>จังหวัด: {row.province_desc}</Typography>
                          <Typography>อำเภอ: {row.amphur_desc}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{row.branch_name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{row.dispatch_today_count}</Typography>
                        </TableCell>
                        {/* Todo */}
                        <TableCell>
                          {/* <Typography>{"12/09/2023 17:20:02"}</Typography> */}
                          <Typography>{row.lastlogInDateTime}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={statusStyle}>
                            {statusTitle}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={
                              dataRow[i].is_allowWork === 0 ? false : true
                            }
                            onChange={(data, status) =>
                              handleStatusCase(i, status)
                            }
                            color="success"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </Paper>
      </Box>
    </Box>
  );
}

export default function SuperMonitor() {
  return <SuperMonitorContent />;
}
