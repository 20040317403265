import * as React from "react";

import EvaluationForm from "./EvaluationForm";
import { Box, Button, Paper, Typography } from "@mui/material";

function CustomerEvaFormContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          pb: 2,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          Customer Satisfaction &gt; Evaluation Form
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <EvaluationForm />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default function CustomerEvaForm() {
  return <CustomerEvaFormContent />;
}
