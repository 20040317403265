import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  ButtonBase,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Snackbar,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
// import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller, useWatch, get } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";


export default function PaymentOSCopyForm() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [zone, setZone] = useState("");
  const [qID, setQID] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [openAlert, setOpenAlert] = React.useState(false);

  const [comp_source, setCompSource] = useState("");
  const [comp_dest, setCompDest] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    // watch,
  } = useForm();

  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   // getData(0, rowsPerPage, searchName);
  // }, []);


  function onSubmit(data) {
    setIsLoading(true);

    // Verify company source & destination must not duplicate
    if(comp_source === comp_dest){
      setErrorMessage(`บริษัทที่ต้องการคัดลอก ต้องไม่ใช่บริษัทเดียวกัน`);
      setIsLoading(false);
      return;
    }

    var dataForm = JSON.stringify({
      zone_id: zone,
      company_source: comp_source,
      company_dest: comp_dest,
    });
    // console.log(`formSubmit`, dataForm);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/v1/ss/ins/copyconfig`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
      data: dataForm,
    };
    // console.log(config);

    axios(config)
      .then(function (response) {
        clearFormValue();
        setIsLoading(false);
        if ( (response.status === 200 || response.status === 201) && response.data.success === true) {
          setOpenAlert(true);
          setOpen(false);

          setTimeout(() => {
            window.location.href="/Payment/Outsource";  //Reload config page
          }, 2000);

        } else {
          setErrorMessage(response.data.message);
        }

      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });

    return false;
  }

  const handleClearZoneClick = () => {
    setZone("");
  };


  // function getData(nowPage, nowRowsPerPage, formName) {
  //   setLoading(true);
  //   setDataRow([]);

  //   var nowPage = nowPage + 1;

  //   var config = {
  //     method: "get",
  //     url: `${process.env.REACT_APP_API_URL}api/questionlist?page=${nowPage}&perpage=${nowRowsPerPage}&name=${formName}`,
  //     headers: {
  //       Authorization: `Bearer ${userData.token}`,
  //     },
  //   };
  //   // console.log(config);
  //   axios(config)
  //     .then(function (response) {
  //       setLoading(false);
  //       setData(response.data);
  //       setDataRow(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  async function getCompany() {
    setCompanyList([])

    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/ss/ins/list/confcomp`, {
        headers,
      })
      .then((response) => {
        setCompanyList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getZone() {
    setZoneList([])

    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/ss/ins/list/confzone`, {
        headers,
      })
      .then((response) => {
        setZoneList(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function clearFormValue() {
    setZone("");
    setCompSource("");
    setCompDest("");
  }

  async function handleOpenConfig() {
    await getCompany();
    await getZone();
    clearFormValue();
    setErrorMessage("");
    setOpen(true);
  }

  
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClickOpenConfirm = (id) => {
    // console.log(id);
    setQID(id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openAlert}
          message="I love snacks"
          autoHideDuration={6000}
          onClose={() => setOpenAlert(false)}
        >
          <Alert severity="success">
            คัดลอกสำเร็จ
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setOpenAlert(false)}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="responsive-dialog-title">
              {/* {isAddMode ? "เพิ่มแบบสอบถาม" : "แก้ไขแบบสอบถาม"} */}
              คัดลอกค่าบริการบริษัทประกัน
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <Typography> เลือกบริษัทที่ต้องการคัดลอก</Typography>
                  <br/>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={16}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("zone")}
                          // required
                          id="zone"
                          select
                          label="โซน"
                          value={zone}
                          variant="outlined"
                          onChange={(event) => setZone(event.target.value)}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                sx={{ visibility: zone ? "visible" : "hidden" }}
                                onClick={handleClearZoneClick}
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        >
                          {zoneList.map((item) => (
                            <MenuItem
                              key={`Z${item.zoneID}`}
                              value={item.zoneID}
                            >
                              {item.zone_desc}
                            </MenuItem>
                          ))}
                            {/* <MenuItem
                              key="Z0"
                              value="0"
                            >
                              All
                            </MenuItem> */}
                                                    
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={16}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("comp_source")}
                          required
                          id="comp_source"
                          select
                          label="บริษัทประกัน"
                          value={comp_source}
                          variant="outlined"
                          onChange={(event) =>
                            setCompSource(event.target.value)
                          }
                        >
                          {companyList.map((item) => (
                            <MenuItem
                              key={`C${item.sys_companyID}`}
                              value={item.sys_companyID}
                            >
                              {item.company_name_th}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>                  
                  </Grid>
                  <br/>
                  <br/>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={16}>คัดลอกไปที่</Grid>
                    <Grid item md={8} xs={16}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("comp_dest")}
                          required
                          id="comp_dest"
                          select
                          label="บริษัทประกัน"
                          value={comp_dest}
                          variant="outlined"
                          onChange={(event) =>
                            setCompDest(event.target.value)
                          }
                        >
                          {companyList.map((item) => (
                            <MenuItem
                              key={`D${item.sys_companyID}`}
                              value={item.sys_companyID}
                            >
                              {item.company_name_th}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>                  
                  </Grid>

                  <Grid
                      container
                      spacing={2}
                      sx={{ mt: 3 }}
                      justifyContent="flex-end"
                  >
                      <Alert
                        severity="error"
                        className={errorMessage === "" ? "hide" : ""}
                      >
                        {errorMessage}
                      </Alert>                      
                      <LoadingButton
                        type="submit"
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                      <Button onClick={handleClose}>Cancel</Button>
                  </Grid>

                </div>

                {/* {!question && (
                  <div className="text-center pmdt">
                    <CircularProgress />
                  </div>
                )} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </form>
          {/* <div>Value: {JSON.stringify(watch())}</div> */}
        </Dialog>
      </div>

      <Grid container spacing={2} justifyContent="space-between">
        <Grid p={2}>
          <Button
            variant="contained"
            size="middle"
            style={{ backgroundColor: "#32B917", marginRight: "5px" }}
            onClick={() => handleOpenConfig()}
          >
            <Typography fontSize={14}>คัดลอกค่าบริการ</Typography>
          </Button>
        </Grid>
      </Grid>

    </div>
  );
}
