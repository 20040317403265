import React, { useEffect, useState, useContext, createContext } from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation,
  useNavigate,
  Navigate,
  Outlet,
  Link,
} from "react-router-dom";

import "./styles/style.css";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MailIcon from "@mui/icons-material/Mail";
import ListMenu from "./ListMenu";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Badge from "@mui/material/Badge";
import nprogress from "nprogress";
import Button from "@mui/material/Button";
import axios from "axios";

import SuperRegister from "./pages/SuperRegister";
import SuperSurInfo from "./pages/SuperSurInfo";
import SuperMonitor from "./pages/SuperMonitor";
import SuperBilling from "./pages/SuperBilling";
import SuperPayment from "./pages/SuperPayment";
import OursourceRegister from "./pages/OursourceRegister";
import SignIn from "./pages/SignIn";
import Policy from "./pages/Policy";
import Register from "./pages/Register";
import CustomerEvaForm from "./pages/CustomerEvaForm";
import CustomerSatisReport from "./pages/CustomerSatisReport";
import BillMonthly from "./pages/BillMonthly";
import OutsourcePayment from "./pages/OutsourcePayment";
import BillingList from "./pages/BillingList";
import JobpollConfig from "./pages/JobpoolConfig";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#F15B2A",
    },
    secondary: {
      main: "#494c7d",
    },
    red: {
      main: "#FF0000",
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontFamily: `"Roboto", "Prompt", "Helvetica", "Arial", sans-serif`,
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        iSurvey DP
      </Link>{" "}
      v{process.env.REACT_APP_VERSION} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = theme;

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (token) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    // const origin = location.state?.from?.pathname || "/Super/Register";

    var role = token.rolename;

    var initialpath = "";
    if (role === "System Admin" || role === "Surveyor Admin") {
      initialpath = "/Super/Register";
    } else if (role === "Surveyor Monitor") {
      initialpath = "/Super/Monitor";
    } else if (role === "Billing Approve") {
      initialpath = "/Billing/Super";
    } else if (role === "Billing Account") {
      initialpath = "/Billing/Month";
    } else {
      initialpath = "/Super/Register";
    }
    const origin = location.state?.from?.pathname || initialpath;
    navigate(origin);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    navigate("/");
  };
  // const handleNotification = () => {
  //   const user = JSON.parse(sessionStorage.getItem("token"));
  //   const headers = {
  //     Authorization: `Bearer ${user.token}`,
  //     "Content-Type": "application/json",
  //   };
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}api/v1/external/noti/readall`, {
  //       headers,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const value = {
    onLogin: handleLogin,
    onLogout: handleLogout,
    // onClearNotification: handleNotification,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const ProtectedRoute = ({ children, roleRequired }) => {
  // const { token } = useAuth();
  var token = JSON.parse(sessionStorage.getItem("token"));

  //Set default admin
  if (roleRequired === undefined) {
    roleRequired = ["System Admin"];
  }

  const location = useLocation();

  if (!token) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  //Verify role
  var role = token.rolename;
  if (!roleRequired.includes(role)) {
    sessionStorage.removeItem("token");
    return <Navigate to="/" />;
  }

  return children;
};

export default function App() {
  let location = useLocation();
  const user = JSON.parse(sessionStorage.getItem("token"));
  const [notification, setNotification] = useState(0);
  const [datanotification, setDataNotification] = useState([]);

  useEffect(() => {
    nprogress.start();
    nprogress.done();
    // getNotification();
  }, [location.pathname]);

  function getNotification() {
    if (user !== null) {
      const headers = {
        Authorization: `Bearer ${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}api/v1/external/noti`, {
          headers,
        })
        .then((response) => {
          console.log(response.data);
          setNotification(response.data.new_total);
          setDataNotification(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <AuthProvider>
        <Routes>
          <Route index element={<SignIn useAuth={useAuth} />} />
          <Route path="Policy" element={<Policy />} />
          <Route path="Register" element={<Register />} />

          <Route
            path="/"
            element={
              <Layout
                data={{
                  notification: notification,
                  datanotification: datanotification,
                }}
              />
            }
          >
            <Route
              index
              path="Super/Register"
              element={
                <ProtectedRoute
                  roleRequired={["System Admin", "Surveyor Admin"]}
                >
                  <SuperRegister />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Super/SurInfo"
              element={
                <ProtectedRoute
                  roleRequired={["System Admin", "Surveyor Admin"]}
                >
                  <SuperSurInfo />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Super/Monitor"
              element={
                <ProtectedRoute
                  roleRequired={[
                    "System Admin",
                    "Surveyor Admin",
                    "Surveyor Monitor",
                  ]}
                >
                  <SuperMonitor />
                </ProtectedRoute>
              }
            />

            <Route
              index
              path="Outsource/Register"
              element={
                <ProtectedRoute>
                  <OursourceRegister />
                </ProtectedRoute>
              }
            />

            <Route
              index
              path="Payment/Super"
              element={
                <ProtectedRoute>
                  <SuperPayment />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Payment/Outsource"
              element={
                <ProtectedRoute>
                  <OutsourcePayment />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Customer/Form"
              element={
                <ProtectedRoute>
                  <CustomerEvaForm />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Customer/Report"
              element={
                <ProtectedRoute>
                  <CustomerSatisReport />
                </ProtectedRoute>
              }
            />

            <Route
              index
              path="/Billing/Super"
              element={
                <ProtectedRoute
                  roleRequired={["Billing Approve", "System Admin"]}
                >
                  <SuperBilling />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Billing/Month"
              element={
                <ProtectedRoute
                  roleRequired={["Billing Account", "System Admin"]}
                >
                  <BillMonthly />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Billing/List"
              element={
                <ProtectedRoute>
                  <BillingList />
                </ProtectedRoute>
              }
            />
            <Route
              index
              path="Jobpool/Config"
              element={
                <ProtectedRoute>
                  <JobpollConfig />
                </ProtectedRoute>
              }
            />
            <Route path="about/:invoiceId" element={<About />} />

            {/* Using path="*"" means "match anything", so this route
                        acts like a catch-all for URLs that we don't have explicit
                        routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

function Layout(data) {
  const user = JSON.parse(sessionStorage.getItem("token"));
  const { onLogout, onClearNotification } = useAuth();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  var totalNotification = data.data.notification;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const [anchorElNoti, setAnchorElNoti] = useState(null);
  // const openMenuNoti = Boolean(anchorElNoti);
  // const handleClickNoti = (event) => {
  //   setAnchorElNoti(event.currentTarget);
  // };
  // const handleCloseNoti = () => {
  //   setAnchorElNoti(null);
  // };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              backgroundColor: "#FFFFFF",
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              color="primary"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <IconButton
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(!open && { display: "none" }),
                }}
              >
                <ChevronLeftIcon />
              </IconButton>
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {/* <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClickNoti}
                  size="small"
                  sx={{ mr: 2 }}
                  aria-controls={openMenuNoti ? "account-menu-noti" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuNoti ? "true" : undefined}
                >
                  <Badge badgeContent={totalNotification} color="primary">
                    <MailIcon color="action" />
                  </Badge>
                </IconButton>
              </Tooltip> */}
              <Typography sx={{ minWidth: 100 }} className="cb">
                {user.firstname} {user.lastname}
              </Typography>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {Array.from(user.firstname)[0]}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            {/* <Menu
              anchorEl={anchorElNoti}
              id="account-menu-noti"
              open={openMenuNoti}
              onClose={handleCloseNoti}
              onClick={handleCloseNoti}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <h3 style={{ marginLeft: 10, marginRight: 10 }}>
                ผู้สมัครคนใหม่
              </h3>
              {data.data.datanotification.map((item) => (
                <MenuItem
                  sx={{ backgroundColor: item.ck_view === 0 ? "#EAEAEA" : "" }}
                >
                  {item.firstname} {item.lastname} : {item.phone}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem onClick={onClearNotification}>ยืนยันอ่านแล้ว</MenuItem>
            </Menu> */}
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={onLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <img src="/img/logo.png" className="logo" alt="Logo" />
          </Toolbar>
          <Divider />
          <List component="nav">
            <ListMenu />
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function About() {
  let params = useParams();
  return <h1>Invoice {params.invoiceId}</h1>;
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
