import * as React from "react";

import PaymentFormOS from "../forms/PaymentFormOS";
import PaymentOSCopyForm from "../forms/PaymentOSCopyForm";
import { Box, Button, Paper, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OutsourcePaymentContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          pb: 2,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          ค่าบริการบริษัทประกัน
        </Typography>
      </Box>
      <Box sx={{ width: "100%" }}>
        <PaymentOSCopyForm/>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="A. กรุงเทพฯ" {...a11yProps(0)} />
              <Tab label="B. ปริมณฑล" {...a11yProps(1)} />
              <Tab label="C. ต่างจังหวัดในอำเภอเมือง" {...a11yProps(2)} />
              <Tab label="D. ต่างจังหวัดนอกอำเภอเมือง" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PaymentFormOS zoneID="1" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PaymentFormOS zoneID="2" />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <PaymentFormOS zoneID="3" />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PaymentFormOS zoneID="4" />
          </TabPanel>
        </Paper>
      </Box>
    </Box>
  );
}

export default function OutsourcePayment() {
  return <OutsourcePaymentContent />;
}
