import React, { useState, useEffect, Fragment } from 'react';
import { Card, Typography } from '@mui/material';

export default function StyledCard(props) {

  useEffect(() => {
    console.log(props)
  }, []);

  const [shadow, setShadow] = useState(0);
  function onMouseOver() {
    setShadow(3);
  }
  function onMouseOut() {
    setShadow(0);
  }

  return (
    <Card
      onMouseOver={() => onMouseOver()}
      onMouseOut={() => onMouseOut()}
      onClick= { () =>
        props.status(props.dataStatus)
      }
      elevation={props.active === true ? 3 : shadow}
      sx={{ p: '10px', cursor: 'pointer', backgroundColor: props.active === true ? '#F15B2A' : "" }} >
      <Typography variant="h6" align="center">
        {props.data}
      </Typography>
      <Typography align="center">
        {props.children}
      </Typography>
    </Card>
  );
}