import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  ButtonBase,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  styled,
  Typography,
  TextField,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";

import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, Controller, useWatch, get } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import Rating from "@mui/material/Rating";

function createData(name, detail, type, status) {
  return { name, detail, type, status };
}
const dataMockup = [
  createData("การแต่งกายสุภาพเรียบร้อย", 1, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 4, "ให้คะแนน", 0),
  createData("การทำงานเรียบร้อย", 2, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 5, "ให้คะแนน", 1),
  createData("การตรงต่อเวลา", 3, "ใช่/ไม่ใช่", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 6, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 7, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 8, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 9, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 9, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 9, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 9, "ให้คะแนน", 1),
  createData("การแต่งกายสุภาพเรียบร้อย", 9, "ให้คะแนน", 1),
].sort((a, b) => (a.detail < b.detail ? -1 : 1));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    backgroundColor: "#f4dfd7",
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 12,
  // },
}));

export default function EvaluationForm() {
  const themePreview = useTheme();
  const isNotMobile = !useMediaQuery(themePreview.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ total: 0 });
  const [dataRow, setDataRow] = useState([]);
  const [dataPreview, setDataPreview] = useState([]);
  const [dataType, setDataType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [province, setProvince] = useState("");
  const [provinceEmp, setProvinceEmp] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [amphur, setAmphur] = useState("");
  const [amphurEmp, setAmphurEmp] = useState("");
  const [amphurList, setAmphurList] = useState([]);
  const [amphurListEmp, setAmphurListEmp] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [form_type, setFormType] = useState("");
  const [qID, setQID] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [userData, setUserData] = React.useState(
    JSON.parse(sessionStorage.getItem("token"))
  );
  const [formTypeList, setFormTypeList] = React.useState([
    "ให้คะแนน",
    "ช่องทำเครื่องหมาย",
    "ตอบคำถามแบบสั้น",
  ]);
  const [formType, getFormType] = useState("");
  const handleClearFormType = () => {
    getFormType("");
    getData(0, rowsPerPage, searchName, "");
  };

  const [form_status, setFormStatus] = useState("");
  const [formStatusList, setFormStatusList] = useState(["Active", "Inactive"]);
  const [isAddMode, setAddMode] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const [user, setUser] = useState(null);
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    getData(0, rowsPerPage, searchName, formType);
  }, []);

  useEffect(() => {
    // reset form with question data
    reset(question);
  }, [question]);

  function onSubmit(data) {
    setIsLoading(true);
    // console.log(`onSubmit`, data);

    var dataForm = JSON.stringify({
      question_order: data.form_order,
      question_type: form_type,
      question_topic: data.form_topic,
      isActive: form_status === "Active" ? 1 : 0,
      csq_id: qID, //edit
    });

    // console.log(`formSubmit`, dataForm);

    if (isAddMode) {
      // Add
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}api/v1/addquestion`,
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
        data: dataForm,
      };
      // console.log(config);
    } else {
      //Edit
      var config = {
        method: "put",
        url: `${process.env.REACT_APP_API_URL}api/v1/question/${qID}`,
        headers: {
          Authorization: `Bearer ${userData.token}`,
          "Content-Type": "application/json",
        },
        data: dataForm,
      };
      // console.log(config);
    }

    axios(config)
      .then(function (response) {
        clearFormValue();
        setIsLoading(false);
        setOpen(false);
        getData(page, rowsPerPage, searchName, formType);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });

    return false;
  }

  function _delete() {
    // console.log("DEL");

    const headers = {
      Authorization: `Bearer ${userData.token}`,
      "Content-Type": "application/json",
    };
    axios
      .delete(`${process.env.REACT_APP_API_URL}api/v1/question/${qID}`, {
        headers,
      })
      .then((res) => {
        // console.log(res);
        getData(page, rowsPerPage, searchName, formType);
      });

    handleCloseConfirm();
  }

  function getData(nowPage, nowRowsPerPage, formName, fmType) {
    setLoading(true);
    setDataRow([]);

    var nowPage = nowPage + 1;

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/questionlist?page=${nowPage}&perpage=${nowRowsPerPage}&name=${formName}&type=${fmType}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setData(response.data);
        setDataRow(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function clearFormValue() {
    setQuestion({
      form_order: "",
      form_topic: "",
    });
    setFormType("");
    setFormStatus("");
  }

  function getNextOrder() {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/v1/nextorder`)
      .then((response) => {
        clearFormValue();
        setQuestion({
          form_order: response.data.next_order,
        });
        setAddMode(true);
        // console.log(`nextorder`, response.data.next_order);

        setOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleClickOpen(id) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/question/${id}`,
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
    };
    // console.log(config);
    axios(config)
      .then(function (response) {
        var item = response.data;
        setQuestion({
          form_order: item.question_order,
          form_topic: item.question_topic,
        });
        setQID(item.csq_id);
        setFormType(item.question_type);
        setFormStatus(item.isActive === 1 ? "Active" : "Inactive");
        setAddMode(false);

        setOpen(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, rowsPerPage, searchName, formType);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getData(0, event.target.value, searchName, formType);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleClickOpenConfirm = (id) => {
    // console.log(id);
    setQID(id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openPreview, setOpenPreview] = useState(false);

  const handleClickPreview = () => {
    setOpenPreview(true);
    getFormPreview();
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const commonStyles = {
    bgcolor: "background.paper",
    m: 2,
    border: 1,
    borderRadius: "16px",
    // width: "100%",
    width: isNotMobile ? 400 : "100%",
    color: "#0b188f",
  };

  function getFormPreview() {
    setLoading(true);
    setDataPreview([]);
    // setDataCnt(0);

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/v1/question`,
      headers: {
        // Authorization: `Bearer ${process.env.REACT_APP_AUTH}`,
        "Content-Type": "application/json",
      },
    };
    // console.log(`config`, config);
    axios(config)
      .then(function (response) {
        setLoading(false);
        setDataPreview(response.data);
        // setDataCnt(response.data.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="responsive-dialog-title">
              {isAddMode ? "เพิ่มแบบสอบถาม" : "แก้ไขแบบสอบถาม"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("form_order")}
                          fullWidth
                          id="form_order"
                          label="แบบสอบถามข้อที่"
                          variant="outlined"
                          type="number"
                          inputProps={{ min: 1 }} // Set minimum allowed value to 1
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("form_type")}
                          required
                          id="form_type"
                          select
                          label="ประเภทแบบสอบถาม"
                          value={form_type}
                          variant="outlined"
                          onChange={(event) => setFormType(event.target.value)}
                        >
                          {formTypeList.map((item, index) => (
                            <MenuItem key={`T${index}`} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item md={12}>
                      <TextField
                        {...register("form_topic")}
                        required
                        fullWidth
                        id="form_topic"
                        label="หัวข้อแบบประเมิน"
                        variant="outlined"
                        multiline
                        rows={4}
                      />
                    </Grid>

                    <Grid item md={6}>
                      <FormControl fullWidth={true}>
                        <TextField
                          {...register("form_status")}
                          required
                          id="form_status"
                          select
                          label="สถานะ"
                          value={form_status}
                          variant="outlined"
                          onChange={(event) =>
                            setFormStatus(event.target.value)
                          }
                        >
                          {formStatusList.map((item, index) => (
                            <MenuItem key={`S${index}`} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ mt: 3 }}
                      justifyContent="flex-end"
                    >
                      <LoadingButton
                        type="submit"
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                      >
                        Save
                      </LoadingButton>
                      <Button onClick={handleClose}>Cancel</Button>
                    </Grid>
                  </Grid>
                </div>

                {!question && (
                  <div className="text-center pmdt">
                    <CircularProgress />
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </form>
          {/* <div>Value: {JSON.stringify(watch())}</div> */}
        </Dialog>

        <Dialog
          open={openConfirm}
          onClose={handleCloseConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to confirm delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirm}>Cancle</Button>
            <Button onClick={() => _delete()} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ flex: 1, p: "0px", mb: "20px" }}
        rowSpacing={{ xs: 2, sm: 2, md: 2 }}
        spacing={{ xs: 1, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={5} md={8}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            sx={{ flex: 1, p: "0px", mb: "20px" }}
            rowSpacing={{ xs: 2, sm: 2, md: 2 }}
            spacing={{ xs: 1, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={5} md={6}>
              <FormControl fullWidth={true}>
                <TextField
                  // size="small"
                  // sx={{ flex: 1, p: "0px", mb: "20px" }}
                  placeholder="แบบประเมิน"
                  onChange={(e) => {
                    setSearchName(e.target.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      setPage(0);
                      getData(0, rowsPerPage, searchName, formType);
                      ev.preventDefault();
                    }
                  }}
                  InputProps={{
                    "aria-label": "search form name",
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          type="button"
                          sx={{ p: "0px" }}
                          aria-label="search"
                          onClick={() =>
                            getData(0, rowsPerPage, searchName, formType)
                          }
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5} md={5}>
              <FormControl fullWidth={true}>
                <TextField
                  id="outlined-select-status"
                  select
                  label="ประเภทแบบสอบถาม"
                  value={formType}
                  onChange={(event) => {
                    setPage(0);
                    getFormType(event.target.value);
                    getData(0, rowsPerPage, searchName, event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: formType ? "visible" : "hidden" }}
                        onClick={handleClearFormType}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                >
                  {formTypeList.map((item, index) => (
                    <MenuItem key={`T${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ flex: 1, p: "0px", mb: "0px" }}
          rowSpacing={{ xs: 2, sm: 2, md: 2 }}
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid>
            <Button
              variant="contained"
              size="middle"
              style={{ backgroundColor: "#32B917", marginRight: "15px" }}
              // onClick={() => setOpen(true)}
              onClick={() => getNextOrder()}
            >
              <Typography fontSize={14}>เพิ่มแบบประเมิน</Typography>
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              size="middle"
              style={{ backgroundColor: "#1a6e0a", marginRight: "5px" }}
              onClick={() => handleClickPreview()}
            >
              <Typography fontSize={14}>Preview</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div className={loading === true ? "loading" : "hide"}>
        <CircularProgress />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No.</StyledTableCell>
              <StyledTableCell>แบบประเมิน</StyledTableCell>
              <StyledTableCell>แบบสอบถามข้อที่</StyledTableCell>
              <StyledTableCell>ประเภทแบบสอบถาม</StyledTableCell>
              <StyledTableCell>สถานะ</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRow.map((row, index) => {
              var statusTitle = "";
              var statusStyle = "";
              var rowNum = data.from;

              if (row.isActive === 1) {
                statusTitle = "Active";
                statusStyle = "cgr";
              } else {
                statusTitle = "Inactive";
                statusStyle = "cr";
              }
              return (
                <TableRow key={row.csq_id}>
                  <TableCell component="th" scope="row">
                    {rowNum + index}
                  </TableCell>
                  <TableCell>
                    <Typography>{row.question_topic}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.question_order}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{row.question_type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={statusStyle}>
                      {statusTitle}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => handleClickOpen(row.csq_id)}
                    >
                      <EditIcon color="success" />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => handleClickOpenConfirm(row.csq_id)}
                    >
                      <DeleteIcon color="red" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={data.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />

      {/* Preview phone theme */}
      <Dialog
        open={openPreview}
        onClose={handleClosePreview}
        fullScreen={themePreview.breakpoints.down("sm")} // Set full screen for mobile
        PaperProps={{ sx: { bgcolor: "background.paper" } }} // Optional: Set background color
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: 2 }}
        >
          <Box sx={{ marginLeft: 2 }}>
            <DialogTitle>Preview แบบประเมิน</DialogTitle>
          </Box>
          <Box sx={{ marginRight: 5 }}>
            <DialogActions>
              <Button onClick={handleClosePreview}> Close</Button>
            </DialogActions>
          </Box>
        </Grid>
        <DialogContent>
          {/* <DialogContentText>Test </DialogContentText> */}
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="column"
          >
            <Grid>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: "#264c86",
                  textAlign: "left",
                  borderBottom: 1,
                  borderColor: "primary.main",
                }}
                width={isNotMobile ? 400 : "100%"}
              >
                Customer Satisfaction
              </Box>
            </Grid>
            <Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 14,
                }}
              >
                <Box sx={{ ...commonStyles, borderColor: "grey.500" }}>
                  <Box
                    sx={{
                      marginBlockStart: 2,
                      marginLeft: 2,
                      marginRight: 2,
                      textAlign: "center",
                      paddingBottom: 3,
                    }}
                  >
                    <Box>โปรดให้คะแนนการทำงานของผู้ตรวจสอบ</Box>
                    <Box>ขอบพระคุณค่ะ</Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 1,
                        m: 1,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                      }}
                    >
                      <Box sx={{ color: "red" }}>
                        <SentimentVeryDissatisfiedIcon />
                      </Box>
                      <Box sx={{ color: "orange" }}>
                        <SentimentDissatisfiedIcon />
                      </Box>
                      <Box sx={{ color: "yellow" }}>
                        <SentimentNeutralIcon />
                      </Box>
                      <Box sx={{ color: "lightgreen" }}>
                        <SentimentSatisfiedIcon />
                      </Box>
                      <Box sx={{ color: "green" }}>
                        <SentimentSatisfiedAltIcon />
                      </Box>
                    </Box>
                    <Box>{loading ? <CircularProgress /> : ""}</Box>

                    {dataPreview.map((row, index) => {
                      var typeStyle = "";
                      if (row.question_type === "ให้คะแนน") {
                        typeStyle = "1";
                      } else if (row.question_type === "ช่องทำเครื่องหมาย") {
                        typeStyle = "2";
                      } else {
                        typeStyle = "3";
                      }
                      return (
                        <div key={index}>
                          <Grid
                            container
                            spacing={2}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{
                              mt: 1,
                              ml: 1,
                              paddingRight: 2,
                              marginBottom: 1,
                              textAlign: "left",
                            }}
                          >
                            <Grid>
                              {index + 1}. {row.question_topic}
                            </Grid>

                            {typeStyle === "1" ? (
                              <Grid sx={{ paddingLeft: 2 }}>
                                <Rating name={row.csq_id.toString()} />
                              </Grid>
                            ) : typeStyle === "2" ? (
                              <Grid sx={{ paddingLeft: 2 }}>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name={row.csq_id.toString()}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="ใช่"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="ไม่ใช่"
                                  />
                                </RadioGroup>
                              </Grid>
                            ) : (
                              <Grid sx={{ paddingLeft: 2 }}>
                                <Box width={isNotMobile ? 250 : "100%"}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    name={row.csq_id.toString()}
                                  />
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
