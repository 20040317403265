import React, { useState, useEffect, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
    Box,
    Button,
    FormControl,
    FormGroup,
    MenuItem,
    Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import EastIcon from "@mui/icons-material/East";
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from "@mui/material/TextField";
import axios from "axios";

export default function PositionForm({ onSave, handleBack }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isPosition, setIsPosition] = useState(false);
    const [province, setProvince] = useState("");
    const [provinceList, setProvinceList] = useState([]);
    const [amphur, setAmphur] = useState("");
    const [amphurList, setAmphurList] = useState([]);

    useEffect(() => {
        getProvince()
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => {
        setIsPosition(false);
        if(data.acc_employee === false && data.insp_employee === false){
            setIsPosition(true);
        } else {
            setIsLoading(true);
            onSave(data);
        }
    };

    function getProvince() {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/opt/province`).then((response) => {
            setProvinceList(response.data)
        }).catch(error => {
            console.log(error)
        });
    }

    function getAmphur(provinceID) {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/opt/amphur?provinceID=${provinceID}`).then((response) => {
            // console.log(response.data);
            setAmphurList(response.data)
        }).catch(error => {
            console.log(error)
        });
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox {...register("acc_employee")} id="acc_employee" />} label="1. พนักงานสำรวจอุบัติเหตุ" />
                            <FormControlLabel control={<Checkbox {...register("insp_employee")} id="insp_employee" />} label="2. พนักงานตรวจสภาพรถยนต์" />
                            {isPosition && <span role="alert">กรุณาเลือกตำแหน่ง</span>}
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography className="bold cb" variant='h6' sx={{ mb: 2 }}>พื้นที่รับงาน</Typography>

                        <FormControl fullWidth={true}>
                            <TextField
                                {...register("province", { required: true })}
                                id="province"
                                select
                                label="จังหวัด"
                                value={province}
                                onChange={(event) => {
                                    setProvince(event.target.value)
                                    setAmphur('');
                                    getAmphur(event.target.value)
                                }}
                            >
                                {provinceList.map(item =>
                                    <MenuItem value={item.provinceID}>{item.provincename}</MenuItem>
                                )}
                            </TextField>
                            {errors.province && <span role="alert">กรุณากรอกข้อมูลให้ครบ</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth={true}>
                            <TextField
                                {...register("amphur", { required: true })}
                                id="amphur"
                                select
                                label="อำเภอ"
                                value={amphur}
                                onChange={(event) => {
                                    setAmphur(event.target.value)
                                }}
                            >
                                {amphurList.map(item =>
                                    <MenuItem key={`A${item.amphurID}`} value={item.amphurID}>{item.amphurname}</MenuItem>
                                )}
                            </TextField>
                            {errors.amphur && <span role="alert">กรุณากรอกข้อมูลให้ครบ</span>}
                        </FormControl>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        ย้อนกลับ
                    </Button>

                    <LoadingButton
                        type="submit"
                        loading={isLoading}
                        loadingPosition="end"
                        endIcon={<EastIcon />}
                        variant="contained"
                        sx={{ mt: 3, ml: 1 }}
                    >
                        สมัคร
                    </LoadingButton>

                </Box>
            </form>
        </React.Fragment>
    );
}
