import React, { useState, useEffect, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EastIcon from "@mui/icons-material/East";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';

import { useForm, Controller } from "react-hook-form";

export default function InformationForm({ onSave, dataStep }) {
    const [gender, setGender] = useState("");
    const [birthday, setBirthday] = useState("");
    const [is_punish, setIs_punish] = useState("1");
    const [education_qual, setEducation_qual] = useState('');
    const [educationList, setEducationList] = useState([
        "ต่ำกว่ามัธยมศึกษาตอนปลาย / ปวช.",
        "มัธยมศึกษาตอนปลาย / ปวช.",
        "อนุปริญญา / ปวส.",
        "ปริญญาตรี",
        "ปริญญาโท หรือสูงกว่า"
    ]);

    const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm({});
    const [user, setUser] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        // reset form with user data
        reset(user);
    }, [user]);

    const onSubmit = (data) => {
        if (birthday !== "") {
            if (typeof birthday !== 'string') {
                let onlyDate = birthday.$d.toISOString();
                const date = new Date(onlyDate);
                data.birthday = date.toLocaleDateString();
            }
        }
        onSave(data);
    };

    function getData() {
        if (dataStep !== "") {
            setUser({
                firstname: dataStep.firstname,
                lastname: dataStep.lastname,
                gender: dataStep.gender,
                birthday: dataStep.birthday,
                age: dataStep.age,
                email: dataStep.email,
                address1: dataStep.address1,
                address2: dataStep.address2,
                education_qual: dataStep.education_qual,
                emergency_name: dataStep.emergency_name,
                emergency_phone: dataStep.emergency_phone,
                work_history: dataStep.work_history,
                is_punish: dataStep.is_punish,
                education_desc: dataStep.education_desc,
            })
            console.log(dataStep);
            setIs_punish(dataStep.is_punish !== null ? dataStep.is_punish : "0");
            setGender(dataStep.gender);
            setBirthday(dataStep.birthday);
            setEducation_qual(dataStep.education_qual);
        }
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("firstname", { required: true })}
                            aria-invalid={errors.firstname ? "true" : "false"}
                            fullWidth
                            id="firstname"
                            label="ชื่อ"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.firstname?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("lastname", { required: true })}
                            aria-invalid={errors.lastname ? "true" : "false"}
                            fullWidth
                            id="lastname"
                            label="นามสกุล"
                            InputLabelProps={{ shrink: true }}
                            required
                        />
                        {errors.lastname?.type === "required" && (
                            <p role="alert">กรุณากรอกข้อมูลให้ครบ</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                {...register("gender")}
                                id="gender"
                                select
                                label="เพศ"
                                value={gender}
                                onChange={(event) =>
                                    setGender(event.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem value="ชาย">ชาย</MenuItem>
                                <MenuItem value="หญิง">หญิง</MenuItem>
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                id="birthday"
                                label="วัน / เดือน / ปีเกิด"
                                value={birthday}
                                onChange={(newValue) => {
                                    let now = moment(new Date());
                                    let selectDate = moment(new Date(newValue));
                                    let nowAge = now.year() - selectDate.year();
                                    setBirthday(newValue);
                                    setValue('age', nowAge)
                                }}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(params) => (
                                    <TextField {...params} {...register("birthday")} id="birthday" value={birthday} />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("age")}
                            id="age"
                            label="อายุ"
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("email", {
                                required: "required",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "รูปแบบอีเมล์ไม่ถูกต้อง"
                                }
                            })}
                            id="email"
                            label="อีเมล์"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                        {errors.email && <span role="alert">{errors.email.message}</span>}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("address1")}
                            id="address1"
                            name="address1"
                            label="ที่อยู่ตามทะเบียนบ้าน"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            autoComplete="shipping address-line1"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            {...register("address2")}
                            id="address2"
                            name="address2"
                            label="ที่อยู่ปัจจุบัน"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            autoComplete="shipping address-line2"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth={true}>
                            <TextField
                                {...register("education_qual")}
                                required
                                id="education_qual"
                                select
                                label="ระดับการศึกษาสูงสุด"
                                value={education_qual}
                                onChange={(event) =>
                                    setEducation_qual(event.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                            >
                                {educationList.map(item =>
                                    <MenuItem value={item}>{item}</MenuItem>
                                )}
                            </TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("emergency_name")}
                            id="emergency_name"
                            name="emergency_name"
                            label="ชื่อบุคคลสำหรับติดต่อฉุกเฉิน"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("education_desc")}
                            id="education_desc"
                            name="education_desc"
                            label="ชื่อสถาบัน / สาขาเอกที่สำเร็จการศึกษา"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("work_history")}
                            id="work_history"
                            name="work_history"
                            label="ประวัติการทำงาน"
                            multiline
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">ท่านเคยต้องโทษในคดีแพ่งหรืออาญาหรือไม่</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={is_punish}
                                name="radio-buttons-group"
                                {...register("is_punish")}
                            >
                                <FormControlLabel onChange={(event) => setIs_punish("1")} value="1" control={<Radio />} label="ใช่" />
                                <FormControlLabel onChange={(event) => setIs_punish("0")} value="0" control={<Radio />} label="ไม่" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            {...register("emergency_phone")}
                            id="emergency_phone"
                            name="emergency_phone"
                            label="เบอร์โทรบุคคลสำหรับติดต่อฉุกเฉิน"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>

                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="contained"
                        type="submit"
                        endIcon={<EastIcon />}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        ถัดไป
                    </Button>
                </Box>
            </form>
        </React.Fragment>
    );
}
