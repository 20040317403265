import * as React from "react";

import BillMonthlyForm from "./BillMonthlyForm";
// import Outsource from "./Outsource";
import { Box, Button, Paper, Typography } from "@mui/material";
// import UploadIcon from "@mui/icons-material/Upload";
// import DownloadIcon from "@mui/icons-material/Download";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import UploadIcon from "@mui/icons-material/Upload";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BillMonthlyContent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          pb: 2,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom>
          วางบิล
        </Typography>
        {/* <Button variant="text" startIcon={<UploadIcon />}>
          Import
        </Button>
        <Button variant="text" startIcon={<DownloadIcon />}>
          Export
        </Button> */}

        {/* <Button
          variant="text"
          startIcon={<UploadIcon />}
          size="large"
          sx={{ mt: 1, ml: 1 }}
          onClick={(e) => exportToExcel(fileName)}
        >
          Export ตั้งเบิกค่าพาพนะ
        </Button> */}
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <BillMonthlyForm />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default function BillMonthly() {
  return <BillMonthlyContent />;
}
